import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';

function BtnGoBack() {
  const navigate = useNavigate();

  return (
    <Button
      style={{ color: '#ffffff' }}
      type="link"
      icon={<BackwardOutlined />}
      onClick={() => { navigate(-1); }}
    />
  );
}

export default BtnGoBack;
