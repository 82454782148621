/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDeliveryPrice, getKmFromM } from '../utils/utils';
import { selectCurrentLocation } from '../../app/Layouts/WebLayout/webSlice';

export default function useGoogleMasApi(
  onLoadingChange,
  onMapMarkerChange,
) {
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [userCoords, setUserCoords] = useState(null);
  const userLocation = useRef(null);
  const [validCoords, setCoordsState] = useState(true);
  const [currentOrigin, setCurrentOrigin] = useState(null);

  const currentLocation = useSelector(selectCurrentLocation);
  const currentLocationLatLng = useRef(
    currentLocation
    && {
      lat: currentLocation?.latitude,
      lng: currentLocation?.longitude,
    },
  );

  const mapRef = useRef(null);
  const currentMarkerRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const setScriptTag = (scriptUrlId = 'distanceMatrix') => {
    let scriptUrl = document.getElementById(`${scriptUrlId}`);

    if (!scriptUrl) {
      scriptUrl = document.createElement('script');
      scriptUrl.id = scriptUrlId;
      scriptUrl.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAVHN1DnwPUX4zf5k1PD0AB-mBmn4-ZUaw&libraries=places,distancematrix';
      document.head.appendChild(scriptUrl);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const removeScriptsTag = (scriptUrlId = 'distanceMatrix') => {
    const scriptUrl = document.getElementById(`${scriptUrlId}`);

    if (scriptUrl) {
      scriptUrl.remove();
    }
  };

  const fetchCurrentUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        userLocation.current = position;
        setUserCoords(
          userLocation.current ? {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          } : null,
        );
      });
    }
  };

  useEffect(() => {
    fetchCurrentUserLocation();
  }, []);

  const calculateDelivery = (origin, destination) => {
    const distanceMatrixService = new google.maps.DistanceMatrixService();
    const request = {
      origins: [origin],
      destinations: [destination],
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
      // eslint-disable-next-line no-undef
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    };
    onLoadingChange(true);
    distanceMatrixService.getDistanceMatrix(request)
      .then((response) => {
        const distance = response.rows[0].elements[0].distance.value;
        const currentDistance = getKmFromM(distance);
        const currentDeliveryPrice = getDeliveryPrice(currentDistance);
        setDeliveryPrice(currentDeliveryPrice);
        console.log('este es el nuevo precio', deliveryPrice, currentDeliveryPrice);
      })
      .finally(() => {
        setTimeout(() => {
          onLoadingChange(false);
        }, 500);
      });
  };

  const onPlaceChangedHandler = (newAddressObj) => {
    console.log(newAddressObj, 'este es el user location', userLocation, userCoords);
    // const userLocation = { current: null };
    // if ('geolocation' in navigator) {
    // navigator.geolocation.getCurrentPosition(async (position) => {
    // userLocation.current = position;
    // eslint-disable-next-line no-undef
    if (userLocation) {
      // eslint-disable-next-line no-undef
      const distanceMatrixService = new google.maps.DistanceMatrixService();
      let origin = newAddressObj.geometry.location;
      if (currentLocation && currentLocation.latitude && currentLocation.longitude) {
        origin = {
          lat: currentLocation.latitude,
          lng: currentLocation.longitude,
        };
      }
      /* if (!currentMarkerRef.current) {
        origin = currentMarkerRef.current.position;
      } */
      const destination = newAddressObj.geometry.location;
      mapRef.current.panTo(destination);
      currentMarkerRef.position = newAddressObj.geometry.location;

      const request = {
        origins: [origin],
        destinations: [destination],
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        // eslint-disable-next-line no-undef
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      };
      onLoadingChange(true);
      distanceMatrixService.getDistanceMatrix(request)
        .then((response) => {
          const distance = response.rows[0].elements[0].distance.value;
          const currentDistance = getKmFromM(distance);
          const currentDeliveryPrice = getDeliveryPrice(currentDistance);
          setDeliveryPrice(currentDeliveryPrice);
        })
        .finally(() => {
          setTimeout(() => {
            onLoadingChange(false);
          }, 500);
        });
    }
  };

  const getLimitBounds = () => {
    const currentCenterPoint = currentLocationLatLng.current;
    let newBounds = null;
    if (currentCenterPoint) {
      const googleBounds = {};
      googleBounds.north = currentCenterPoint.lat + 0.15;
      googleBounds.south = currentCenterPoint.lat - 0.15;
      googleBounds.east = currentCenterPoint.lng + 0.15;
      googleBounds.west = currentCenterPoint.lng - 0.15;
      // eslint-disable-next-line no-undef
      newBounds = new google.maps.LatLngBounds(
        {
          lat: googleBounds.south,
          lng: googleBounds.west,
        },
        {
          lat: googleBounds.north,
          lng: googleBounds.east,
        },
      );
      // autoCompleteRef.current.setBounds(newBounds);
    }
    return newBounds;
  };

  const onMapChanged = async (latLngObj) => {
    const geocoderService = new google.maps.Geocoder();
    const geocodeQuery = await geocoderService.geocode({ location: latLngObj });
    /** OBTENEMOS EL PRIMER RESULTADO DEL ARRAY */
    const firstPlaceFound = geocodeQuery.results[0];
    const firstPlaceFormattedData = {
      formatted_address: firstPlaceFound.formatted_address,
      geometry: firstPlaceFound?.geometry,
    };
    if (onMapMarkerChange) {
      onMapMarkerChange(firstPlaceFormattedData);
    }
    const limitBounds = getLimitBounds();
    const isPointInsideLimitBounds = limitBounds.contains(latLngObj);
    setCoordsState(isPointInsideLimitBounds);
  };

  const handleCenterChange = () => {
    const mapCenterPoint = mapRef.current.getCenter();
    currentMarkerRef.current.position = mapCenterPoint;
    onMapChanged(mapCenterPoint);

    const origin = currentMarkerRef.current.position;
    /** SETEAMOS EL ORIGEN ACTUAL */
    console.log('este es el current origin', origin);
    setCurrentOrigin({
      lat: origin.lat,
      lng: origin.lng,
    });
    /** --------------------------------- */

    let destination = currentMarkerRef.current.position;
    // console.log('este es el current location', currentLocation, origin);
    if (currentLocation && currentLocation.latitude && currentLocation.longitude) {
      destination = {
        lat: currentLocation.latitude,
        lng: currentLocation.longitude,
      };
    }

    calculateDelivery(origin, destination);
  };

  const setMap = async (mapId) => {
    if ('geolocation' in navigator) {
      // eslint-disable-next-line no-unused-vars
      navigator.geolocation.getCurrentPosition(async (position) => {
        /* const myLatIng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }; */
        // console.log(myLatIng);
        const limitBounds = getLimitBounds();
        const mapOptions = {
          zoom: 17,
          center: currentLocationLatLng.current,
          mapId: 'MAP_ANKI',
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          restriction: limitBounds,
        };
        // console.log(mapId, document.getElementById(mapId));
        const map = new google.maps.Map(document.getElementById(mapId), mapOptions);

        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
        const markerView = new AdvancedMarkerElement({
          position: currentLocationLatLng.current,
          map,
          title: 'Mapa de anki',
        });
        mapRef.current = map;
        currentMarkerRef.current = markerView;
        map.addListener('center_changed', () => { handleCenterChange(); });

        // marker.setMap(map);
      });
    } else {
      setTimeout(() => {
        setMap(mapId);
      }, 2000);
    }
  };

  return {
    onPlaceChangedHandler,
    setMap,
    userCoords,
    validCoords,
    deliveryPrice,
    currentOrigin,
  };
}
