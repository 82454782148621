import { useDispatch, useSelector } from 'react-redux';
import {
  reset as resetCart,
  selectShoppingCart,
  selectShoppingCartDiscount,
} from '../components/ShoppingCartMenu/shoppingCartSlice';

export default function useWhatsapp() {
  const dispatch = useDispatch();
  const shoppingCartData = useSelector(selectShoppingCart);
  const cartDiscount = useSelector(selectShoppingCartDiscount);

  const sendWhatsappOrder = (extraMessage = '', resetShoppinCart = false) => {
    console.log('este es el número al que debería enviar', shoppingCartData, shoppingCartData.locationWebPhone);
    const defaultPhoneNumber = '51955756006';
    const selectedDishes = shoppingCartData.dishes.map((d) => ({
      name: d.name,
      price: d.price,
      quantity: d.quantity,
    }));
    const totalStore = shoppingCartData.total;
    let message = `
    Hola, quisiera realizar el siguiente pedido realizado desde ${window.location.origin}:%0A
    %0A
    ${selectedDishes.map((d) => `${d.quantity} x ${d.name} (P/U ${d.price}) ----> Total del Plato: ${(d.quantity * d.price).toFixed(2)}`).join('%0A')}
    %0A%0A
    %0A
    Total de la compra: S/${totalStore.toFixed(2)}
    %0A%0A
    ${extraMessage}
        `;
    const messageDiscount = `
        Tengo un descuento de ${cartDiscount},
        %0A
        El total de mi pedido es ${totalStore - cartDiscount}
        `;
    if (cartDiscount > 0) {
      message = `${message} %0A%0A${messageDiscount}`;
    }

    const urlToRedirect = `https://wa.me/${shoppingCartData.locationWebPhone || defaultPhoneNumber}/?text=${message}`;

    // window.location.href = urlToRedirect;
    const aT = document.createElement('a');
    aT.target = '_blank';
    aT.href = urlToRedirect;
    document.body.appendChild(aT);
    aT.click();
    document.body.removeChild(aT);

    if (resetShoppinCart) {
      console.log('resetea el carro');
      dispatch(resetCart());
    }
  };

  return {
    sendWhatsappOrder,
  };
}
