import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button, Col, Form, Input, Modal, Row, Upload,
} from 'antd';
import { getBase64File } from '../../../../common/utils/utils';

export default function FeIssuerFormModal({
  isOpen,
  handleCancel,
}) {
  const model = {
    usuarioSol: '',
    claveSol: '',
    document: '',
    legalName: '',
    businessName: '',
    certificate: null,
    certificate64: '',
  };
  const [form] = Form.useForm();
  const handleFormChange = (changedFields) => {
    console.log('estos son los campos cambiados', changedFields);
    const fieldChanged = changedFields[0];
    if (fieldChanged.name[0] === 'certificate') {
      getBase64File(fieldChanged.value.file.originFileObj, (result) => {
        form.setFieldValue('certificate64', result);
      });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      title="Emisor"
    >
      <Form
        initialValues={model}
        form={form}
        onFieldsChange={handleFormChange}
        layout="vertical"
      >
        <Row gutter={[12, 12]}>
          <Col>
            <Form.Item
              name="usuarioSol"
              label="Usuario Sol"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="claveSol"
              label="Clave Sol"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="document"
              label="Número de documento"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="legalName"
              label="Nombre legal"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="businessName"
              label="Nombre comercial"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="certificate"
              label="Certificado"
            >
              <Upload>
                <Button icon={<UploadOutlined />}>
                  Cargar
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="certificate64"
              label="Certificado en base 64"
            >
              <Input
                placeholder="Certificado"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Modal>
  );
}

FeIssuerFormModal.defaultProps = {
  isOpen: false,
  handleCancel: () => {},
};

FeIssuerFormModal.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
};
