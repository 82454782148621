import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
// import CustomInput from '../../common/components/Extras/CustomInput/CustomInput';
import { useDispatch } from 'react-redux';
import OrderStateContainer from './orderStateContainer/OrderStateContainer';
import useKitchenOrders from './useKitchenOrders';
import './orders-page.css';
import { setCurrentPageTitle, setExtrasNavbarItems } from '../../app/Layouts/appLayoutSlice';

function KitchenOrdersPage() {
  const [filteredOrders, isLoading] = useKitchenOrders();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPageTitle('Cocina'));
  }, []);

  useEffect(() => {
    dispatch(setExtrasNavbarItems(['LocationsSelect']));
  }, [filteredOrders]);

  return (
    <Row span={24} gutter={12}>
      {
        filteredOrders.map((obj) => (
          <Col xs={24} sm={12} key={obj.state.id}>
            <OrderStateContainer
              state={obj.state}
              orders={obj.orders}
              isLoading={isLoading}
            />
          </Col>
        ))
      }
    </Row>
  );
}

export default KitchenOrdersPage;
