/* eslint-disable import/prefer-default-export */
import { getOptChain } from '../common/utils/utils';
import { api } from './config/axios';

export const getDashboardData = async (optsObj = {}) => {
  const optChain = getOptChain(optsObj);

  return api.get(`/report/get-dashboard-data/?${optChain}`)
    .then(({ data }) => (data))
    .catch(() => ({
      data: null,
      success: false,
      message: 'Ha habido un error, contactar al administrador',
    }));
};

export const getDashboardParams = async () => api.get('/report/get-dashboard-params')
  .then(({ data }) => (data))
  .catch(() => ({
    data: null,
    success: false,
    message: 'Ha habido un error, por favor contactar al administrador',
  }));
