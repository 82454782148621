import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './dish-info-menu.css';
import {
  Button,
  Table,
  Typography,
  Tabs,
} from 'antd';
import { CloseCircleOutlined, InfoCircleOutlined, ShoppingOutlined } from '@ant-design/icons';
// import { faColumns } from '@fortawesome/free-solid-svg-icons';
import CustomImg from '../../../../common/components/Extras/CustomImg/CustomImg';
import { selectAdmDishById } from '../admDishesSlice';
import { getUrlByFileRoot } from '../../../../common/utils/utils';

const { TabPane } = Tabs;
const { Title } = Typography;
function DishInfoMenu({ dishId, isOpen, onStateChange }) {
  // const [currentDish, setCurrenDish] = useState(null);
  const currentDish = useSelector(selectAdmDishById(dishId));
  const [state, setState] = useState(false);
  const insumosDataSource = currentDish?.ingredients.map((ingredient) => ({
    title: ingredient.title,
    quantity: ingredient.quantity,
    unit: ingredient.unit,
    price: ingredient.price.toFixed(2),
  }));
  const addonsDataSource = currentDish?.addons.map((addon) => ({
    title: addon.title,
    quantity: addon.quantity,
    unit: addon.unit,
    price: addon.price.toFixed(2),
  }));

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'title',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
    },
    {
      title: 'Unidad',
      dataIndex: 'unit',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
    },
  ];

  const paginationConfig = {
    pageSize: 4,
    rowPerPageOptions: [5, 10, 20],
    hideOnSinglePage: true,
  };

  useEffect(() => {
    setState(isOpen);
  }, [isOpen]);

  return (
    <div className={`dish-info-menu ${state && 'is-open'}`}>
      <div className="dish-info-menu__body">
        <div className="dish-info-menu__inner-container">
          <div className="is-relative mb-5">
            <div className="is-size-3 has-text-centered has-text-weight-bold">
              { currentDish?.name }
            </div>
            <div className="sticky-header">
              <div className="btn-info-menu">
                <div className="dish-info-menu__title-container">
                  <Title level={3} strong style={{ marginTop: 0, whiteSpace: 'nowrap' }}>
                    <InfoCircleOutlined />
                    {' '}
                    { currentDish?.title }
                  </Title>
                </div>
                <div>
                  <Button
                    icon={isOpen ? <CloseCircleOutlined /> : <ShoppingOutlined />}
                    type="primary"
                    ghost
                    onClick={() => { onStateChange(false); }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="dish-info-menu__description-grid">
            <div className="">
              <CustomImg
                url={getUrlByFileRoot(currentDish?.img)}
                width="160px"
                height="160px"
                border="5px"
              />
            </div>
            <div className="dish-info-menu__description-container">
              <div>
                <span>Restaurante:</span>
                <span className="has-word-break item">
                  {currentDish?.restaurantLocationName}
                </span>
              </div>
              <div>
                <span>Precio público:</span>
                <span className="item">{currentDish?.price.toFixed(2)}</span>
              </div>
              <div>
                <span>Costo:</span>
                <span className="item">{currentDish?.cost.toFixed(2)}</span>
              </div>
              <div>
                <span>Descripcion:</span>
                <div className="item-description">
                  {currentDish?.desc}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Insumos" key="1">
                {currentDish?.ingredients && currentDish?.ingredients.length > 0 && (
                  <div>
                    <Table
                      dataSource={insumosDataSource}
                      columns={columns}
                      pagination={paginationConfig}
                    />
                  </div>
                )}
              </TabPane>
              <TabPane tab="Adicionales" key="2">
                {currentDish?.addons && currentDish?.addons.length > 0 && (
                  <div>
                    <Table
                      dataSource={addonsDataSource}
                      columns={columns}
                      pagination={paginationConfig}
                    />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DishInfoMenu;

DishInfoMenu.defaultProps = {
  dishId: -1,
  isOpen: false,
  onStateChange: (() => { }),
};

DishInfoMenu.propTypes = {
  dishId: PropTypes.number,
  isOpen: PropTypes.bool,
  onStateChange: PropTypes.func,
};
