import {
  Button,
  // Button,
  Col,
  Layout,
  Row,
  Space,
  // Space,
  Typography,
  theme,
} from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  // useLocation,
  // useNavigate,
} from 'react-router';
import { Link } from 'react-router-dom';
import { Header } from 'antd/es/layout/layout';
import { BackwardOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

import ankiLogo from '../../../assets/img/logo-anki.png';
import ankiLogoWhite from '../../../assets/img/anki-logo-white.png';
import WebRestaurantLocation from '../../../features/web/restaurantLocation/WebRestaurantLocation';
import HomePage from '../../../features/web/home/HomePage';
import './web-layout.css';
import WebSpinner from '../../../common/components/Spinners/WebSpinner/WebSpinner';
import { selectWebLoading } from './webSlice';
import ShoppingCartMenuWeb from '../../../common/components/ShoppingCartMenu/ShoppingCartMenuWeb';
import TermsAndConditionsPage from '../../../features/web/termsAndConditions/TermsAndConditionsPage';
import ClaimsPage from '../../../features/web/claims/ClaimsPage';

const {
  // Header,
  Content,
  Footer,
} = Layout;

const {
  useToken,
} = theme;

const {
  Title,
} = Typography;

function IconWebLogo({ width, isWhite }) { return (<img src={!isWhite ? ankiLogo : ankiLogoWhite} width={width} alt="logo" />); }
IconWebLogo.defaultProps = { width: '80px', isWhite: false };
IconWebLogo.propTypes = { width: PropTypes.string, isWhite: PropTypes.bool };

const restaurantsPages = [
  {
    name: 'La Pipzza',
    link: 'http://lapipzza.com',
  },
  {
    name: 'Afan - Be a fan',
    link: 'http://afanburger.com',
  },
  {
    name: 'Brutal',
    link: 'http://brutalsalchipapa.com',
  },
];
const featuredLinks = [
  {
    name: 'Términos y condiciones',
    link: '/terms-and-conditions',
  },
  {
    name: 'Libro de reclamaciones',
    link: '/claims',
  },
];
const phonenNumbers = [
  {
    city: 'Arequipa',
    number: '955-756-006',
    link: 'https://wa.me/51955756006',
  },
  {
    city: 'Lima',
    number: '984-705-661',
    link: 'https://wa.me/51984705661',
  },
];

export default function WebLayout() {
  const { token } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector(selectWebLoading);

  const layoutContainerStyle = {
    width: '100%',
    maxWidth: '1140px',
    margin: '0 auto',
  };

  return (
    <Layout style={{ backgroundColor: token.lightColor, position: 'relative' }}>
      <WebSpinner isLoading={isLoading} />
      <Header
        style={{
          background: token.lightColor,
          alignItems: 'center',
          boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 14px -2px',
          height: 'unset',
          padding: '15px 10px',
          lineHeight: 'inherit',
          zIndex: '2',
          // position: 'fixed',
          // left: 0,
          // top: 0,
          // width: '100%',
        }}
      >
        <Layout
          style={{ ...layoutContainerStyle, backgroundColor: token.lightColor }}
        >
          <Space direction="horizontal">

            {
              location.pathname.includes('/stores/restaurant/')
              && (
                <Button
                  icon={<BackwardOutlined />}
                  onClick={() => { navigate('/'); }}
                />
              )
            }
            <Link to="/" onClick={() => { navigate('/'); window.location.reload(); }}>
              <IconWebLogo width="120px" />
            </Link>
          </Space>

        </Layout>

      </Header>
      <Layout
        style={{
          width: '100%',
          maxWidth: '1140px',
          margin: '0 auto',
          backgroundColor: token.lightColor,
        }}
        className="site-layout"
      >
        <Content className="main" style={{ backgroundColor: token.lightColor, margin: '0 0 45px' }}>
          {
            (!(location.pathname.includes('/terms-and-conditions')) && !(location.pathname.includes('/claims')))
            && <ShoppingCartMenuWeb isWeb />
          }
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/:citySlug" element={<HomePage />} />
            <Route path="stores/restaurant/:restaurantSlug/:locationSlug" element={<WebRestaurantLocation />} />
            <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
            <Route path="claims" element={<ClaimsPage />} />
          </Routes>
        </Content>
      </Layout>
      <Footer
        style={{
          backgroundColor: token.darkColor,
          color: token.lightColor,
          borderRadius: '15px 15px 0 0',
          paddingBottom: '50px',
        }}
      >
        <Layout
          style={{ ...layoutContainerStyle, backgroundColor: token.darkColor }}
        >

          <Row gutter={12}>
            <Col xs={24} sm={24} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Link to="/">
                <IconWebLogo width="128px" isWhite />
              </Link>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Title style={{ color: token.lightColor, marginTop: 0 }} level={4}>
                Cónoce nuestras marcas
              </Title>
              <ul className="footer__list">
                {
                  restaurantsPages.map((r) => (
                    <li key={r.name}><a href={r.link || ''} target="_blank" rel="noreferrer">{r.name}</a></li>
                  ))
                }
              </ul>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Title style={{ color: token.lightColor, marginTop: 0 }} level={4}>
                Enlaces de interés
              </Title>
              <ul className="footer__list">
                {
                  featuredLinks.map((r) => (
                    <li key={r.name}><Link to={r.link || ''}>{r.name}</Link></li>
                  ))
                }
              </ul>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Title style={{ color: token.lightColor, marginTop: 0 }} level={4}>
                Contacto
              </Title>
              <ul className="footer__list">
                {
                  phonenNumbers.map((r) => (
                    <li key={r.city}>
                      <a href={r.link}>
                        {r.city}
                        :
                        {' '}
                        {r.number}
                      </a>
                    </li>
                  ))
                }
              </ul>
            </Col>
          </Row>
        </Layout>

      </Footer>
      <ToastContainer />
    </Layout>
  );
}
