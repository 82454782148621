import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import styles from './restaurant-header.module.css';
import { dishCategoryMould, restaurantMould } from '../../../common/utils/objectsPropsMould';
import { getUrlByFileRoot } from '../../../common/utils/utils';

const {
  Text,
  Title,
} = Typography;

export default function RestaurantHeader({
  restaurant, categories, onCategoryChange, isOpen,
}) {
  const [currentCategories, setCurrentCategories] = useState([]);

  const setActiveCategory = (categoryId) => {
    const newCurrentCategories = currentCategories.map((c) => ({
      ...c,
      active: c.id === categoryId,
    }));
    setCurrentCategories(newCurrentCategories);
    onCategoryChange?.(categoryId);
  };

  useEffect(() => {
    setCurrentCategories(categories.map((c, index) => ({
      ...c,
      active: index === 0,
    })));
    if (categories.length > 0) {
      onCategoryChange?.(categories[0].id);
    }
  }, [categories]);

  return (
    <div className={styles['header-container']}>
      <div
        style={{ backgroundImage: `url('${getUrlByFileRoot(restaurant?.banner)}')` }}
        className={styles['header-container__banner']}
      >
        <div
          className={`${styles['header-container__state']} ${isOpen ? styles.open : styles.close}`}
        >
          {
            isOpen
              ? 'Abierto'
              : 'Cerrado'
          }
          <div className={styles['state-color']} />
        </div>
      </div>
      <div
        className={styles['header-container__restaurant-desc-container']}
      >
        <div
          className={styles['header-container__restaurant-desc-container--first-child']}
        >
          <div
            className={styles['header-container__restaurant-img']}
            style={{ backgroundImage: `url('${getUrlByFileRoot(restaurant?.logo)}')` }}
          />
        </div>
        <div>
          <Title level={2}>
            {restaurant?.restaurantName}
          </Title>
          <Text type="secondary">
            {restaurant?.schedule}
          </Text>
        </div>
      </div>
      <div className={styles['header-container__categories-container']}>
        <ul>
          {
            currentCategories.map((c) => (
              <li key={c.id} className={`${c.active && styles.active}`}>
                <button type="button" onClick={() => { setActiveCategory(c.id); }}>
                  {c.name}
                </button>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

RestaurantHeader.defaultProps = {
  restaurant: null,
  categories: null,
  onCategoryChange: null,
  isOpen: false,
};

RestaurantHeader.propTypes = {
  restaurant: PropTypes.shape(restaurantMould),
  categories: PropTypes.arrayOf(PropTypes.shape(dishCategoryMould)),
  onCategoryChange: PropTypes.func,
  isOpen: PropTypes.bool,
};
