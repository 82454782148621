import { faBox } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import CustomInput from '../../../../common/components/Extras/CustomInput/CustomInput';
import CustomSelect from '../../../../common/components/Extras/CustomSelect/CustomSelect';
import useForm from '../../../../common/hooks/useForm';
import { ValidationMould } from '../../../../common/utils/utils';
import { ingredientData as ingredientInitialData, ingredientValidations as ingredientInitialValidations } from './model';

export default function IngredientForm() {
  // eslint-disable-next-line no-unused-vars
  const [ingredient, setIngredient] = useState(ingredientInitialData);

  const ingredientForm = useForm(ingredient, ingredientInitialValidations);

  const categoryOptions = [
    {
      key: 1,
      value: 'Harinas',
    },
    {
      key: 2,
      value: 'Quesos',
    },
    {
      key: 3,
      value: 'Verduras',
    },
    {
      key: 4,
      value: 'Aderezos',
    },
  ];

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    const propExist = ingredientForm.changeDataProperty(name, value);
    if (propExist) {
      const isValid = ingredientForm.checkValidationByField(name, value);
      ingredientForm.updatingValidation({
        [name]: new ValidationMould({ isValid }),
      });
    }
  };

  return (
    <>
      <div className="is-size-4 has-text-centered has-text-weight-bold mb-5">
        {(ingredient && ingredient.name !== '') ? ingredient.name : 'Nuevo Insumo'}
      </div>
      <div>
        <form>
          <div className="columns is-multiline">
            <div className="column is-4">
              <CustomInput
                value={ingredientForm.data.name}
                label="Nombre"
                inputName="name"
                inputId="name"
                fieldValidations={ingredientForm.validations.name}
                placeholder="Ingrese el nombre del insumo"
                onInputChange={handleInputChange}
                leftIcon={faBox}
              />
            </div>
            <div className="column is-4">
              <CustomInput
                value={ingredientForm.data.price}
                label="Precio"
                inputName="price"
                inputType="number"
                min="0"
                inputId="price"
                fieldValidations={ingredientForm.validations.price}
                placeholder="Ingrese el precio del insumo"
                onInputChange={handleInputChange}
                leftIcon={faBox}
              />
            </div>
            <div className="column is-4">
              <CustomSelect
                value={ingredientForm.data.categoryId}
                options={categoryOptions}
                label="Categoria"
                name="category"
                selectId="category"
                leftIcon={faBox}
                onInputChange={handleInputChange}
              />
            </div>
            <div className="column is-4">
              <CustomInput
                value={ingredientForm.data.description}
                label="Descripcion"
                inputName="descripcion"
                inputType="textarea"
                inputId="descripcion"
                placeholder="Ingrese la descripcion del insumo"
                onInputChange={handleInputChange}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
