/* eslint-disable import/prefer-default-export */
import { api } from './config/axios';

const baseEndpoint = '/order/culqi';
const serviceBaseEndpoint = '/service/culqi';

export const fetchCulqiVars = () => api.get(`${baseEndpoint}/config`)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, por favor contactarse con el administrador',
  }));
export const checkCulqiAvailability = () => api.get(`${serviceBaseEndpoint}/check-availability`)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, por favor contactarse con el administrador',
  }));
export const sendToken = (jsonData) => api.post(`${serviceBaseEndpoint}/save-token`, jsonData)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, por favor contactarse con el administrador',
  }));
