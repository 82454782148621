import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/plots';
import {
  // Button,
  Space, Table, Tag,
} from 'antd';
/* import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
 */import { EditableCell, EditableRow } from './EditableCell';
import { admSave } from '../../../../api/locationOnRestaurant';
import { notify } from '../../../../common/utils/notifications';

function RestaurantaOnLocationList({
  stores, isLoading, fetchData,
}) {
  const [storesCollection, setStoresCollection] = useState(stores);
  const [isTableLoading, setTableLoading] = useState(false);
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
    },
    {
      title: 'Lugar',
      dataIndex: 'locationName',
    },
    {
      title: 'Restaurante',
      dataIndex: 'restaurantName',
    },
    {
      title: 'Disponible en la web',
      dataIndex: 'isAvailableOnWeb',
      type: 'boolean',
      render: (_, { isAvailableOnWeb }) => (

        isAvailableOnWeb
          ? <Tag color="success">Activo</Tag>
          : <Tag color="error">Desactivado</Tag>

      ),
      editable: true,
    },
  ];

  useEffect(() => {
    setStoresCollection(stores);
  }, [stores]);

  const components = {
    body: {
      cell: EditableCell,
      row: EditableRow,
    },
  };

  const handleSave = (row) => {
    setTableLoading(true);
    admSave(row.id, { isAvailableOnWeb: row.isAvailableOnWeb })
      .then(({ success, message }) => {
        notify({
          text: message,
          type: success ? 'success' : 'error',
        });

        if (success) {
          const newData = [...storesCollection];
          console.log('esta es la key', row);
          const index = newData.findIndex((item) => row.id === item.id);
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });

          setStoresCollection(newData);

          fetchData();
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  return (
    <Table
      components={components}
      pagination={false}
      dataSource={storesCollection}
      loading={isLoading || isTableLoading}
    >
      {
        columns.map((c) => (
          <Column
            dataIndex={c.dataIndex}
            key={c.dataIndex}
            title={c.title}
            render={c.render}
            editable={c.editable}
            onCell={c.editable ? (record) => ({
              record,
              editable: c.editable,
              dataIndex: c.dataIndex,
              title: c.title,
              handleSave,
              type: c.type,
            }) : () => { }}
          />
        ))
      }
      <Column
        title="Acciones"
        key="actions"
        // eslint-disable-next-line no-unused-vars
        render={(_, record) => (
          <Space>
            {/*  <Button onClick={() => onSelectedCoupon(record)}>
              <EditOutlined />
            </Button>
            <Button icon={<DeleteOutlined />} type="text" danger /> */}
          </Space>
        )}
      />
    </Table>
  );
}

export default RestaurantaOnLocationList;

RestaurantaOnLocationList.defaultProps = {
  stores: [],
  isLoading: false,
  /* onSelectedCoupon: () => { }, */
  fetchData: () => { },
};

RestaurantaOnLocationList.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  /* onSelectedCoupon: PropTypes.func, */
  fetchData: PropTypes.func,
};
