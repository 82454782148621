import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { validationMouldPropTypes } from '../../../utils/utils';

function CustomSelect({
  value,
  options,
  selectId,
  name,
  label,
  fieldValidations,
  onInputChange,
  isSearch,
  style,
  leftIcon,
}) {
  const [currentValue, setCurrentValue] = useState('');
  const [currentFieldValidations, setCurrentFieldValidations] = useState({});

  useEffect(() => {
    setCurrentValue(value);
    setCurrentFieldValidations(fieldValidations);
  }, []);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  useEffect(() => {
    setCurrentFieldValidations(fieldValidations);
  }, [fieldValidations]);

  return (
    <div className="field">
      {
            label !== ''
            && (
              <label htmlFor={selectId} className="label">
                {label}
                {' '}
                {
                  currentFieldValidations
                  && (
                    currentFieldValidations.isRequired
                    && (
                      <span className="has-text-danger">*</span>
                    ))
                }
                {' '}
              </label>
            )
          }
      <div className={`control has-icons-right ${leftIcon && 'has-icons-left'} `}>
        <div className="select is-fullwidth">
          <select
            name={name}
            value={currentValue}
            onChange={onInputChange}
            style={style}
          >
            {
              options.map((opt) => (
                <option key={opt.key} value={opt.key}>
                  {opt.value}
                </option>
              ))
            }
          </select>
        </div>
        {
              leftIcon
              && (
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={leftIcon} />
                </span>
              )
            }
        {
              currentFieldValidations
              && (
                <span className="icon is-small is-right">
                  <FontAwesomeIcon className={`${currentFieldValidations.isValid ? 'has-text-success' : 'has-text-danger'}`} icon={faCheck} />
                </span>
              )
            }
        {
              isSearch
              && (
                <span className="icon is-small is-right">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              )
            }
      </div>
      {currentFieldValidations
            && (
              !currentFieldValidations.isValid
              && (
                <div className="help has-text-danger">
                  {currentFieldValidations.errorMessage}
                </div>
              ))}
    </div>
  );
}

CustomSelect.defaultProps = {
  selectId: '',
  name: '',
  options: [],
  label: '',
  fieldValidations: null,
  isSearch: false,
  style: null,
  leftIcon: null,
};

CustomSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  selectId: PropTypes.string,
  name: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  fieldValidations: PropTypes.shape({ ...validationMouldPropTypes }),
  isSearch: PropTypes.bool,
  style: PropTypes.shape({}),
  leftIcon: PropTypes.shape({}),
};

export default CustomSelect;
