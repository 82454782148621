import React, { useEffect, useRef, useState } from 'react';
import { Pie, Column } from '@ant-design/plots';
import {
  Button,
  Card, Col, DatePicker, Form, Row, Select, Space, Statistic, Table,
} from 'antd';
import dayjs from 'dayjs';
import {
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { getDashboardData, getDashboardParams } from '../../api/report';
import { getDateAlteredByMin } from '../../common/utils/utils';
import { M_PER_WEEK } from '../../common/utils/constants';
import { setLoading } from '../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';
import { setCurrentPageTitle } from '../../app/Layouts/appLayoutSlice';
import ModalDishDetails from './modalDishDetails/ModalDishDetails';

const { RangePicker } = DatePicker;

/** function SelectChkBox({ options, onChange, value }) {
  const [selecteOptions, setSelectedOptions] = useState([]);
  const [dirty, setDirty] = useState(false);
  const handleChange = (value) => {
    onChange(value);
    setDirty(true);
  };

  return (
    <Select
      onChange={handleChange}
      options={[options.map((opt) => (
        <Checkbox

        />
      ))]}
    >

    </Select>
  )
}* */

export default function Dashboard() {
  const dispatch = useDispatch();

  /** MODAL */
  const [modalDishDetailState, setModalDishDetails] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  /** * FORM */
  const model = {
    range: [dayjs(getDateAlteredByMin(new Date(), M_PER_WEEK)), dayjs(new Date())],
    locationsIds: [],
    restaurantsIds: [],
  };
  const [form] = Form.useForm();

  const [locationOpts, setLocationOpts] = useState([]);
  const [restaurantOpts, setRestaurantOpts] = useState([]);
  /** *************** */

  /** * SUMMARY */
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [summaryObj, setSummaryObj] = useState({
    sales: 0,
    ordersQ: 0,
    orderTotalAverage: 0,
  });

  /** ** GRAPHICS DATA *** */
  const [ordersPerDayData, setOrdersPerDayData] = useState([]);

  const ordersPerDayGraphicConfig = useRef({
    data: ordersPerDayData,
    xField: 'date',
    yField: 'value',
    seriesField: 'restaurant',
    isGroup: 'true',
  });

  const [soldItemsData, setSoldItemsData] = useState([]);

  const soldItemsGraphicConfing = useRef({
    appendPadding: 10,
    data: soldItemsData,
    angleField: 'dishesCount',
    colorField: 'name',
    radius: 0.8,
    innerRadius: 0.8,
    label: {
      type: 'inner',
      offset: '-10%',
    },
  });

  /** ********************************* */
  /** **** TABLE ORDERS SUMMARY */
  const ordersTableCols = [
    {
      title: 'N°',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Restaurante',
      dataIndex: 'restaurantLocationName',
      key: 'restaurantLocationName',
    },
    {
      title: 'Canal',
      dataIndex: 'channel',
      key: 'channel',
    },
    {
      title: 'Monto',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button onClick={() => { setModalDishDetails(true); setCurrentOrder(record); }}>
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  const [ordersData, setOrdersData] = useState([]);

  /** **************************** */

  const getFiltersObj = () => {
    const [startDateObj, endDateObj] = (form.getFieldValue('range') || model.range);
    const startDate = `${dayjs(startDateObj.$d).format('YYYY-MM-DD')}`;
    const endDate = `${dayjs(endDateObj.$d).format('YYYY-MM-DD')}`;

    return {
      startDate,
      endDate,
      locationsIds: (form.getFieldValue('locationsIds') || model.locationsIds),
      restaurantsIds: (form.getFieldValue('restaurantsIds') || model.restaurantsIds),
    };
  };

  const setDashboardData = ({
    categories,
    orders,
    ordersCountByRestaurantArray,
    ordersTotal,
    ordersTotalAverage,
    ordersCount,
  }) => {
    setOrdersData(orders);
    setSoldItemsData(categories);
    setOrdersPerDayData(ordersCountByRestaurantArray);
    setSummaryObj({
      sales: ordersTotal,
      ordersQ: ordersCount,
      orderTotalAverage: ordersTotalAverage,
    });
  };

  const handleFormSubmit = () => {
    form.validateFields()
      .then(() => {
        const filtersObj = getFiltersObj();
        setLoading(true);
        getDashboardData(filtersObj)
          .then(({
            data, success,
          }) => {
            if (success) {
              setDashboardData(data);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
  };

  useEffect(() => {
    dispatch(setCurrentPageTitle('Dashboard'));
    const filtersObj = getFiltersObj();
    setIsLoading(true);
    Promise.all([
      getDashboardData(filtersObj),
      getDashboardParams(),
    ])
      .then(([{
        data: dashboardData, success,
      }, { data: { locationCollection, restaurantCollection }, success: paramsSuccess }]) => {
        if (success) {
          setDashboardData(dashboardData);
        }
        if (paramsSuccess) {
          console.log('estas son las colleciones', locationCollection, restaurantCollection);
          setLocationOpts(locationCollection.map((l) => ({
            value: l.id,
            label: l.name,
            key: l.id,
          })));
          setRestaurantOpts(restaurantCollection.map((r) => ({
            value: r.id,
            label: r.name,
            key: r.id,
          })));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <ModalDishDetails
        isOpen={modalDishDetailState}
        onCloseModal={() => { setModalDishDetails(false); }}
        orderObj={currentOrder}
      />
      <div className="filters-container">
        <Form
          initialValues={model}
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Row gutter={[16, 32]} justify="end">
            <Col>
              <Form.Item
                name="locationsIds"
              >
                <Select
                  mode="multiple"
                  placeholder="Locaciones (Todos)"
                  style={{ width: 250 }}
                  options={locationOpts}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="restaurantsIds"
              >
                <Select
                  mode="multiple"
                  placeholder="Restaurantes (Todos)"
                  style={{ width: 250 }}
                  options={restaurantOpts}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="range"
              >
                <RangePicker
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  htmlType="submit"
                >
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="balances-container">
        <Row gutter={[16, 8]}>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic
                title="Ventas Totales"
                value={summaryObj.sales}
                precision={2}
                prefix="S/"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic
                title="N° de Pedidos"
                value={summaryObj.ordersQ}
                precision={0}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic
                title="Ticket Promedio"
                value={summaryObj.orderTotalAverage}
                precision={2}
                prefix="S/"
              />
            </Card>
          </Col>
        </Row>
      </div>
      <div className="graphics-container mt-1">
        <Row gutter={16}>
          <Col span={12}>
            <Card bordered={false}>
              <Column
                loading={isLoading}
                data={ordersPerDayData}
                xField={ordersPerDayGraphicConfig.current.xField}
                yField={ordersPerDayGraphicConfig.current.yField}
                seriesField={ordersPerDayGraphicConfig.current.seriesField}
                isGroup={ordersPerDayGraphicConfig.current.isGroup}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false}>
              <Pie
                loading={isLoading}
                appendPadding={soldItemsGraphicConfing.current.appendPadding}
                data={soldItemsData}
                angleField={soldItemsGraphicConfing.current.angleField}
                colorField={soldItemsGraphicConfing.current.colorField}
                radius={soldItemsGraphicConfing.current.radius}
                innerRadius={soldItemsGraphicConfing.current.innerRadius}
                label={soldItemsGraphicConfing.current.label}
                statistic={{ title: false, content: undefined }}
              />
            </Card>

          </Col>
        </Row>
      </div>
      <div className="orders-summary-container mt-1">
        <Table
          dataSource={ordersData}
          columns={ordersTableCols}
          loading={isLoading}
          pagination={{ pageSize: 10 }}
          size="small"
        />
      </div>
    </>
  );
}
