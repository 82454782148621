import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CustomInput from '../../../../common/components/Extras/CustomInput/CustomInput';
import IngredientsList from './AdmIngredientsList/IngredientsList';
import { fetchAdmIngredientsByUser, selectAdmIngredients } from '../admIngredientsSlice';
import useSearch from '../../../../common/hooks/useSearch';
import LoadFileForm from '../LoadFileForm/LoadFileForm';

export default function IngredientsPage() {
  const dispatch = useDispatch();

  const search = useSearch();
  const ingredients = useSelector(selectAdmIngredients);
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    dispatch(fetchAdmIngredientsByUser());
  }, []);

  useEffect(() => {
    console.log('se han cambiado los ignredientes', ingredients);
  }, [ingredients]);

  return (
    <>
      <LoadFileForm state={modalState} changeState={setModalState} />
      <div className="btn-item-form__container">
        <button type="button" className="button mr-4" onClick={() => { setModalState(true); }}>
          <FontAwesomeIcon icon={faFileExcel} />
        </button>
        <Link to="add" className="button">
          <FontAwesomeIcon icon={faAdd} />
        </Link>
      </div>
      <div className="columns">
        <div className="column is-12">
          <div className="is-size-3 has-text-centered has-text-weight-bold ">
            Insumos
          </div>
        </div>
      </div>
      <div>
        <div className="is-flex is-justify-content-end">
          <CustomInput
            value={search.value}
            onInputChange={search.handleSearchInputChange}
            inputName="search"
            inputId="search"
            style={{ minWidth: '25rem' }}
            placeholder="Buscar Insumos"
            leftIcon={faSearch}
            size="small"
            className="my-auto"
          />
          <button type="button" className="button is-link ml-2 is-small my-auto">
            Buscar
          </button>
        </div>

      </div>
      <div />
      <div className="py-2">
        <IngredientsList ingredients={ingredients} />
      </div>
    </>
  );
}
