import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SupplierMould } from '../../../../../common/utils/objectsPropsMould';

export default function AdmSupplierRow({ supplier }) {
  return (
    <tr>
      <td>{supplier.name}</td>
      <td>{supplier.address}</td>
      <td>{supplier.contact}</td>
      <td>{supplier.phone}</td>
      <td>{supplier.email}</td>
      <td className="actions">
        <button type="button" className="is-inline-block button is-white">
          <FontAwesomeIcon icon={faEdit} className="has-text-warning" />
        </button>
        <button type="button" href="#" className="is-inline-block button is-white">
          <FontAwesomeIcon icon={faTrash} className="has-text-danger" />
        </button>
      </td>
    </tr>
  );
}

AdmSupplierRow.defaultProps = {
  supplier: null,
};

AdmSupplierRow.propTypes = {
  supplier: PropTypes.shape(SupplierMould),
};
