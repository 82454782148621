import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { colsOrder, colsOrderPropType } from '../../../utils/constants';

export default function CustomHeaderRow({ columns, onColumnsChange }) {
  const [currentColumns, setCurrentColumns] = useState(columns);

  const onOptOrderChange = (colName, currentOrderValue) => {
    const newValue = (currentOrderValue === colsOrder.DESC) ? colsOrder.ASC : colsOrder.DESC;
    const columnsCopy = [];
    currentColumns.forEach((col) => {
      if (col.name === colName) {
        columnsCopy.push({ ...col, order: newValue });
      } else {
        columnsCopy.push({ ...col });
      }
    });
    onColumnsChange(columnsCopy);
  };

  useEffect(() => {
    setCurrentColumns(columns);
  }, [columns]);

  return (
    <tr>
      {
                currentColumns.map((col) => (
                  <th
                    className={`${col.class ? col.class : ''}`}
                    key={col.name}
                  >
                    {col.label}
                    {
                      (col.order)
                        && (
                          <button type="button" className="btn-link ml-2" onClick={() => { onOptOrderChange(col.name, col.order); }}>
                            <FontAwesomeIcon
                              icon={col.order === colsOrder.ASC ? faCaretUp : faCaretDown}
                            />
                          </button>
                        )
                    }
                  </th>
                ))
            }
    </tr>

  );
}

CustomHeaderRow.defaultProps = {
  columns: [],
};

CustomHeaderRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    field: PropTypes.string,
    order: colsOrderPropType,
  })),
  onColumnsChange: PropTypes.func.isRequired,
};
