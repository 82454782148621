import axios from 'axios';
import { api } from './config/axios';

const getAllByUser = (userId) => axios.get('https://www.thecocktaildb.com/api/json/v1/1/filter.php?i=Vodka')
  .then(({ data }) => {
    console.log(userId);
    console.log(data);
    return {
      success: true,
      data: [
        {
          id: 1,
          name: 'Extra picante',
          price: 10,
          measureUnit: 'Kg',
          purchasePrice: 1,
          salePrice: 1.5,
        },
        {
          id: 2,
          name: 'Borde de queso',
          price: 1.5,
          measureUnit: 'Kg',
          purchasePrice: 1,
          salePrice: 1.5,
        },
        {
          id: 3,
          name: 'Queso gouda',
          price: 1.5,
          measureUnit: 'Kg',
          purchasePrice: 1,
          salePrice: 1.5,
        },
        {
          id: 4,
          name: 'Queso fundido',
          price: 1.5,
          measureUnit: 'Kg',
          purchasePrice: 1,
          salePrice: 1.5,
        },
        {
          id: 5,
          name: 'Queso azul',
          price: 1.5,
          measureUnit: 'Kg',
          purchasePrice: 1,
          salePrice: 1.5,
        },
        {
          id: 6,
          name: 'Queso azul',
          price: 1.5,
          measureUnit: 'Kg',
          purchasePrice: 1,
          salePrice: 1.5,
        },
        {
          id: 7,
          name: 'Queso azul',
          price: 1.5,
          measureUnit: 'Kg',
          purchasePrice: 1,
          salePrice: 1.5,
        },
      ],
      message: 'Orden creada con exito',
    };
  })
  .catch((error) => (
    {
      success: false,
      data: error,
      message: 'Ha Habido un error',
    }
  ));

const loadIngredients = (form) => api.post(
  '/ingredient/load-file/',
  form,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
)
  .then(({ data }) => data)
  .catch(() => ({
    success: 'false',
    data: '',
    message: 'Ha habido un error, contactar al administrador',
  }));

const getAllByLocation = (locationId) => api.get(`/ingredient/list/location/${locationId}`)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, intente más tarde',
  }));

export { getAllByUser, loadIngredients, getAllByLocation };
