/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import Search from 'antd/es/input/Search';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  LoadingOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import {
  selectShoppingCart,
  setDishQuantity,
  selectShoppingCartState,
  setState,
  updateCartTotals,
  removeFromCart,
  reset as resetCart,
  setOrderId,
  setCouponDiscount,
  selectShoppingCartDiscount,
  selectShoppingCartDelivery,
  updateWebCartTotals,
  selectShoppingCartCoupon,
  setCouponCode,
} from './shoppingCartSlice';
import PurchaseModal from './ShoppingCartPurchaseModal/PurchaseModal';
import Quantifier from '../Quantifier/Quantifier';
import { notify } from '../../utils/notifications';
import './shopping-car-menu-web.css';
// import CustomInput from '../Extras/CustomInput/CustomInput';
// import AutoCompleteAddressInput from
// '../Extras/AutoCompleteAddressInput/AutoCompleteAddressInput';
import {
  //  getDeliveryPrice,
  //  getKmFromM,
  getNextOrderState,
} from '../../utils/utils';
import { saveWebOrder } from '../../../api/web';
import useCulqi from '../../hooks/useCulqi';
import CustomerWebModal from './ShoppinCartWebCustomerData/CustomerWebModal';
import { checkCoupon } from '../../../api/coupon';
// import CustomImg from '../Extras/CustomImg/CustomImg';
// import { filesRoot } from '../../../api/config/axios';

const { Text, Title } = Typography;

const currentDeviceIsMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);

function CartSpinner({ isLoading }) {
  return (
    isLoading
    && (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0,0,0,0.15)',
          zIndex: 9999,
          width: '100%',
          height: '100%',
          overflow: 'visible',
          top: 0,
          left: 0,
          borderRadius: 10,
        }}
      >
        <Spin indicator={(
          <LoadingOutlined
            style={{
              fontSize: 54,
            }}
          />
        )}
        />
      </div>
    )
  );
}
CartSpinner.defaultProps = {
  isLoading: false,
};
CartSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

function ShoppingCartMenuWeb({
  isWeb,
}) {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectShoppingCartState);
  const [isLoading, setLoading] = useState(false);
  const [isPurchaseModalOpen, setPurchaseModalState] = useState(false);
  const [clientAddress, setClientAddress] = useState('');
  const [clientDistrict, setClientDistrict] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  // const [deliveryPrice, setDeliveryPrice] = useState(0);
  const deliveryPrice = useSelector(selectShoppingCartDelivery);
  const {
    activateCulqiCheckout,
    culqiIsLoading,
    successfulPayment,
    culqiIsActive,
  } = useCulqi();

  const userLocation = useRef(null);
  // const [clientAddressObj, setClientAddressObj] = useState('');

  // const shoppingCartMenuWidth = '31rem';

  const shoppingCartData = useSelector(selectShoppingCart);
  const cartDiscount = useSelector(selectShoppingCartDiscount);

  /** HANDLE COUPON */
  // const [couponCode, setCouponCode] = useState('');
  const couponCode = useSelector(selectShoppingCartCoupon);
  const couponCodeRef = useRef(couponCode);
  couponCodeRef.current = couponCode;

  const [availableDiscount, setAvailableDiscount] = useState(0);
  const [isCouponLoading, setCouponLoading] = useState(false);
  const fetchCouponDiscount = () => {
    setCouponLoading(true);
    const dishesCollection = shoppingCartData.dishes.map((d) => ({ dishId: d.id }));
    const lCCouponCode = couponCode.toLowerCase();
    checkCoupon(dishesCollection, lCCouponCode)
      .then(({ data, success }) => {
        const discount = data?.discount || 0;

        setAvailableDiscount(discount);
        dispatch(setCouponDiscount(discount));
      })
      .finally(() => {
        setCouponLoading(false);
      });
  };
  const handleCouponCode = (value) => {
    dispatch(setCouponCode(value));
    /* setTimeout((newValue) => {
      console.log('entra al timeout', couponCodeRef.current, couponCode, newValue);
      if (newValue !== '' && newValue === couponCodeRef.current) {
        fetchCouponDiscount();
      }
    }, 1000, value); */
  };
  /** ***** */
  const onClickHandler = () => {
    dispatch(setState(!isOpen));
  };

  const onQuantityChangeHandler = (obj) => {
    dispatch(setDishQuantity(obj));
  };

  const fetchCurrentUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        userLocation.current = position;
      });
    }
  };
  useEffect(() => {
    if (culqiIsActive) {
      setLoading(culqiIsLoading);
    }
  }, [culqiIsLoading]);

  useEffect(() => {
    fetchCurrentUserLocation();
  });

  useEffect(() => {
    dispatch(updateWebCartTotals());
  }, [shoppingCartData]);

  const cleanFields = () => {
    setClientAddress('');
  };

  const reset = () => {
    dispatch(resetCart());
    cleanFields();
    dispatch(setState(!isOpen));
    // notifySuccess();
    notify({
      text: 'Se ha limpiado el carrito',
      type: 'success',
      autoClose: 1000,
    });
  };

  const changePurchaseModalState = (val, resetFields = false) => {
    console.log('seteamos el stado del modal en el carrito', val);
    if (resetFields) {
      cleanFields();
    }
    setPurchaseModalState(val);
  };

  const openPurchaseModal = () => {
    const message = shoppingCartData.dishes.length < 1 && 'Carrito vacío';
    if (shoppingCartData.dishes.length > 0) {
      changePurchaseModalState(true);
    } else {
      notify({
        text: message,
        type: 'error',
        autoClose: 1000,
      });
    }
  };

  /** * WEB METHODS */
  const sendWhatsappOrder = (extraMessage = '') => {
    const defaultPhoneNumber = '51955756006';
    const selectedDishes = shoppingCartData.dishes.map((d) => ({
      name: d.name,
      price: d.price,
      quantity: d.quantity,
    }));
    const totalStore = shoppingCartData.total;
    const message = `
Hola, quisiera realizar el siguiente pedido realizado desde ${window.location.origin}:%0A
%0A
${selectedDishes.map((d) => `${d.quantity} x ${d.name} (P/U ${d.price}) ----> Total del Plato: ${(d.quantity * d.price).toFixed(2)}`).join('%0A')}
%0A%0A
%0A
Total de la compra: S/${totalStore.toFixed(2)}
%0A%0A
${extraMessage}
    `;
    // const link = document.createElement('a');
    /** if (currentDeviceIsMobile()) {
      link.setAttribute('target', '_blank');
      link.setAttribute('href', `whatsapp://send?abid=+${shoppingCartData.locationWebPhone || defaultPhoneNumber}&text=${message}`);
    } else { */
    const urlToRedirect = `https://wa.me/${shoppingCartData.locationWebPhone || defaultPhoneNumber}/?text=${message}`;
    window.location.href = urlToRedirect;
    // link.setAttribute('target', '_blank');
    // link.setAttribute('href', urlToRedirect );
    // }
    // link.click();

    // link.remove();
  };

  useEffect(() => {
    /* notify({
      text: 'Pago procesado',
      type: successfulPayment ? 'success' : 'error',
    }); */
    let extraMessage = '';
    if (successfulPayment) {
      extraMessage = 'Pago procesado con éxito';
    }
    // sendWhatsappOrder(extraMessage);
  }, [successfulPayment]);

  const generateJson = () => {
    const orderState = getNextOrderState(0);
    const json = {
      dishes: [],
      state: `${orderState.id}:${orderState.name}`,
      subtotal: shoppingCartData.subtotal,
      totalIGV: shoppingCartData.totalIGV,
      total: shoppingCartData.total,
    };
    json.dishes = shoppingCartData.dishes.map((d) => (
      {
        ...d,
        ingredients: d.ingredients ? JSON.stringify(d.ingredients) : '',
        addons: d.addons ? JSON.stringify(d.addons) : '',
        dishes: (d.dishes && d.dishes?.length > 0) ? JSON.stringify(d.dishes) : '',
      }
    ));
    return json;
  };
  const saveOrder = (customerData = {}) => {
    if (isPurchaseModalOpen) {
      setPurchaseModalState(false);
    }
    /** FILTERING CUSTOMER DATA FOR WS MS */
    const ftCustomerData = customerData?.customerData;
    const wsDistrict = ftCustomerData?.district || '';
    const wsCity = ftCustomerData?.city || '';
    const newMessage = `Mi dirección es ${wsCity} - ${wsDistrict}`;
    setClientDistrict(wsDistrict);
    setClientAddress(wsCity);
    /** ***************** */
    let jsonData = generateJson();
    jsonData = { ...jsonData, ...customerData };
    setLoading(true);

    saveWebOrder({ ...jsonData }).then(({ success, message, data }) => {
      notify({
        text: message,
        type: success ? 'success' : 'error',
      });
      if (success) {
        dispatch(resetCart());
        sendWhatsappOrder(newMessage);
        console.log(jsonData);
        if (data.orderId) {
          dispatch(setOrderId(data.orderId));
          // activateCulqiCheckout({ total: jsonData.total, id: data.orderId });
        }
        //
      }
    })
      .finally(() => {
        setLoading(false);
      });
  };

  /** ********************* */

  return (
    <React.StrictMode>

      <CustomerWebModal
        isOpen={isPurchaseModalOpen}
        onModalStateChange={changePurchaseModalState}
      />

      <div onClick={onClickHandler} className={`${!isOpen ? 'btn-car-container-web' : 'btn-car-container'}`}>
        {
          !isOpen
          && (
            <>
              <Tag color="black">
                {shoppingCartData.dishes.length}
              </Tag>
              <Title level={4} style={{ margin: 0, color: '#fff' }}>
                Ver carrito
                {' '}
                <ShoppingCartOutlined />
              </Title>
            </>
          )
        }
        <Button
          icon={isOpen ? <CloseCircleOutlined /> : <ShoppingOutlined />}
          type="primary"
          ghost
          onClick={onClickHandler}
        />
      </div>
      {/** OPTIMIZAR CLICK HANDLER */}
      <div className={`car-menu ${isOpen ? 'is-open' : ''}`} onClick={onClickHandler} >
        {/** OPTIMIZAR CLICK HANDLER */}
        <CartSpinner isLoading={isLoading} />
        <div id="car-menu-container" className="car-menu__container" onClick={(e) => { e.stopPropagation(); }} >
          <div className="cart-menu__title-container">
            <Title level={2} strong style={{ marginTop: 0 }} >
              <ShoppingCartOutlined />
              {' '}
              Carrito
            </Title>
          </div>
          <div style={{
            overflowY: 'scroll', position: 'relative',
          }}
          >
            <Card size="small" style={{ overflowY: 'scroll', maxHeight: '65vh', minHeight: '65vh' }}>
              <Space
                direction="vertical"
                style={{
                  display: 'flex',
                }}
              >
                {shoppingCartData.dishes.map((dish) => (
                  <Card
                    key={`item_${dish.uid}`}
                  >
                    <div className="car-menu__items-grid">
                      <div>
                        <div>
                          <Title strong level={5} className="items__title" >
                            {dish.name}
                          </Title>
                        </div>
                        <div>
                          {(dish.ingredients && dish.ingredients.length > 0)
                            && (
                              <p>
                                <Text strong type="secondary">
                                  No agregar:
                                  {' '}
                                </Text>
                                <Text type="secondary">
                                  {dish.ingredients.map((ing) => ing.name).join(', ')}
                                </Text>
                              </p>
                            )}
                          {(dish.addons && dish.addons.length > 0)
                            && (
                              <p>
                                <Text strong type="secondary">
                                  Adicionales:
                                  {' '}
                                </Text>
                                <Text type="secondary">
                                  {dish.addons.map((addon) => addon.name).join(', ')}
                                </Text>
                              </p>
                            )}
                          {(dish.dishes && dish.dishes.length > 0)
                            && (
                              <p>
                                <span className="has-text-weight-bold has-text-grey-dark is-size-7">
                                  Platos:
                                  {' '}
                                </span>
                                <span className="is-size-7 has-text-grey">
                                  {dish.dishes.map((d) => d.title).join(', ')}
                                </span>
                              </p>
                            )}
                        </div>
                      </div>
                      <div>
                        <Space direction="vertical" align="end" style={{ display: 'flex' }}>
                          <Button
                            icon={<DeleteOutlined />}
                            shape="circle"
                            danger
                            onClick={() => { dispatch(removeFromCart(dish.uid)); }}
                          />
                          <Quantifier
                            value={dish.quantity}
                            maxWidth="140px"
                            onQuantityChange={(newValue) => {
                              onQuantityChangeHandler({ uid: dish.uid, quantity: newValue });
                            }}
                            isSmall
                          />
                          <div>
                            <Text strong>
                              Precio /u:
                              {' '}
                            </Text>
                            <Text type="secondary">
                              {dish.price.toFixed(2)}
                            </Text>
                          </div>
                        </Space>
                      </div>
                    </div>
                  </Card>
                ))}
              </Space>
            </Card>
            <Card size="small">
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Row>
                  <Col span={24} >
                    <Text strong>
                      Introduce tu cupón de descuento (opcional):
                    </Text>
                    <br />
                    <Search
                      style={{ marginBottom: '15px', marginTop: '10px' }}
                      placeholder="Código del cupón"
                      value={couponCode}
                      loading={isCouponLoading}
                      onChange={({ target: { value } }) => { handleCouponCode(value); }}
                      onSearch={fetchCouponDiscount}
                    />
                    <br />
                    {
                      (cartDiscount && cartDiscount !== 0 && availableDiscount !== 0)
                        ? (
                          <p>
                            Descuento:
                            {' '}
                            S/
                            {' '}
                            {cartDiscount}
                          </p>
                        )
                        : (
                          <Typography.Text disabled>Sin descuento aplicado</Typography.Text>
                        )
                    }
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong>
                      Subtotal:
                    </Text>
                  </Col>
                  <Col>
                    {(cartDiscount && cartDiscount !== 0)
                      ? (
                        <>
                          <Text delete style={{ color: '#cf1322' }}>
                            {shoppingCartData.subtotal}
                          </Text>
                          {' '}
                          <Text>

                            {shoppingCartData.totalsWithDiscount.subtotal}

                          </Text>

                        </>

                      )
                      : (
                        <Text>
                          {shoppingCartData.subtotal}
                        </Text>
                      )}
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong>
                      Total (inc. IGV):
                    </Text>
                  </Col>
                  <Col>
                    {(cartDiscount && cartDiscount !== 0)
                      ? (
                        <>
                          <Text delete style={{ color: '#cf1322' }}>
                            {shoppingCartData.total}
                          </Text>
                          {' '}
                          <Text>

                            {shoppingCartData.totalsWithDiscount.total}

                          </Text>

                        </>

                      )
                      : (
                        <Text>
                          {shoppingCartData.total}
                        </Text>
                      )}
                  </Col>
                </Row>
                <Row justify="start">
                  <Space direction="horizontal" >
                    <Button onClick={reset} danger disabled={isLoading} >
                      Cancelar Pedido
                    </Button>
                    <Button type="primary" onClick={openPurchaseModal}>
                      Continuar
                    </Button>
                  </Space>
                </Row>
              </Space>
            </Card>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
}

ShoppingCartMenuWeb.defaultProps = {
  isWeb: false,
};

ShoppingCartMenuWeb.propTypes = {
  isWeb: PropTypes.bool,
};

export default ShoppingCartMenuWeb;
