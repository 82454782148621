import { useMemo } from 'react';
import { paginatorDots } from '../utils/constants';

const range = (start, end) => {
  const length = (end - start) + 1;

  const rangeArray = Array.from({ length }, (_, index) => index + start);
  return rangeArray;
};

export default function usePagination({
  rowCount,
  rowsPerPage,
  siblingCount = 1,
  currentPage,
}) {
  const pagesRange = useMemo(() => {
    const totalPageCount = Math.ceil(rowCount / rowsPerPage);

    const totalPaginationLinkItems = siblingCount + 5;

    if (totalPaginationLinkItems >= totalPageCount) {
      return range(1, totalPageCount);
    }
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    /*
      We do not want to show dots if there is only one position left
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      console.log('entra al primero', shouldShowLeftDots, shouldShowRightDots);
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, paginatorDots, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      console.log('entra al medio', shouldShowLeftDots, shouldShowRightDots);
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, paginatorDots, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      console.log('entra al ultimo');
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, paginatorDots, ...middleRange, paginatorDots, lastPageIndex];
    }

    return [1];
  }, [rowCount, rowsPerPage, siblingCount, currentPage]);

  return pagesRange;
}
