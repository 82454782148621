import React,
{
  // useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import {
  locationTypesForGoogleAPI,
  countriesRestrictionsForGoogleAPI,
} from '../../../utils/constants';
import { selectCurrentLocation } from '../../../../app/Layouts/WebLayout/webSlice';

// import { loadScript } from '../../../utils/utils';
// import CustomInput from '../CustomInput/CustomInput';
// import { setUpAutocomplete } from '../../../utils/google';

function AutoCompleteAddressInputWeb({
  value,
  // onInputChange,
  onPlaceChanged,
  onChange,
  centerPointObj,
  disabled,
}) {
  /** OBTENEMOS LA LOCACIÓN DEL STORE */
  const currentLocation = useSelector(selectCurrentLocation);
  const currentLocationLatLng = useRef(
    currentLocation
    && {
      lat: currentLocation?.latitude,
      lng: currentLocation?.longitude,
    },
  );
  /* ------------------------- */
  // const [address, setAddress] = useState('');
  const inputRef = useRef(null);
  inputRef.current = value;
  // console.log('este es el input ref', inputRef.current);
  // CONSIDERAR PUNTO DE REFERENCIA COMO LA UBICACION DEL USUARIO
  const autoCompleteRef = useRef(null);

  const googleProps = {
    types: locationTypesForGoogleAPI,
    componentRestrictions: { country: countriesRestrictionsForGoogleAPI },
    fields: ['place_id', 'geometry', 'name', 'vicinity', 'formatted_address'],
    strictBounds: true,
  };

  const onPlaceChangedHandler = () => {
    const place = autoCompleteRef.current.getPlace();

    if (!place || !place.geometry) {
      inputRef.current.placeholder = 'Ingresa una dirección';
    } else {
      // setAddress(place.name);
      // onInputChange(`${place.name}, ${place.formatted_address}`);
      onChange(`${place.name}, ${place.formatted_address}`);
      onPlaceChanged(place);
      // onInputChange(inputRef.current);
      console.log('data del lugar', place);
    }
  };

  const setGoogleAutoComplete = () => {
    // eslint-disable-next-line no-undef
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      googleProps,
    );

    autoCompleteRef.current.addListener('place_changed', onPlaceChangedHandler);
  };

  const fetchNewBoundsToAutoCompleteObj = () => {
    const currentCenterPoint = centerPointObj || currentLocationLatLng.current;
    if (currentCenterPoint) {
      const googleBounds = {};
      googleBounds.north = currentCenterPoint.lat + 0.15;
      googleBounds.south = currentCenterPoint.lat - 0.15;
      googleBounds.east = currentCenterPoint.lng + 0.15;
      googleBounds.west = currentCenterPoint.lng - 0.15;
      // eslint-disable-next-line no-undef
      const newBounds = new google.maps.LatLngBounds(
        {
          lat: googleBounds.south,
          lng: googleBounds.west,
        },
        {
          lat: googleBounds.north,
          lng: googleBounds.east,
        },
      );
      autoCompleteRef.current.setBounds(newBounds);
    }
  };

  useEffect(() => {
    setGoogleAutoComplete();
  }, []);

  useEffect(() => {
    fetchNewBoundsToAutoCompleteObj();
  }, [centerPointObj]);

  useEffect(() => {
    if (value === '') {
      inputRef.current.value = '';
    }
  }, [value]);

  return (
    <>
      {/* <CustomInput
        inputId="inputAutocomplete"
        value={address}
        onInputChange={handleInputChange}
      /> */}
      <Input
        // value={address}
        disabled={disabled}
        value={inputRef.current}
        prefix={<EnvironmentOutlined />}
        placeholder="Dirección"
        onChange={onChange}
        ref={(r) => { inputRef.current = r?.input; }}
      />
      {/* <script
        async
        src=""
        defer
      /> */}
      {/* <script
        src="../../../utils/google"
        defer
      /> */}
      {/* <script>
        setUpAutocomplete(inputId);
      </script> */}
    </>
  );
}
AutoCompleteAddressInputWeb.defaultProps = {
  centerPointObj: null,
  value: '',
  onChange: () => {},
  disabled: false,
};

AutoCompleteAddressInputWeb.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onPlaceChanged: PropTypes.func.isRequired,
  centerPointObj: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  disabled: PropTypes.bool,
};

export default AutoCompleteAddressInputWeb;
