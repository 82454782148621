import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ingredientMould } from '../../../../../common/utils/objectsPropsMould';

export default function IngredientRow({ ingredient }) {
  return (
    <tr>
      <td>{ ingredient.name }</td>
      <td>{ ingredient.measureUnit }</td>
      <td>{ ingredient.purchasePrice }</td>
      <td>{ ingredient.salePrice }</td>
      <td className="is-flex is-justify-content-center">
        <div className="is-inline-block mr-2 is-clickable">
          <FontAwesomeIcon icon={faEdit} className="has-text-warning" />
        </div>
        <div className="is-inline-block is-clickable">
          <FontAwesomeIcon icon={faTrash} className="has-text-danger" />
        </div>
      </td>
    </tr>
  );
}

IngredientRow.propTypes = {
  ingredient: PropTypes.shape(ingredientMould).isRequired,
};
