import { toast } from 'react-toastify';

const notifySuccess = (text) => toast.success(text, {
  position: 'top-center',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
});

const notify = ({
  text, type, autoClose = 1000, theme = 'light',
}) => toast[type](text, {
  position: 'top-center',
  autoClose,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme,
});

export { notifySuccess, notify };
