import { useState } from 'react';
import { getDeeperCopyFromObjectWithObjectProps, ValidationMould } from '../utils/utils';

export default function useForm(dataModel, validationsModel) {
  const [data, setData] = useState(dataModel);
  const [validations, setValidations] = useState(validationsModel);

  const checkValidationByField = (name, value, ...validationArguments) => {
    let fieldIsValid = true;
    console.log('entra a validar', name, value, validationArguments);
    if (validations[name]) {
      if (validationArguments && validationArguments.length > 0) {
        console.log('VALIDATION ARGUMENTS', validationArguments, ...validationArguments);
        fieldIsValid = validations[name].checkIfIsValid(
          value,
          ...validationArguments,
        );
      } else {
        fieldIsValid = validations[name].checkIfIsValid(value);
      }
      console.log('field is valid', fieldIsValid);
    }
    return fieldIsValid;
  };

  const updatingValidation = (newValidationObject) => {
    const validationsCopy = getDeeperCopyFromObjectWithObjectProps(validations);
    // { ...clientDataValidations };
    Object.keys(newValidationObject).forEach((prop) => {
      if (validations[prop]) {
        validationsCopy[prop].isValid = newValidationObject[prop].isValid;
      }
    });
    setValidations({ ...validationsCopy });
  };

  const changeDataProperty = (name, value) => {
    const isValidProp = Object.keys(data).indexOf(name) > -1;
    if (isValidProp) {
      setData({ ...data, [name]: value });
    }
    return isValidProp;
  };

  const setManyDataProperties = (keyValueObj) => {
    const verifiedKeysObj = {};
    Object.keys(keyValueObj).forEach((key) => {
      const isValidProp = Object.keys(data).indexOf(key) > -1;
      if (isValidProp) {
        verifiedKeysObj[key] = keyValueObj[key];
      }
    });
    console.log('cambiando', verifiedKeysObj, Object.keys(verifiedKeysObj).length);
    if (Object.keys(verifiedKeysObj).length > 0) {
      setData({ ...data, ...verifiedKeysObj });
      console.log('Cambiando varias propiedades', { ...data, ...verifiedKeysObj });
    }
  };

  const checkIsFormValid = (extraValidationProps = null) => {
    const isFormValid = { value: true };
    const newValidationObject = {};
    Object.keys(validations).forEach((prop) => {
      let fieldIsValid = true;
      if (extraValidationProps && extraValidationProps[prop]) {
        fieldIsValid = checkValidationByField(prop, data[prop], ...extraValidationProps[prop]);
      } else {
        fieldIsValid = checkValidationByField(prop, data[prop]);
      }
      newValidationObject[prop] = new ValidationMould({ isValid: fieldIsValid });
      if (!fieldIsValid) {
        isFormValid.value = fieldIsValid;
      }
      updatingValidation(newValidationObject);
      // console.log('revisando', prop, validations[prop], fieldIsValid);
    });
    console.log('validando form', validations);
    return isFormValid.value;
  };

  const resetForm = () => {
    setData(dataModel);
    setValidations(validationsModel);
  };

  return {
    data,
    validations,
    checkValidationByField,
    updatingValidation,
    changeDataProperty,
    setManyDataProperties,
    checkIsFormValid,
    resetForm,
  };
}
