import React from 'react';
import PropTypes from 'prop-types';
import './global-spinner.css';
import { Spin } from 'antd';

function GlobalSpinner({ isLoading, children }) {
  return (
    <React.StrictMode>
      {
        isLoading
        && (
          <div className="global-spinner__container">
            <Spin size="large" />
            {(isLoading && children)
              && (
                <div className="has-text-centered spinner__inner-text">
                  {children}
                </div>

              )}
          </div>
        )
      }
    </React.StrictMode>
  );
}

GlobalSpinner.defaultProps = {
  isLoading: false,
  children: undefined,
};

GlobalSpinner.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.element,
};

export default GlobalSpinner;
