import {
  Button,
  Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space, Spin, Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { LoadingOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { claimModel, claimTypesOpt, wellHiredTypeOpts } from './claimModel';
import { documentsTypesCollection } from '../../../common/utils/constants';
// import { validateDocumentNumberByType } from '../../../common/utils/documents';
import { regexValidations } from '../../../common/utils/utils';
import { fetchCustomerSunatApi } from '../../../api/customer';
import { fetchAll } from '../../../api/locationOnRestaurant';
import { notify } from '../../../common/utils/notifications';
import { save } from '../../../api/claim';
import { selectWebLoading, setWebLoading } from '../../../app/Layouts/WebLayout/webSlice';
// import { ToastContainer } from 'react-toastify';

function ClaimSpinner({ isLoading }) {
  return (
    isLoading
    && (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0,0,0,0.15)',
          zIndex: 9999,
          width: '100%',
          height: '100%',
          overflow: 'visible',
          top: 0,
          left: 0,
          borderRadius: 10,
        }}
      >
        <Spin indicator={(
          <LoadingOutlined
            style={{
              fontSize: 54,
            }}
          />
        )}
        />
      </div>
    )
  );
}
ClaimSpinner.defaultProps = {
  isLoading: false,
};
ClaimSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

export default function ClaimsPage() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  // const [isLoading, setLoading] = useState(false);
  const isLoading = useSelector(selectWebLoading);
  const [isLoadingRestaurants, setRestaurantsLoading] = useState(false);
  const [locationsOnRestaurants, setLocationsOnRestaurants] = useState([]);
  const [LROpt, setLROpt] = useState([]);
  const [selectedRestaurant, setCurrentRestaurant] = useState(null);
  const styleFWAC = {
    width: '100%', textAlign: 'center',
  };
  const styleRowContainer = {
    padding: '10px 25px',
    borderRadius: '15px',
    backgroundColor: '#F9F9F9',
    margin: '20px 0',
    height: '100%',
  };

  const currentSelectedLRId = Form.useWatch('locationOnRestaurantId', form);

  useEffect(() => {
    const currentSelectedLR = locationsOnRestaurants.find((lr) => lr.id === currentSelectedLRId);
    setCurrentRestaurant(currentSelectedLR);
  }, [currentSelectedLRId]);

  useEffect(() => {
    setRestaurantsLoading(true);
    fetchAll()
      .then(({ data, success }) => {
        if (success) {
          const formattedData = data.map((lr) => ({ key: lr.id, value: lr.id, label: lr.name }));
          setLocationsOnRestaurants(data);
          setLROpt(formattedData);
        }
      })
      .finally(() => {
        setRestaurantsLoading(false);
      });
  }, []);

  const handleFormSubmit = () => {
    form.validateFields()
      .then((values) => {
        dispatch(setWebLoading(true));
        save(values)
          .then(({ success, message }) => {
            notify({
              text: message,
              type: (success ? 'success' : 'error'),
            });
          }).finally(() => {
            dispatch(setWebLoading(false));
          });
      })
      .catch(() => {
        notify({
          text: 'Algunos campos son incorrectos',
          type: 'error',
        });
      });
  };

  return (
    <div style={{ postion: 'relative' }}>
      <h1 style={styleFWAC}>libro de reclamaciones</h1>
      <p />
      <h2 style={styleFWAC}>Hoja de reclamación</h2>
      <p>
        <b>Fecha de registro:</b>
        {' '}
        {dayjs().format('DD-MM-YYYY')}
      </p>
      <Form
        form={form}
        initialValues={claimModel}
        layout="vertical"
        onFinish={handleFormSubmit}
        scrollToFirstError
      >
        <Row gutter={[12, 8]} style={{ padding: '0 0 10px' }}>
          <Col sm={24} md={12}>
            <Row gutter={[8]} style={styleRowContainer}>
              <Typography.Title level={3}>
                Información del Consumidor Reclamante
              </Typography.Title>
              <Col span={12}>
                <Form.Item
                  name="customerFullName"
                  label="Ingrese su nombre completo"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Documento"
                  name="customerDocNumber"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <Space.Compact direction="horizontal" block>
                    <InputNumber
                      style={{ width: '100%' }}
                      type="number"
                      addonBefore={(
                        <Form.Item
                          name="customerDocType"
                          dependencies={['customerDocNumber']}
                          style={{ minWidth: '30px' }}
                          noStyle
                        >
                          <Select
                            placeholder="Documento"
                          >
                            {documentsTypesCollection.map((document) => (
                              <Select.Option key={document.id} value={document.id}>
                                {document.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    />
                    <Button icon={<UserSwitchOutlined />} onClick={fetchCustomerSunatApi} />
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="customerAddress"
                  label="Dirección"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="customerEmail"
                  label="Correo electrónico"
                  rules={[
                    {
                      required: true,
                      message: 'Debe colocar un correo electrónico válido',
                      pattern: regexValidations.email,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="customerPhoneNumber"
                  label="Número de teléfono"
                  rules={[
                    {
                      required: true,
                      message: 'Debe colocar un número de teléfono válido',
                      pattern: regexValidations.onlyNumbers,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="customerIsYounger"
                  label="¿Usted es menor de edad?"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={24} md={12}>
            <div style={styleRowContainer}>
              <Typography.Title level={3} style={{ height: 'fit-content' }}>
                Identificación del local
              </Typography.Title>
              <Form.Item
                name="locationOnRestaurantId"
                label="Seleccione la sede del restaurante"
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio',
                }]}
              >
                <Select options={LROpt} loading={isLoadingRestaurants} />
              </Form.Item>
              {
                selectedRestaurant
                && (
                  <>
                    <b>Razón social: </b>
                    {' '}
                    {selectedRestaurant.businessName}
                    <br />
                    <b>RUC: </b>
                    {' '}
                    {selectedRestaurant.ruc}
                    <br />
                    <b>Dirección: </b>
                    {' '}
                    {selectedRestaurant.address}
                  </>

                )
              }
            </div>

          </Col>
        </Row>
        <Row gutter={[12, 8]}>
          <Col sm={24} md={12}>
            <Row gutter={[8]} justify="start" style={styleRowContainer}>
              <Typography.Title level={3}>
                Identificación del bien contratado
              </Typography.Title>
              <Col span={12}>
                <Form.Item
                  name="wellHiredType"
                  label="Tipo de bien contratado"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <Select
                    options={wellHiredTypeOpts}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="amountSpent"
                  label="Monto gastado"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <Input
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="orderDescription"
                  label="Descripción de la orden"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={24} md={12}>
            <Row gutter={[8]} justify="start" style={styleRowContainer}>
              <Typography.Title level={3}>
                Detalle de Reclamación y Pedido del Consumidor
              </Typography.Title>

              <Col span={24}>
                <Form.Item
                  name="orderDate"
                  label="Fecha del pedido"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="claimType"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <Radio.Group options={claimTypesOpt} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="claimDescription"
                  label="Detalle"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <TextArea />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="orderClaimDescription"
                  label="Información del Pedido"
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio',
                  }]}
                >
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Enviar reclamo
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
