import { getOptChain } from '../common/utils/utils';
import { api } from './config/axios';

export const listAdm = async (optObj) => {
  const optStringChain = `?${getOptChain(optObj)}`;

  return api.get(`/menu/list-adm/${optStringChain}`)
    .then(({ data }) => data)
    .catch(() => ({
      success: false,
      data: null,
      message: 'Ha habido un error, por favor contactar al administrador',
    }));
};

export const save = async (jsonData, menuId = null) => {
  const apiEndpoint = !menuId ? 'save' : `edit/${menuId}`;

  return api.post(`/menu/${apiEndpoint}`, jsonData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(({ data }) => ({
      success: data.success,
      data: data.data,
      message: data.message,
    }))
    .catch((error) => (
      {
        success: false,
        data: error,
        message: 'Ha Habido un error',
      }
    ));
};

export const getFormParams = async () => api.get('/menu/g-fp')
  .then(({ data }) => data)
  .catch(() => (
    {
      success: false,
      data: null,
      message: 'Ha habido un error, por favor contactar al administrador',
    }
  ));
