import PropTypes from 'prop-types';

const documentsTypesCollection = [
  { id: 1, name: 'DNI' },
  { id: 4, name: 'CE' },
  { id: 6, name: 'RUC' },
];

const documentTypes = (() => {
  const keyValueObj = {};
  documentsTypesCollection.forEach((doc) => {
    keyValueObj[doc.name] = doc.id;
  });
  return keyValueObj;
})();

const paymentMethods = [
  { id: 1, name: 'YAPE' },
  { id: 2, name: 'PLIN' },
  { id: 3, name: 'TARJETA' },
  { id: 4, name: 'TRANSFERENCIA' },
  { id: 5, name: 'EFECTIVO' },
];

const channelTypes = {
  REGULAR: 'REGULAR',
  DELIVERY_SERVICE: 'DELIVERY_SERVICE',
};

const channels = [
  {
    id: 1, name: 'CAJA', text: 'Caja', type: channelTypes.REGULAR,
  },
  {
    id: 2, name: 'RAPPI', text: 'Rappi', type: channelTypes.DELIVERY_SERVICE, borderColor: '#95D5EE', textColor: '#1595C7', backgroundColor: '#F7FCFE',
  },
  {
    id: 3, name: 'PEDIDOSYA', text: 'PedidosYa', type: channelTypes.DELIVERY_SERVICE, borderColor: '#FB6C98', textColor: '#FB6C98', backgroundColor: '#FFF2F6',
  },
  {
    id: 4, name: 'WHATSAPP', text: 'Whatsapp', type: channelTypes.REGULAR,
  },
  {
    id: 5, name: 'WEB', text: 'Página web', type: channelTypes.REGULAR,
  },
];

const channelsWithDeliveryService = channels.filter(
  (c) => c.type === channelTypes.DELIVERY_SERVICE,
);

const channelsObj = (() => {
  const newChannelsObj = {};
  channels.forEach((channel) => {
    newChannelsObj[channel.name] = channel.name;
  });
  return newChannelsObj;
})();

const orderStateAreas = {
  KITCHEN: 'kitchen',
  CHECKOUT: 'checkout',
};

const orderStatesCollection = [
  {
    id: 1,
    text: 'PENDIENTE DE PAGO',
    name: 'PPAGO',
    sequence: 1,
    areas: [orderStateAreas.CHECKOUT],
  },
  {
    id: 2,
    text: 'RECIÉN INGRESADO',
    name: 'INGRESADO',
    sequence: 2,
    areas: [orderStateAreas.KITCHEN, orderStateAreas.CHECKOUT],
  },
  {
    id: 3,
    text: 'EN PREPARACIÓN',
    name: 'PREPARANDO',
    sequence: 3,
    areas: [orderStateAreas.KITCHEN, orderStateAreas.CHECKOUT],
  },
  {
    id: 4,
    text: 'LISTO P/ENVÍO',
    name: 'LISTOPENVIO',
    sequence: 4,
    areas: [orderStateAreas.CHECKOUT],
  },
  {
    id: 5,
    text: 'ENVIADO',
    name: 'ENVIADO',
    sequence: 5,
    areas: [],
  },
  {
    id: 6,
    text: 'ELIMINAR',
    name: 'ELIMINADO',
    sequence: 6,
    areas: [],
  },
  { /** * REVISAR */
    id: 7,
    text: 'CANCELADO',
    name: 'CANCELADO',
    sequence: 7,
    areas: [],
  },
];

const areaStates = [
  {
    id: 100,
    name: 'COCINA',
    text: 'COCINA',
    areas: [orderStateAreas.KITCHEN],
  },
  {
    id: 101,
    text: 'Caja',
    areas: [orderStateAreas.CHECKOUT],
  },
];

const orderStates = ((area = '') => {
  const keyValue = {};
  orderStatesCollection.forEach((state, index) => {
    if (area !== '' && state.areas.indexOf(area) > -1) {
      keyValue[state.name] = orderStatesCollection[index].id;
    } else if (area === '') {
      keyValue[state.name] = orderStatesCollection[index].id;
    }
  });
  return keyValue;
})();

/** CONSTANTS FOR GOOGLE APIS  */
const countriesRestrictionsForGoogleAPI = ['PE'];
const locationTypesForGoogleAPI = ['address'];
/** ********* */
const deliveryBasePrice = 5;
const deliveryPriceByKm = 1;

const colsOrder = {
  DESC: 1,
  ASC: 2,
};

const colsOrderPropType = PropTypes.number;

/** * TIME */
const MS_PER_MINUTE = 60000;
const M_PER_WEEK = 60 * 24 * 7;
/** ** */

/** MATH */
const mathBasicOperations = {
  SUM: 'SUM',
  REST: 'REST',
};

/** */

const paginatorDots = '&hellip;';

export {
  orderStatesCollection,
  orderStates,
  orderStateAreas,
  documentsTypesCollection,
  documentTypes,
  paymentMethods,
  countriesRestrictionsForGoogleAPI,
  locationTypesForGoogleAPI,
  deliveryBasePrice,
  deliveryPriceByKm,
  channels,
  channelsObj,
  channelsWithDeliveryService,
  colsOrder,
  colsOrderPropType,
  paginatorDots,
  areaStates,
  /** TIME */
  MS_PER_MINUTE,
  M_PER_WEEK,
  /** MATH */
  mathBasicOperations,
};
