import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAdd, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Button, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

function Quantifier(
  {
    value,
    onQuantityChange,
    isSmall,
    maxQuantity,
    minQuantity,
    step,
    maxWidth,
  },
) {
  const [currentQuantity, setQuantity] = useState(1);

  const [isSmallValue, setIsSmallValue] = useState(false);

  useEffect(() => {
    setIsSmallValue(isSmall);
  }, [isSmall]);

  useEffect(() => {
    setQuantity(value);
  }, []);

  useEffect(() => {
    setQuantity(value);
  }, [value]);

  const onClickHandler = (val) => {
    let newVal = currentQuantity + val;
    newVal = (newVal < minQuantity) ? minQuantity : newVal;
    newVal = (newVal > maxQuantity) ? maxQuantity : newVal;
    onQuantityChange(newVal);
    // setQuantity(newVal);
  };

  return (
    <InputNumber
      size={isSmallValue ? 'small' : 'default'}
      value={currentQuantity}
      readOnly
      style={{ maxWidth }}
      addonBefore={
        <Button size={isSmallValue ? 'small' : 'default'} icon={<MinusCircleOutlined />} onClick={() => { onClickHandler(-step); }} type="text" />
      }
      addonAfter={
        <Button size={isSmallValue ? 'small' : 'default'} icon={<PlusCircleOutlined />} onClick={() => { onClickHandler(step); }} type="text" />
      }
    />
  );
}
Quantifier.defaultProps = {
  isSmall: false,
  maxQuantity: 10,
  minQuantity: 1,
  step: 1,
  value: 1,
  maxWidth: '170px',
  onQuantityChange: () => {},
};

Quantifier.propTypes = {
  value: PropTypes.number,
  onQuantityChange: PropTypes.func,
  isSmall: PropTypes.bool,
  maxQuantity: PropTypes.number,
  minQuantity: PropTypes.number,
  step: PropTypes.number,
  maxWidth: PropTypes.string,
};

export default Quantifier;
