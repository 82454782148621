import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/** SOCKETS */
import { io } from 'socket.io-client';
import { socketServerURL } from '../../api/config/axios';
/** SOCKETS */
import { channelsWithDeliveryService, orderStatesCollection } from '../../common/utils/constants';
import { selectUser } from '../login/user';
import { fetchOrders, selectCurrentSelectedLocationId, selectOrders } from './ordersSlice';

export default function useKitchenOrders() {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);
  const currentUser = useSelector(selectUser);
  const currentSelectedLocationId = useSelector(selectCurrentSelectedLocationId);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchOrders(currentUser))
      .finally(() => { setLoading(false); });

    const socket = io(socketServerURL);
    socket.once('orders-dispatched', async () => {
      setLoading(true);
      dispatch(fetchOrders(currentUser))
        .finally(() => { setLoading(false); });
    });
    socket.once('orders-dispatched-kitchen', async () => {
      setLoading(true);
      dispatch(fetchOrders(currentUser))
        .finally(() => { setLoading(false); });
    });
  }, []);

  const filteredOrders = useMemo(() => {
    const newFilteredOrders = [];

    orderStatesCollection.filter((state) => state.areas.indexOf('kitchen') > -1)
      .sort((a, b) => a.sequence > b.sequence).forEach((state) => {
        /** ******* LOCATION AND TIME FILTER  ******** */
        let ordersCollection = orders
          .filter((order) => order.state === state.id
          && order.locationId === currentSelectedLocationId)
          .sort((a, b) => (
            (new Date(a.startDateTime)).getTime()
              - (new Date(b.startDateTime)).getTime()));

        /** * DELIVERY FILTER */
        /** * NOTA: DS significa DELIVERY SERVICE */
        const ordersCollectionWithDS = ordersCollection.filter(
          (o) => channelsWithDeliveryService.map((c) => c.name).indexOf(o.channel) > -1,
        );
        const ordersCollectionWithoutDS = ordersCollection.filter(
          (o) => channelsWithDeliveryService.map((c) => c.name).indexOf(o.channel) < 0,
        );
        ordersCollection = [...ordersCollectionWithDS, ...ordersCollectionWithoutDS];
        /** ***************** */

        newFilteredOrders.push({
          state: { ...state },
          orders: ordersCollection,
        });
      });

    return newFilteredOrders;
  }, [orders, currentSelectedLocationId]);

  return [filteredOrders, isLoading];
}
