import axios from 'axios';

const getAll = () => axios.get('https://www.thecocktaildb.com/api/json/v1/1/filter.php?i=Vodka')
  .then(({ data }) => {
    console.log(data);
    return {
      success: true,
      data: [
        {
          id: 1,
          name: 'Empresa',
          address: 'direccion',
          contact: 'contacto',
          phone: '22222222',
          email: 'ali@prueba.com',
        },
        {
          id: 2,
          name: 'Empresa',
          address: 'direccion',
          contact: 'contacto',
          phone: '22222222',
          email: 'ali@prueba.com',
        },
        {
          id: 3,
          name: 'Empresa',
          address: 'direccion',
          contact: 'contacto',
          phone: '22222222',
          email: 'ali@prueba.com',
        },
        {
          id: 4,
          name: 'Empresa',
          address: 'direccion',
          contact: 'contacto',
          phone: '22222222',
          email: 'ali@prueba.com',
        },

      ],
      message: 'Consulta exitosa',
    };
  })
  .catch((error) => ({
    success: false,
    data: '',
    message: error,
  }));

// eslint-disable-next-line import/prefer-default-export
export { getAll };
