import { getOptChain } from '../common/utils/utils';
import { api } from './config/axios';

const baseEndpoint = '/coupon';

export const fetchCoupons = async (optObj = {}) => {
  const optChain = `/?${getOptChain(optObj)}`;
  return api.get(`${baseEndpoint}${optChain}`)
    .then(({ data }) => data)
    .catch((error) => ({
      success: false,
      data: error,
      message: 'Ha habido un error',
    }));
};

export const saveCoupon = async (form) => api.post(`${baseEndpoint}`, form)
  .then(({ data }) => data)
  .catch((error) => (
    {
      success: false,
      data: error,
      message: 'Ha Habido un error',
    }
  ));

export const getFormParams = async () => api.get(`${baseEndpoint}/g-fp`)
  .then(({ data }) => data)
  .catch(() => (
    {
      success: false,
      data: null,
      message: 'Ha habido un error, por favor contactar al administrador',
    }
  ));

export const checkCoupon = async (dishes, code) => api.post(`${baseEndpoint}/check/${code}`, {
  dishes,
})
  .then(({ data }) => data)
  .catch(() => ({
    succes: false,
    data: null,
    message: 'Ha habido un error para consulta el cupón',
  }));
