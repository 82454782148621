import React, { useEffect } from 'react';
import {
  Col,
  Row,
} from 'antd';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import OrderStateContainer from './orderStateContainer/OrderStateContainer';
import useCashRegisterOrders from './useCashRegisterOrders';
import './orders-page.css';
import { setCurrentPageTitle, setExtrasNavbarItems } from '../../app/Layouts/appLayoutSlice';
import { setCurrentLocationId } from './ordersSlice';
// import { setLoading } from '../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';

export default function CashRegisterOrdersPage() {
  const [filteredOrders, isLoading] = useCashRegisterOrders();
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    dispatch(setCurrentPageTitle('Caja'));
    if (state?.currentCartLocationId && state.currentCartLocationId !== 0) {
      dispatch(setCurrentLocationId(state.currentCartLocationId));
    }
    // dispatch(setExtrasNavbarItems(['LocationsSelect']));
  }, []);

  useEffect(() => {
    dispatch(setExtrasNavbarItems(['LocationsSelect']));
  }, [filteredOrders]);

  return (
    <Row span={24} gutter={12}>

      {
        filteredOrders.map((obj) => (

          <Col xs={24} sm={12} md={8}>
            <OrderStateContainer
              state={obj.state}
              orders={obj.orders}
              areaToExclude="kitchen"
              isLoading={isLoading}
            />
          </Col>

        ))
      }
    </Row>
  );
}
