import { webApi } from './config/axios';

export const getMenuWeb = (restaurantSlug, locationSlug) => webApi.get(`/restaurant-location/get-menu-web/${restaurantSlug}/${locationSlug}`)
  .then(({ data }) => data)
  .catch(() => ({
    succes: false,
    data: [],
    message: 'Ha habido un error al consultar los datos',
  }));
export const getWebData = (locationCitySlug = '') => webApi.get(`/restaurant-location/get-web-data/${locationCitySlug}`)
  .then(({ data }) => data)
  .catch(() => ({
    succes: false,
    data: [],
    message: 'Ha habido un error al consultar los datos',
  }));
export const saveWebOrder = (jsonData) => webApi.post('/order/save-web-order', jsonData)
  .then(({ data }) => data)
  .catch(() => ({
    succes: false,
    data: [],
    message: 'Ha habido un error al consultar los datos',
  }));
