import {
  Card, Carousel, Col, Row, Typography,
} from 'antd';
import React, {
  useEffect,
  // useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchWebRestaurantsLocations, selectWebRestaurantLocationsCities } from '../../../app/Layouts/WebLayout/webSlice';
import { getUrlByFileRoot } from '../../../common/utils/utils';
/** * BANNERS */
import bannerPromoAfan from '../../../assets/img/slider_web_home/banner-promo-afan.jpg';
import bannerPromoFresco from '../../../assets/img/slider_web_home/banner-promo-fresco.jpg';
import bannerPromoLaPipzza from '../../../assets/img/slider_web_home/banner-promo-la-pipzza.jpg';
import martinaLogo from '../../../assets/img/martina.jpg';
import './home-page.css';
// import LocationModal from './LocationModal';
import CitySelect from './CitySelect';
import { notify } from '../../../common/utils/notifications';
/** * */
const { Title } = Typography;

export default function HomePage() {
  const dispatch = useDispatch();
  const restaurantLocationsCities = useSelector(selectWebRestaurantLocationsCities);

  // eslint-disable-next-line prefer-const
  let { citySlug } = useParams();
  const { innerHeight } = window;
  const slideStyle = {
    height: (innerHeight / 100) * 50,
    width: '100%',
    color: '#fff',
    textAlign: 'center',
    backgroundColor: '#364d79',
    borderRadius: '10px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: '1em 0',
  };

  const slidersBackgrounds = [
    { src: bannerPromoAfan, alt: 'Promo Afan' },
    { src: bannerPromoFresco, alt: 'Promo fresco' },
    { src: bannerPromoLaPipzza, alt: 'Promo la Pipzza' },
  ];

  useEffect(() => {
    citySlug = citySlug || '';
    dispatch(fetchWebRestaurantsLocations(citySlug));
  }, []);

  const navigate = useNavigate();

  const handleLocationChange = async (selectedCity) => {
    if (citySlug !== selectedCity) {
      navigate(`/${selectedCity}`);
      window.location.reload();
    } else {
      notify({
        text: 'Ya haz escogido esta ciudad',
        type: 'warning',
      });
    }
  };

  return (
    < >
      <section className="home-page-section">
        <Carousel autoplay effect="fade">
          {
            slidersBackgrounds.map((banner) => (
              <div>
                <div className="bg-banner" style={{ ...slideStyle, backgroundImage: `url('${banner.src}')` }} />
              </div>
            ))
          }
        </Carousel>
      </section>
      <section
        className="home-page-section section-select"
      >
        <CitySelect
          value={citySlug}
          onLocationChange={handleLocationChange}
        />
      </section>
      {
        restaurantLocationsCities.map((c) => (
          <section className="home-page-section">
            <Title className="title" level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
              Cónoce nuestras sedes en
              {' '}
              {c.name}
            </Title>
            <Row
              gutter={[24, 32]}
              justify="center"
            >
              {c.locationsOnRestaurants.map((r) => (
                <Col xs={24} md={12} lg={8}>

                  <Card
                    hoverable
                    cover={
                      (
                        <Link to={`/stores/restaurant/${r.restaurant.slug}/${r.location.slug}`} style={{ height: 'inherit' }}>
                          <img
                            alt={r.restaurant.name}
                            width="100%"
                            height="auto"
                            src={`${getUrlByFileRoot(r.restaurant.logo)}`}
                          />
                        </Link>
                      )
                    }
                  >
                    <Title level={4} style={{ textAlign: 'center', margin: 0 }}>
                      {`${r.restaurant.name} - ${r.location.name}`}
                    </Title>
                  </Card>
                </Col>
              ))}
              {
                c.name?.toLowerCase() === ' arequipa'
                && (
                  <Col xs={24} md={12} lg={8}>

                    <Card
                      hoverable
                      cover={
                        (
                          <Link to="https://martina.rest/" style={{ height: 'inherit' }}>
                            <img
                              alt="Martina"
                              width="100%"
                              height="auto"
                              src={`${martinaLogo}`}
                            />
                          </Link>
                        )
                      }
                    >
                      <Title level={4} style={{ textAlign: 'center', margin: 0 }}>
                        Martina
                      </Title>
                    </Card>
                  </Col>
                )
              }
            </Row>
          </section>
        ))
      }
    </>
  );
}
