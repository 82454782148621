import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Column from 'antd/es/table/Column';
import { Button, Space, Table } from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import { orderMould } from '../../../../../common/utils/objectsPropsMould';
// import AdmOrderRow from './AdmOrderRow';
// eslint-disable-next-line import/named, no-unused-vars
import { getOptChainByColumns } from '../../../../../common/utils/utils';
// import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
// import { colsOrder } from '../../../../../common/utils/constants';
// import CustomHeaderRow from '../../.
// ./../../common/components/Extras/CustomHeaderRow/CustomHeaderRow';

export default function AdmOrdersList({
  orders,
  handleOptChange,
}) {
  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState([
    {
      title: 'Fecha',
      dataIndex: 'startDateTime',
    },
    {
      title: 'Rest',
      dataIndex: 'restaurantName',
    },
    {
      title: 'Monto',
      dataIndex: 'total',
    },
    {
      title: 'Canal',
      dataIndex: 'channel',
    },
    {
      title: 'Metodo de Pago',
      dataIndex: 'paymentMethod',
    },
  ]);

  // const [currentPage, setCurrentPage] = useState(1);

  const onOptChange = () => {
    if (handleOptChange) {
      handleOptChange(getOptChainByColumns(columns));
    }
  };

  useEffect(() => {
    onOptChange();
  }, [columns]);

  return (
    <React.StrictMode>
      <Table
        pagination={false}
        dataSource={orders}
      >
        {
          columns.map((c) => (
            <Column
              dataIndex={c.dataIndex}
              key={c.dataIndex}
              title={c.title}
            />
          ))
        }
        <Column
          title="Acciones"
          key="actions"
          // eslint-disable-next-line no-unused-vars
          render={(_, record) => (
            <Space>
              <Button icon={<DeleteOutlined />} type="text" danger />
            </Space>
          )}
        />
      </Table>
    </React.StrictMode>
  );
}

AdmOrdersList.defaultProps = {
  orders: [],
  handleOptChange: null,
};

AdmOrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape(orderMould)),
  handleOptChange: PropTypes.func,
};
