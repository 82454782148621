/* eslint-disable import/prefer-default-export */
import { api } from './config/axios';

export const getAll = () => api.get('/category/list')
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: [],
    message: 'Ha habido un error al consultar los datos',
  }));
