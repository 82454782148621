/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

const globalSpinnerSlice = createSlice({
  name: 'globalSpinner',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = globalSpinnerSlice.actions;

export const selectGlobalSpinnerState = (state) => state.globalSpinner.isLoading;

export default globalSpinnerSlice.reducer;
