import React, { useEffect, useState } from 'react';
import { Button, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AdmMenuList from '../AdmMenuList/AdmMenuList';
import Paginator from '../../../../common/components/Extras/Paginator/Paginator';
import { listAdm } from '../../../../api/menu';
import MenuFormModal from '../MenuFormModal/MenuFormModal';

export default function AdmMenuPage() {
  const [isDataLoading, setDataLoading] = useState(false);
  /** **** PAG */
  const [page, setCurrentPage] = useState(1);
  const [paginationProps, setPaginatioProps] = useState({
    rowCount: 0,
    rowsPerPage: 10,
  });
  const [menus, setMenus] = useState([]);
  /** FORM DATA */
  const [isFormOpen, setFormState] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  /** ********* */
  const getMenus = (optObj = {}) => {
    setDataLoading(true);
    listAdm(optObj)
      .then(({ success, data: { menus: menusData, extras } }) => {
        const { rowCount, currentPage, rowsPerPage } = extras;
        console.log(menusData, 'estos son los menus');
        setCurrentPage(currentPage);
        if (success) {
          setMenus(menusData);
        }
        setPaginatioProps({
          ...paginationProps,
          rowCount: rowCount || 0,
          rowsPerPage,
        });
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getMenus();
  }, []);

  const handleFormCancel = () => {
    setSelectedMenu(null);
    setFormState(false);
  };

  const handleFormSubmit = () => {
    getMenus();
    setFormState(false);
  };

  const openFormModal = (data = undefined) => {
    setSelectedMenu(data);
    setFormState(true);
  };

  return (
    <>
      <MenuFormModal
        isOpen={isFormOpen}
        menuData={selectedMenu}
        onCancel={handleFormCancel}
        onSubmit={handleFormSubmit}
      />
      <Row justify="space-between">
        <Space />
        <Space>
          <Button
            onClick={() => openFormModal()}
            icon={<PlusOutlined />}
          />
        </Space>
      </Row>
      <AdmMenuList
        menus={menus}
        isLoading={isDataLoading}
        onSelectedMenu={openFormModal}
      />
      <Paginator
        rowCount={paginationProps.rowCount}
        rowsPerPage={paginationProps.rowsPerPage}
        currentPage={page}
        onPageChange={(newPage, pageSize) => {
          getMenus({ page: newPage, limit: pageSize });
        }}
      />
    </>
  );
}
