import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomInput from '../../../../common/components/Extras/CustomInput/CustomInput';
import { fetchAdmSuppliers, selectAdmSuppliers } from '../admSuppliersSlice';
import AdmSuppliersList from './AdmSuppliersList/AdmSuppliersList';

export default function AdmSuppliersPage() {
  const dispatch = useDispatch();

  const suppliers = useSelector(selectAdmSuppliers);
  const [search, setSearch] = useState('');
  const handleInputSearchChange = (evt) => {
    const { value } = evt.target;
    setSearch(value);
  };

  useEffect(() => {
    dispatch(fetchAdmSuppliers());
  }, []);

  return (
    <>
      <div className="btn-item-form__container">
        <Link to="add" className="button">
          <FontAwesomeIcon icon={faAdd} />
        </Link>
      </div>
      <div className="columns">
        <div className="column is-12">
          <div className="is-size-3 has-text-centered has-text-weight-bold">
            Proveedores
          </div>
        </div>
      </div>
      <div className="is-flex is-justify-content-end py-2">
        <CustomInput
          value={search}
          inputName="search"
          inputId="search"
          placeholder="Buscar proveedores"
          isSearch
          onInputChange={handleInputSearchChange}
        />
      </div>
      <div>
        <AdmSuppliersList suppliers={suppliers} />
      </div>
    </>
  );
}
