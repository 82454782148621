/* eslint-disable import/prefer-default-export */
import { api } from './config/axios';

const loadAddOns = (form) => api.post(
  '/add-on/load-file/',
  form,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
)
  .then(({ data }) => data)
  .catch(() => ({
    success: 'false',
    data: '',
    message: 'Ha habido un error, contactar al administrador',
  }));

const getAllByLocation = (locationId) => api.get(`/add-on/list/location/${locationId}`)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, intente más tarde',
  }));

export { loadAddOns, getAllByLocation };
