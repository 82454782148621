import {
  Button,
  Col, Form, Row, Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { fetchSystemVars, saveSystemVars } from '../../../api/system';
import { notify } from '../../../common/utils/notifications';

const defaultModel = {
  CULQI_PROD_ENABLED: false,
  CULQI_DISPLAY: false,
};

export default function AdmSystemVarPage() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);

  const fetchVars = () => {
    setIsLoading(true);
    fetchSystemVars()
      .then(({ data, success }) => {
        console.log(data);
        if (success) {
          console.log('entra', data);
          form.setFieldsValue(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchVars();

    return () => {
      form.resetFields();
    };
  }, []);
  const handleForm = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);
      saveSystemVars(values)
        .then(({ success, message }) => {
          notify({
            text: message,
            type: success ? 'success' : 'error',
          });
          if (success) {
            fetchVars();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <Form
      form={form}
      initialValues={defaultModel}
      onFinish={handleForm}
    >
      <Row>
        <Col span={6}>
          <Form.Item
            name="CULQI_PROD_ENABLED"
            label="¿Habilitar CULQI en producción?"
            valuePropName="checked"
          >
            <Switch loading={isLoading} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="CULQI_DISPLAY"
            label="¿Mostrar Culqi?"
            valuePropName="checked"
          >
            <Switch loading={isLoading} />
          </Form.Item>
        </Col>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Enviar
          </Button>
        </Form.Item>

      </Row>

    </Form>
  );
}
