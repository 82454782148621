import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Space,
  Typography,
} from 'antd';
import Link from 'antd/es/typography/Link';
import {
  CarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UserSwitchOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { orderMould } from '../../../common/utils/objectsPropsMould';
import { getDifferenceTime, getNextOrderState } from '../../../common/utils/utils';
import './order-card.css';
import { updateOrderState } from '../ordersSlice';
import { channels, orderStates, orderStatesCollection } from '../../../common/utils/constants';

const { Text } = Typography;

const setScriptsTags = () => {
  const publicKey = '';
  const scriptUrlId = 'culqi-public-key';
  const scriptPublicKeyId = 'culqi-public-key';

  let scriptUrl = document.getElementById(`${scriptUrlId}`);
  let scriptPublicKey = document.getElementById(`${scriptPublicKeyId}`);

  if (!scriptUrl) {
    scriptUrl = document.createElement('script');
    scriptUrl.id = 'culqi-url';
    scriptUrl.src = 'https://checkout.culqi.com/js/v4';
    document.body.appendChild(scriptUrl);
  }
  if (!scriptPublicKey) {
    scriptPublicKey = document.createElement('script');
    scriptPublicKey.id = 'culqi-public-key';
    scriptPublicKey.innerHTML = `Culqi.publicKey = '${publicKey}'`;
    document.body.appendChild(scriptPublicKey);
  }
};
const getCulqiVars = async () => ({
  xculqirsaid: 'Inserta aquí el id de tu llave pública RSA',
  rsapublickey: 'Inserta aquí tu llave pública RSA',
});
// eslint-disable-next-line no-unused-vars
const activateCulqiCheckout = async (orderObj) => {
  const culqiVars = await getCulqiVars();
  /**
   * OrderObj = {
   * id: 3,
   * total: 100,
   * title: 3,
   * }
   */
  setScriptsTags();

  // eslint-disable-next-line no-undef
  Culqi.settings({
    title: 'Culqi Store',
    currency: 'PEN', // Este parámetro es requerido para realizar pagos yape
    amount: orderObj.total, // Este parámetro es requerido para realizar pagos yape
    order: 'ord_live_0CjjdWhFpEAZlxlz', // Este parámetro es requerido para realizar pagos con pagoEfectivo, billeteras y Cuotéalo
    xculqirsaid: culqiVars.xculqirsaid,
    rsapublickey: culqiVars.rsapublickey,
  });
  // eslint-disable-next-line no-undef
  Culqi.options({
    lang: 'auto',
    installments: false, // Habilitar o deshabilitar el campo de cuotas
    paymentMethods: {
      tarjeta: true,
      yape: true,
      bancaMovil: true,
      agente: true,
      billetera: true,
      cuotealo: true,
    },
  });
  // eslint-disable-next-line no-undef
  Culqi.open();
};

function OrderCard({ order, hasBtn, onSeeDetails }) {
  const dispatch = useDispatch();
  const [nextState, setNextState] = useState({});
  let deletedState = null;
  if (order.state === orderStates.PPAGO || order.state === orderStates.INGRESADO) {
    deletedState = orderStatesCollection.find((state) => state.id === orderStates.ELIMINADO);
  }
  const orderChannelName = channels.find((c) => c.name === order.channel)?.text;

  useEffect(() => {
    setNextState(getNextOrderState(order.state));
  }, []);
  const [
    timeLeft,
    setTimeLeft,
  ] = useState(getDifferenceTime(new Date(), new Date(order.endDateTime)));

  setInterval(() => {
    setTimeLeft(getDifferenceTime(new Date(), new Date(order.endDateTime)));
  }, 60000);

  const footerBtns = [];
  if (hasBtn) {
    const btnText = (nextState.id !== orderStates.INGRESADO)
      ? (nextState.text || '')
      : 'PAGO EXITOSO';

    footerBtns.push(
      <Button
        type="primary"
        onClick={() => {
          dispatch(updateOrderState({ orderId: order.id, nextState }));
        }}
        ghost
        block
      >
        {
          btnText
        }
      </Button>,
    );
    /* if (nextState.id === orderStates.INGRESADO) {
      footerBtns.push(
        <Button
          type="primary"
          onClick={async () => {
            await activateCulqiCheckout();
          }}
          ghost
          block
        >
          CULQI
        </Button>,
      );
    } */
  }
  const getChannelColor = (type = 'backgroundColor') => {
    const orderChannelObj = channels.find((c) => c.name === order.channel);
    let channelColor = '#f0f0f0';
    if (orderChannelObj) {
      channelColor = orderChannelObj[type];
    }

    return channelColor;
  };

  if (deletedState) {
    footerBtns.push(
      <Button
        type="primary"
        danger
        onClick={() => {
          dispatch(updateOrderState({ orderId: order.id, nextState: deletedState }));
        }}
      >
        <DeleteOutlined />
      </Button>,
    );
  }
  return (
    <Card
      title={
        (
          <>
            <Text>
              <strong>{`N° ${order.id.toString().padStart(8, 0)} - `}</strong>
            </Text>
            <Text>
              {`${order.locationOnRestaurantName}`}
            </Text>
            <br />
            <Text
              style={{ color: getChannelColor('textColor') }}
            >
              {`${orderChannelName} - ${order.customer.name}`}
            </Text>
            {
              order.customer.phone
              && (
                <>
                  <br />
                  <Text>
                    {`${order.customer.phone}`}
                  </Text>
                </>

              )
            }
            <br />
            <Text type="secondary">
              {`${order.customer.address}`}
            </Text>
            <br />
            <div className="order-box__time-left">
              <Text className="time-left">
                {' '}
                {timeLeft}
                {' '}
                min
              </Text>
            </div>
          </>
        )
      }
      extra={
        (
          <Space direction="vertical">
            {
              (order.customer.link !== '')
              && (
              <Link href={order.customer.link} target="_blank">
                <WhatsAppOutlined />
              </Link>
              )
            }
            <Text>
              {order.isForPickup
                ? <UserSwitchOutlined />
                : <CarOutlined />}

            </Text>
            <Text>
              <ExclamationCircleOutlined onClick={() => onSeeDetails(order)} />
            </Text>
          </Space>

        )
      }
      style={{
        background: `linear-gradient(90deg, ${getChannelColor()} 34%, rgba(255,255,255,1) 99%, rgba(240,240,240,1) 100%)`,
        border: `1px solid ${getChannelColor('borderColor')}`,
        boxShadow: 'rgba(0, 0, 0, 0.15) 1px 1px 9px 2px',
        margin: '13px 20px',
        paddingTop: '10px',
      }}
      actions={footerBtns}
    >
      <div className="order-box__content">
        <ul className="order-box__content-list">
          {
            order.dishes.map((dish) => (
              <li key={dish.code}>
                <Text strong>
                  {`${dish.name} (x${dish.quantity})`}
                </Text>
                <ul>
                  {
                    (dish.dishes && dish.dishes.length > 1 && dish.dishes != null)
                    && (
                      <li>
                        <Text type="secondary" strong>
                          Platos:
                          {' '}
                        </Text>
                        <Text type="secondary" className="text-lowercase">{dish.dishes?.map((ing) => ing.title).join(', ')}</Text>

                      </li>
                    )
                  }
                  <li>
                    <Text type="secondary" strong>
                      No Agregar:
                    </Text>
                    {' '}
                    {
                      (dish.ingredients && dish.ingredients.length > 0)
                        ? <Text type="secondary">{dish.ingredients.map((ing) => ing.name).join(', ')}</Text>
                        : <Text type="secondary"> Sin especificar </Text>
                    }
                  </li>
                  <li>
                    <Text type="secondary" strong>
                      Adicionales:
                    </Text>
                    {' '}
                    {
                      (dish.addOns && dish.addOns.length > 0)
                        ? <Text type="secondary">{dish.addOns.map((ing) => ing.name).join(', ')}</Text>
                        : <Text type="secondary"> Sin especificar </Text>
                    }
                  </li>
                </ul>
              </li>
            ))
          }
        </ul>
      </div>
    </Card>
  );
}

OrderCard.defaultProps = {
  hasBtn: true,
  onSeeDetails: () => {},
};

OrderCard.propTypes = {
  order: PropTypes.shape(orderMould).isRequired,
  hasBtn: PropTypes.bool,
  onSeeDetails: PropTypes.func,
};

export default OrderCard;
