import PropTypes from 'prop-types';

export const categoryMould = {
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
};

export const menuMould = {
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  categoriesIds: PropTypes.arrayOf(PropTypes.number),
};

export const dishMould = {
  id: PropTypes.number,
  name: PropTypes.string,
  desc: PropTypes.string,
  price: PropTypes.number,
  img: PropTypes.string,
};

export const ingredientMould = {
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  measureUnit: PropTypes.string,
};

export const restaurantMould = {
  id: PropTypes.number,
  name: PropTypes.string,
};

export const dishCategoryMould = {
  id: PropTypes.number,
  name: PropTypes.string,
};

export const customer = {
  name: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  DNI: PropTypes.string,
  email: PropTypes.string,
};

export const orderMould = {
  id: PropTypes.number,
  dishes: PropTypes.arrayOf(PropTypes.shape(dishMould)),
  state: PropTypes.number,
  customer: PropTypes.shape(customer),
};

export const orderStateMould = {
  id: PropTypes.number,
  name: PropTypes.string,
  sequence: PropTypes.number,
};

export const SupplierMould = {
  id: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  documentNumber: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

export const couponMould = {
  id: PropTypes.number,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  code: PropTypes.string,
  discountType: PropTypes.string,
  amount: PropTypes.number,
  timesUsed: PropTypes.number,
};
