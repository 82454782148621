/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllByUser } from '../../api/locationOnRestaurant';
import { setLoading } from '../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';
import { notify } from '../../common/utils/notifications';

const initialState = {
  collection: [],
};

const restaurantLocationSlice = createSlice({
  name: 'restaurantLocation',
  initialState,
  reducers: {
    setRestaurantsLocationsCollection: (state, action) => {
      state.collection = action.payload;
    },
  },
});

export const {
  setRestaurantsLocationsCollection,
} = restaurantLocationSlice.actions;

export default restaurantLocationSlice.reducer;

export const selectRestaurantsLocations = (state) => state.restaurantsLocations.collection;

/** * LLAMADAS ASINCRONAS */
export const fetchRestaurantsLocations = createAsyncThunk(
  'restaurantLocation/fetchRestaurantLocation',
  async (_, { dispatch, getState }) => {
    const { user: currentUser } = getState();
    dispatch(setLoading(true));
    dispatch(setRestaurantsLocationsCollection([]));

    const { data, success } = await getAllByUser(currentUser.id);

    if (success) {
      dispatch(setRestaurantsLocationsCollection(data));
    } else {
      notify({
        text: 'Ha habido un error, comuníquese con el administrador',
        type: 'error',
      });
    }
    dispatch(setLoading(false));
  },
);
