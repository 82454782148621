import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAll as getAllSuppliers } from '../../../api/supplier';
import { setLoading } from '../../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';

const initialState = {
  collection: [],
};

const admSuppliersSlice = createSlice({
  name: 'admSuppliers',
  initialState,
  reducers: {
    setSuppliers: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.collection = action.payload;
    },
  },
});

export const {
  setSuppliers,
} = admSuppliersSlice.actions;

export default admSuppliersSlice.reducer;

export const selectAdmSuppliers = (state) => state.admSuppliers.collection;
export const selectAdmSupplierById = (id) => (state) => {
  const supplierIndex = state.admSuppliers.collection.findIndex((supplier) => supplier.id === id);
  const supplier = supplierIndex > -1 ? state.admSuppliers.collection[supplierIndex] : null;

  return supplier;
};

export const fetchAdmSuppliers = createAsyncThunk(
  'admSuppliers/fetchAdmSuppliers',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    const { success, data } = await getAllSuppliers();
    if (success) {
      dispatch(setSuppliers(data));
    }
    dispatch(setLoading(false));
  },
);
