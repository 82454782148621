/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, {
  createContext, useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form, Input, InputNumber, Modal, Space, Switch, Table, Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ingredientMould } from '../../../../common/utils/objectsPropsMould';

const EditableContext = createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

const { Text } = Typography;

function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type = 'text',
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  // const form = Form.useForm();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    // eslint-disable-next-line react/destructuring-assignment
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      // eslint-disable-next-line react/destructuring-assignment
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Ha habido un error', errInfo);
    }
  };

  let childNode = children;
  const inputTypes = {
    number: <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />,
    boolean: <Switch ref={inputRef} onChange={save} onBlur={save} />,
    text: <Input ref={inputRef} onPressEnter={save} onBlur={save} />,
  };
  if (editable) {
    console.log('type selected', inputTypes[type]);
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} es obligatorio`,
          },
        ]}
      >
        {
            inputTypes[type]
        }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        <Text style={{ cursor: 'pointer' }} underline>
          {children}
        </Text>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <td {...restProps}>{childNode}</td>;
}

export function IngredientsModal({
  isOpen,
  ingredientsDataSet,
  onSelectionSubmit,
  onModalStateChange,
  newSelectedIndexArray,
}) {
  /** * FIELDS TO FILTER */
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters, close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter:
    (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    /** render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),* */
  });

  /** * COLUMNNS */
  const ingredientsDefaultColumns = [
    {
      title: 'ID',
      dataIndex: 'ingredientOnLocationId',
    },
    {
      title: 'Nombre',
      dataIndex: 'title',
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Precio',
      dataIndex: 'price',
    },
    {
      title: 'Cantidad',
      dataIndex: 'dishQuantity',
      editable: true,
      type: 'number',
    },
    {
      title: 'Unidad de medida',
      dataIndex: 'unit',
      editable: true,
    },
    {
      title: 'Es Opcional',
      dataIndex: 'isOptional',
      editable: true,
      type: 'boolean',
      render: (val) => (
        <span>{`${val ? 'si' : 'no'}`}</span>
      ),
    },
  ];

  /** ******************* */

  const [isModalOpen, setModalState] = useState(false);
  const [currentIngredientsDataSet, setCurrentIngredientsDataSet] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const onSelectedIngredient = (ingredientsSelection) => {
    setSelectedIngredients(ingredientsSelection);
  };
  useEffect(() => {
    if (newSelectedIndexArray.length > 0) {
      setSelectedIngredients(newSelectedIndexArray);
    }
  }, [newSelectedIndexArray]);

  const rowIngredientSelection = {
    selectedRowKeys: selectedIngredients,
    onChange: onSelectedIngredient,
  };

  const resetSelection = () => {
    setSelectedIngredients([]);
  };

  const closeModal = () => {
    // resetSelection();
    onModalStateChange(false);
  };

  useEffect(() => {
    setModalState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setCurrentIngredientsDataSet(
      ingredientsDataSet.map((i) => ({
        ...i,
        key: i.ingredientOnLocationId,
      })),
    );
    console.log('el ingredient dataset', ingredientsDataSet);
  }, [ingredientsDataSet]);

  const handleSave = (row) => {
    const newData = [...currentIngredientsDataSet];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setCurrentIngredientsDataSet(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const ingredientsColumns = ingredientsDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        type: col.type,
      }),
    };
  });

  const handleSubmitSelection = () => {
    onSelectionSubmit(currentIngredientsDataSet.filter(
      (i) => selectedIngredients.indexOf(i.ingredientOnLocationId) > -1,
    ));
  };

  return (
    <Modal
      open={isModalOpen}
      cancelText="Cancelar"
      cancelButtonProps={{
        type: 'dashed',
        danger: true,
      }}
      onCancel={closeModal}
      okText="Guardar"
      okButtonProps={{
        type: 'primary',
        onClick: handleSubmitSelection,
      }}
      width={900}
    >

      <Table
        components={components}
        pagination={{ hideOnSinglePage: true }}
        columns={ingredientsColumns}
        dataSource={currentIngredientsDataSet}
        rowSelection={rowIngredientSelection}
      />

    </Modal>
  );
}

IngredientsModal.defaultProps = {
  isOpen: false,
  ingredientsDataSet: [],
  onSelectionSubmit: () => { },
  onModalStateChange: () => { },
  newSelectedIndexArray: [],
};

IngredientsModal.propTypes = {
  isOpen: PropTypes.bool,
  ingredientsDataSet: PropTypes.arrayOf(PropTypes.shape(ingredientMould)),
  onSelectionSubmit: PropTypes.func,
  onModalStateChange: PropTypes.func,
  newSelectedIndexArray: PropTypes.arrayOf(PropTypes.number),
};

export function AddOnsModal({
  isOpen,
  addOnsDataSet,
  onSelectionSubmit,
  onModalStateChange,
  newSelectedIndexArray,
}) {
  /** * FIELDS TO FILTER */
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters, close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter:
    (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    /** render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),* */
  });

  const addOnsDefaultColumns = [
    {
      title: 'ID',
      dataIndex: 'addOnOnLocationId',
      key: 'addOnOnLocationId',
    },
    {
      title: 'Nombre',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Unidad',
      dataIndex: 'unit',
      key: 'unit',
      editable: true,
    },
  ];

  const [isModalOpen, setModalState] = useState(false);
  const [currentAddOnsDataSet, setCurrentAddOnsDataSet] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const onSelectedAddOn = (addOnsSelection) => {
    setSelectedAddOns(addOnsSelection);
  };
  useEffect(() => {
    if (newSelectedIndexArray.length > 0) {
      onSelectedAddOn(newSelectedIndexArray);
    }
  }, [newSelectedIndexArray]);

  const rowAddOnSelection = {
    selectedRowKeys: selectedAddOns,
    onChange: onSelectedAddOn,
  };

  const resetSelection = () => {
    setSelectedAddOns([]);
  };

  const closeModal = () => {
    // resetSelection();
    onModalStateChange(false);
  };

  useEffect(() => {
    setModalState(isOpen);
  }, [isOpen]);
  useEffect(() => {
    setCurrentAddOnsDataSet(
      addOnsDataSet.map((a) => ({
        ...a,
        key: a.addOnOnLocationId,
      })),
    );
    console.log('addonDataset', addOnsDataSet);
  }, [addOnsDataSet]);

  const handleSubmitSelection = () => {
    onSelectionSubmit(selectedAddOns);
  };

  const handleSave = (row) => {
    const newData = [...currentAddOnsDataSet];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setCurrentAddOnsDataSet(newData);
  };
  const addOnsColumns = addOnsDefaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        type: record.type,
      }),
    };
  });
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Modal
      open={isModalOpen}
      cancelText="Cancelar"
      cancelButtonProps={{
        type: 'dashed',
        danger: true,
      }}
      onCancel={closeModal}
      okText="Guardar"
      okButtonProps={{
        type: 'primary',
        onClick: handleSubmitSelection,
      }}
      width={900}
    >

      <Table
        components={components}
        pagination={{ hideOnSinglePage: true }}
        columns={addOnsColumns}
        dataSource={currentAddOnsDataSet}
        rowSelection={rowAddOnSelection}
      />

    </Modal>
  );
}

AddOnsModal.defaultProps = {
  isOpen: false,
  addOnsDataSet: [],
  onSelectionSubmit: () => { },
  onModalStateChange: () => { },
  newSelectedIndexArray: [],
};

AddOnsModal.propTypes = {
  isOpen: PropTypes.bool,
  addOnsDataSet: PropTypes.arrayOf(PropTypes.shape(ingredientMould)),
  onSelectionSubmit: PropTypes.func,
  onModalStateChange: PropTypes.func,
  newSelectedIndexArray: PropTypes.arrayOf(PropTypes.number),
};
