import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React, { useState } from 'react';
import FeIssuerFormModal from '../FormModal/FeIssuerFormModal';

export default function FeIssuerPage() {
  const [modalState, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <FeIssuerFormModal
        isOpen={modalState}
        handleCancel={closeModal}
      />
      <Space>
        <div />
        <Button
          icon={<PlusOutlined />}
          onClick={openModal}
        />
      </Space>
      <div />
    </>
  );
}
