import PropTypes from 'prop-types';
import { filesRoot } from '../../api/config/axios';
import {
  deliveryBasePrice,
  deliveryPriceByKm,
  documentsTypesCollection,
  mathBasicOperations,
  orderStatesCollection,
  paymentMethods,
} from './constants';

const getUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

function ValidationMould(
  {
    isValid = true,
    isRequired,
    regex,
    errorMessage,
  },
  callback = null,
) {
  this.isValid = isValid;
  this.isRequired = isRequired || false;
  this.regex = regex || undefined;
  this.errorMessage = errorMessage || '';
  this.checkExtraValidations = callback;
  /** ESTE METODO PUEDE RECIBIR N VALORES, pero el primer valor debe ser igual al
   * valor de la variable a evaluar
   */
  this.checkIfIsValid = (...props) => {
    console.log('todos los props pasados', props, ...props);
    if (!this.isRequired && props[0].length < 1) {
      return true;
    }
    if (this.isRequired && props[0].length < 1) {
      return false;
    }
    if (this.regex && !props[0].match(regex)) {
      return false;
    }
    if (this.checkExtraValidations != null && !this.checkExtraValidations(...props)) {
      return false;
    }
    return true;
  };
}

const validationMouldPropTypes = {
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
  regex: PropTypes.object,
  errorMessage: PropTypes.string,
  checkExtraValidations: PropTypes.func,
  checkIfIsValid: PropTypes.func,
};

const regexValidations = {
  onlyNumbers: /^[0-9]{1,}$/gm,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
};
/** TIME */
/** AMBOS PARAMETROS DE LA FUNCION DEBE SER OBJETOS DATE */
const getDifferenceTime = (startDateTime, endDateTime) => {
  let diff = (endDateTime.getTime() - startDateTime.getTime()) / 1000;
  diff /= 60;
  diff = Math.floor(diff);
  return diff;
};

const getDateAlteredByMin = (date, minutes, operation = mathBasicOperations.REST) => {
  let newDate = null;

  switch (operation) {
    case mathBasicOperations.REST:
      newDate = date.setMinutes(date.getMinutes() - minutes);
      break;
    case mathBasicOperations.SUM:
      newDate = date.setMinutes(date.getMinutes() + minutes);
      break;
    default:
      newDate = date.setMinutes(date.getMinutes() + minutes);
      break;
  }

  return new Date(newDate);
};
/** END TIME */

const getNextOrderState = (stateId) => {
  let nextState = null;
  orderStatesCollection.forEach((state) => {
    if (state.id === parseInt(stateId, 10)) {
      const statesWithMajorSequence = orderStatesCollection.filter(
        (state2) => state2.sequence > state.sequence,
      );
      if (statesWithMajorSequence.length > 0) {
        const [nextStateFound] = [...statesWithMajorSequence.sort(
          (a, b) => a.sequence > b.sequence,
        )];
        nextState = nextStateFound;
      }
    }
  });
  if (!nextState) {
    [nextState] = orderStatesCollection.sort((a, b) => a.sequence > b.sequence);
  }

  return nextState;
};

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }
  if (document.querySelector(`#${id}`)) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('src', src);
  script.setAttribute('id', id);
  position.appendChild(script);
};

/** ==== DELIVERY CALCULATIONS ==== */
const getKmFromM = (metros) => Math.round(metros / 1000);

/** Se debe mandar como parametro la distancia respectiva */
const getDeliveryPrice = (distance) => {
  const totalPrice = Math.round(deliveryBasePrice + (deliveryPriceByKm * distance));
  return totalPrice;
};
/** ============================ */

/** ==== WORKING WITH OBJECTS ==== */
const getDeeperCopyFromObjectWithObjectProps = (obj) => {
  const newCopy = {};
  Object.keys(obj).forEach((key) => {
    newCopy[key] = { ...obj[key] };
  });
  return newCopy;
};
/** =========== */
/** FORM UTILS * */

const getOptChainByColumns = (columns) => {
  const optChainArray = [];
  Object.keys(columns).forEach((col) => {
    if (col.order) {
      optChainArray.push(`${encodeURIComponent(col.name)}-order=${col.order}`);
    }
  });
  return optChainArray.join('&');
};
const getOptChain = (optObj) => {
  const optChainArray = [];
  Object.keys(optObj).forEach((prop) => {
    optChainArray.push(`${prop}=${optObj[prop]}`);
  });
  return optChainArray.join('&');
};

const getUrlByFileRoot = (path) => ((path && path !== '')
  ? `${filesRoot}/${path}`
  : 'https://bulma.io/images/placeholders/256x256.png');

const getPaymentMethodNameById = (paymentMethodId) => {
  let paymentMethodName = '';

  const paymentMethodIndex = paymentMethods.findIndex(
    (p) => p.id === parseInt(paymentMethodId, 10),
  );
  if (paymentMethodIndex > -1) {
    paymentMethodName = paymentMethods[paymentMethodIndex].name;
  }

  return paymentMethodName;
};

const getDocTypeNameById = (docTypeId) => {
  let docTypeName = '';

  const docTypeIndex = documentsTypesCollection
    .findIndex((doc) => doc.id === parseInt(docTypeId, 10));

  if (docTypeIndex > -1) {
    docTypeName = documentsTypesCollection[docTypeIndex].name;
  } else {
    docTypeName = documentsTypesCollection[0].name;
  }

  return docTypeName;
};

/** * *** */

/** WORKING WITH FILE SIZE */
const getFileSize = (file, measure = 'KB') => {
  const size = { value: 0, measure };
  if (file && file.size) {
    switch (measure) {
      case 'KB':
        size.value = file.size / 1024;
        break;
      case 'MB':
        size.value = file.size / 1048576;
        break;
      default:
        size.value = file.size / 1024;
        break;
    }
  }

  return size.value;
};

const fetchObjectArraysToCustomSelectOptions = (objectsArray, keysArray) => {
  const newArray = [];
  objectsArray.forEach((obj) => {
    newArray.push({
      key: obj[keysArray[0]],
      value: obj[keysArray[1]],
    });
  });
  return newArray;
};

/** END WORKING WITH FILE SIZE */

const getBase64File = (file, cb) => {
  const reader = new FileReader();
  console.log('este es el documento', file, typeof file);
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
  reader.onerror = () => {
    cb('Error');
  };
};

const isset = (value) => {
  const isValid = [undefined, null, ''].indexOf(value) < 0;
  return isValid;
};

export {
  getUniqueId,
  ValidationMould,
  regexValidations,
  validationMouldPropTypes,
  getDifferenceTime,
  getNextOrderState,
  loadScript,
  getKmFromM,
  getDeliveryPrice,
  getDeeperCopyFromObjectWithObjectProps,
  getFileSize,
  fetchObjectArraysToCustomSelectOptions,
  getOptChainByColumns,
  getOptChain,
  /** * FORM UTILS */
  getUrlByFileRoot,
  getPaymentMethodNameById,
  getDocTypeNameById,
  /** TIME */
  getDateAlteredByMin,
  /** FILE */
  getBase64File,
  /** Check if value is empty */
  isset,
};
