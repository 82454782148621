import {
  Alert,
  Button, Col, Row, Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DollarCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { dishMould } from '../../../common/utils/objectsPropsMould';
import './menu-list.css';
import { getUrlByFileRoot } from '../../../common/utils/utils';
import { addToCart, setState as setCartState } from '../../../common/components/ShoppingCartMenu/shoppingCartSlice';
import { notify } from '../../../common/utils/notifications';
import Quantifier from '../../../common/components/Quantifier/Quantifier';

const { Text, Title } = Typography;

export default function MenuList({
  dishes,
  isOpen,
  onSelectCombo,
}) {
  const [currentDishes, setCurrentDishes] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentDishes(dishes);
  }, [dishes]);

  const addDishToCart = (dish) => {
    console.log('este es el plato', dish);
    if (dish.comboDetail && dish.comboDetail.length > 0) {
      onSelectCombo?.(dish);
      return;
    }
    dispatch(addToCart({
      id: dish.id,
      name: dish.name,
      desc: dish.desc,
      price: dish.price,
      slug: dish.slug,
      quantity: dish.quantity || 1,
      locationOnRestaurantId: dish.locationOnRestaurantId,
      addons: [],
      ingredients: [],
    }));
    setTimeout(() => {
      dispatch(setCartState(true));
      notify({
        text: 'Se ha agregado el plato al carrito',
        type: 'success',
        autoClose: 500,
      });
    }, 100);
  };
  const onChangeHandler = (dish, newQuantity) => {
    const dishesCopy = currentDishes.map((d) => {
      if (d.id === dish.id) {
        return {
          ...d,
          quantity: newQuantity,
        };
      }

      return d;
    });
    setCurrentDishes([
      ...dishesCopy,
    ]);
  };

  /** * ESTE ES LA ESTRUCTURA QUE DEBE TENER
   * CADA PLATO
   * {
   *    id,
   *    title,
   *    img,
   *    price,
   *    ingredients,
   *
   * }
   */

  if (!isOpen) {
    return (
      <div
        style={{
          height: '250px',
          width: '100%',
          padding: '4% 20%',
        }}
      >
        <Alert
          message="Tienda cerrada"
          description="No se pueden hacer pedidos en este momento"
          type="warning"
          showIcon
          closable
        />
      </div>
    );
  }

  return (
    <section style={{ padding: '0 20px' }}>
      <Row gutter={[24, 32]}>
        {
          currentDishes.map((d) => (
            <Col key={d.id} xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="dish-card">
                <div>
                  <div
                    style={{
                      backgroundImage: `url(${getUrlByFileRoot(d.img)})`,
                    }}
                  />
                </div>
                <div className="dish-card-details">
                  <Title className="dish-card-details__title" level={5}>{d.name}</Title>
                  <Text className="dish-card-details__desc" type="secondary">
                    {d.desc}
                  </Text>
                  <div className="dish-card-details__footer">
                    <Text className="dish-card-details__price">
                      <DollarCircleOutlined />
                      {' '}
                      {`S/ ${d.price.toFixed(2)}`}
                    </Text>
                    <div className="dish-card__btn-box">
                      <Quantifier
                        isSmall
                        value={d.quantity}
                        maxWidth="130px"
                        maxQuantity={25}
                        onQuantityChange={(val) => { onChangeHandler(d, val); }}
                      />
                      <Button
                        type="default"
                        shape="circle"
                        icon={<ShoppingCartOutlined />}
                        onClick={() => { addDishToCart(d); }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))
        }
      </Row>
    </section>
  );
}

MenuList.defaultProps = {
  dishes: [],
  isOpen: false,
  onSelectCombo: null,
};

MenuList.propTypes = {
  dishes: PropTypes.arrayOf(PropTypes.shape(dishMould)),
  isOpen: PropTypes.bool,
  onSelectCombo: PropTypes.func,
};
