/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWebData } from '../../../api/web';

const initialState = {
  restaurantsLocations: [],
  isLoading: false,
  currentCity: '',
  location: null,
};

const webSlice = createSlice({
  name: 'web',
  initialState,
  reducers: {
    setRestaurantsLocations: (state, action) => {
      state.restaurantsLocations = action.payload;
    },
    setWebLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCurrentCity: (state, action) => {
      state.currentCity = action.payload;
    },
    setLocation(state, action) {
      state.location = action.payload;
    },
  },
});

export const {
  setRestaurantsLocations,
  setWebLoading,
  setCurrentCity,
  setLocation,
} = webSlice.actions;

export default webSlice.reducer;

export const selectCurrentLocation = (state) => state.web.location;
export const selectWebRestaurantLocations = (state) => state.web.restaurantsLocations;
export const selectWebRestaurantLocationsV2 = (state) => {
  const defaultState = state.web.restaurantsLocations;
  /** DIVIDIMOS EL DATASET POR CIUDAD */

  const cities = [];
  defaultState.forEach((lr) => {
    const cityExists = cities.findIndex((cName) => cName === lr.location.city);
    if (!(cityExists > -1)) {
      cities.push(lr.location.city);
    }
  });

  return { ...state.web.restaurantsLocations, cities };
};
export const selectWebRestaurantLocationsCities = (state) => {
  const defaultState = state.web.restaurantsLocations;
  /** DIVIDIMOS EL DATASET POR CIUDAD */

  const cities = [];
  defaultState.forEach((lr) => {
    const cityExists = cities.findIndex(
      (cName) => cName?.toLowerCase() === lr.location.city?.toLowerCase(),
    );
    if (!(cityExists > -1)) {
      cities.push(lr.location.city);
    }
  });

  const locationsOnRestaurantsByCityCollection = cities.map((c) => ({
    name: c,
    locationsOnRestaurants: defaultState.filter((lr) => lr.location.city === c),
  }));
  return locationsOnRestaurantsByCityCollection;
};
export const selectWebLoading = (state) => state.web.isLoading;

export const fetchWebRestaurantsLocations = createAsyncThunk(
  'web/fetchWebRestaurantsLocations',
  async (locationCitySlug, { dispatch }) => {
    dispatch(setWebLoading(true));
    const { data, success } = await getWebData(locationCitySlug);

    if (success) {
      dispatch(setRestaurantsLocations(data));
    }

    dispatch(setWebLoading(false));
  },
);

export const selectCurrentCity = (state) => state.web.currentCity;
