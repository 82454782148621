import {
  Button,
  Col, Form, Input, Modal, Row, Select, Space, Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MinusCircleOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { notify } from '../../../../common/utils/notifications';
import { couponMould } from '../../../../common/utils/objectsPropsMould';
// import { isset } from '../../../../common/utils/utils';
import { couponModel, discountTypes, restrictionTypesEnum } from './couponModel';
import { getFormParams, saveCoupon } from '../../../../api/coupon';

export default function CouponFormModal({
  isOpen,
  couponData,
  onCancel,
  onSubmit,
}) {
  const [form] = Form.useForm();

  const discountTypesOpt = discountTypes.map((dt) => ({
    ...dt,
    key: dt.value,
  }));

  // const LRIdFieldName = 'locationOnRestaurantId';
  // const formLRIdValue = Form.useWatch(LRIdFieldName, form);
  const formRestrictionsValue = Form.useWatch('restrictions', form);

  const [isLoading, setLoading] = useState(false);
  // const [loadingCats, setLoadingCats] = useState(false);

  // const [lROpt, setLROpt] = useState([]);

  const [restrictionTypesOpt, setRestrictionTypesOpt] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [restaurantsOpt, setRestaurantsOpt] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [categoriesOpt, setCategoriesOpt] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dishesCollection, setDishesCollection] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dishesOpt, setDishesOpt] = useState([]);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue(couponData);
    } else {
      form.resetFields();
    }
  }, [isOpen]);

  /** useEffect(() => {
    if (dishesCollection.length > 0) {
      const couponId = form.getFieldValue('id');
      if (isset(couponId)) {
        const currentSelectedDishes = dishesCollection.filter(
          (d) => d.menuId === menuId,
        );
        if (currentSelectedDishes?.length > 0) {
          form.setFieldValue(
            'dishes',
            currentSelectedDishes.map((d) => d.id),
          );
        }
      }
      const filteredDishesCollection = dishesCollection.filter(
        (d) => d.locationOnRestaurantId === formLRIdValue
        || !isset(d.menuId),
      );

      setDishesOpt(filteredDishesCollection.map((d) => ({
        value: d.id,
        label: d.title,
        key: d.id,
      })));
    }
  }, [formLRIdValue]);* */
  useEffect(() => {
    const values = form.getFieldsValue();
    console.log(values);
    form.setFieldsValue(values);
  }, [formRestrictionsValue]);
  const fetchFormParams = () => {
    setLoading(true);
    getFormParams()
      .then(({
        success, data: {
          restaurants, dishes, restrictionTypes, categories,
        },
      }) => {
        // console.log('estas son las categorias', categories, success, dishes);
        if (success) {
          if (restrictionTypes?.length > 0) {
            setRestrictionTypesOpt(restrictionTypes.map((rt) => ({
              value: rt.value,
              label: rt.name,
              key: rt.value,
            })));
          }
          if (restaurants?.length > 0) {
            setRestaurantsOpt(restaurants.map((r) => ({
              value: r.id,
              label: r.name,
              key: r.id,
            })));
          }
          if (dishes?.length > 0) {
            setDishesCollection(dishes);
            setDishesOpt(dishes.map((d) => ({
              value: d.id,
              label: d.name,
              key: d.id,
            })));
          }
          if (categories?.length > 0) {
            setCategoriesOpt(categories.map((c) => ({
              value: c.id,
              label: c.name,
              key: c.id,
            })));
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchFormParams();
  }, []);
  const handleFormSubmit = () => {
    form.validateFields()
      .then((vals) => {
        console.log('enviando formulario');
        setLoading(true);
        saveCoupon(vals, couponData?.id)
          .then(({ success, message }) => {
            notify({
              text: message,
              type: success ? 'success' : 'error',
            });
            if (success) {
              fetchFormParams();
              onSubmit();
            }
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={`${couponData ? 'Editar Cupón' : 'Crear Cupón'}`}
      open={isOpen}
      width={900}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          icon={<SaveOutlined />}
          onClick={handleFormSubmit}
          loading={isLoading}
          type="primary"
        >
          Guardar
        </Button>,
      ]}
      onCancel={onCancel}
      okButtonProps={false}
    >
      <Form
        form={form}
        initialValues={couponModel}
        onFinish={handleFormSubmit}
        layout="vertical"
        preserve={false}
      >
        <Row gutter={[12, 8]}>
          <Col span={6}>
            <Form.Item
              name="name"
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: 'Es obligatorio especificar un nombre para el cupón',
                },
              ]}
            >
              <Input
                placeholder="Ingresa el nombre del cupón"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="code"
              label="Código"
              rules={[
                {
                  required: true,
                  message: 'Es obligatorio especificar el código del cupón',
                },
              ]}
            >
              <Input
                placeholder="Ingresa el código del cupón"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="amount"
              label="Monto"
              rules={[
                {
                  required: true,
                  message: 'Es obligatorio especificar el monto del cupón',
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Monto del cupón"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="isActive"
              label="Activo"
              rules={[
                {
                  required: true,
                  message: 'Es obligatorio especificar el estado del cupón',
                },
              ]}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="discountType"
              label="Tipo de descuento"
            >
              <Select
                placeholder="Selecciona el tipo de descuento"
                style={{ width: '100%' }}
                options={discountTypesOpt}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.List
              name="restrictions"
            >
              {(fields, { add, remove }) => (
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                >
                  {
                    fields.map((field) => (
                      <Row key={field.key} gutter={[12, 32]}>
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, 'restrictionType']}
                            shouldUpdate
                          >
                            <Select options={restrictionTypesOpt} />
                          </Form.Item>
                        </Col>
                        {
                          (form.getFieldValue('restrictions')[field.key]?.restrictionType === restrictionTypesEnum.MAX_VALUE
                            || form.getFieldValue('restrictions')[field.key]?.restrictionType === restrictionTypesEnum.MIN_VALUE)
                          && (
                            <Col span={8}>
                              <Form.Item
                                shouldUpdate
                                name={[field.name, 'amount']}
                                placeholder="Monto"
                              >
                                <Input
                                  type="number"
                                />
                              </Form.Item>
                            </Col>
                          )
                        }
                        {
                          (form.getFieldValue('restrictions')[field.key]?.restrictionType === restrictionTypesEnum.ALLOW_DISH
                            || form.getFieldValue('restrictions')[field.key]?.restrictionType === restrictionTypesEnum.REJECT_DISH)
                          && (
                            <Col span={8}>
                              <Form.Item
                                shouldUpdate
                                name={[field.name, 'dishId']}
                              >
                                <Select
                                  options={dishesOpt}
                                />
                              </Form.Item>
                            </Col>
                          )
                        }
                        {
                          form.getFieldValue('restrictions')[field.key]?.restrictionType === restrictionTypesEnum.RESTAURANT
                          && (
                            <Col span={8}>
                              <Form.Item
                                shouldUpdate
                                name={[field.name, 'restaurantId']}
                              >
                                <Select
                                  options={restaurantsOpt}
                                />
                              </Form.Item>
                            </Col>
                          )
                        }
                        {
                          form.getFieldValue('restrictions')[field.key]?.restrictionType === restrictionTypesEnum.CATEGORY
                          && (
                            <Col span={8}>
                              <Form.Item
                                shouldUpdate
                                name={[field.name, 'categoryId']}
                              >
                                <Select
                                  options={categoriesOpt}
                                />
                              </Form.Item>
                            </Col>
                          )
                        }
                        {console.log(field.key, 'este es el key', field.name, form.getFieldValue('restrictions'))}
                        <Col span={4}>
                          <Button
                            icon={<MinusCircleOutlined />}
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      </Row>
                    ))
                  }
                  <Button
                    onClick={() => add()}
                    icon={<PlusCircleOutlined />}
                  >
                    + Agregar restricción
                  </Button>
                </Space>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

CouponFormModal.defaultProps = {
  isOpen: false,
  couponData: null,
  onCancel: () => { },
  onSubmit: () => { },
};

CouponFormModal.propTypes = {
  isOpen: PropTypes.bool,
  couponData: PropTypes.shape(couponMould),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
