export const couponModel = {
  isActive: false,
  name: '',
  code: '',
  discountType: 'PERCENTAGE', // PERCETANGE o CASH
  amount: 0.00,
  restrictions: [],
};
export const discountTypes = [
  {
    label: 'Porcentaje',
    value: 'PERCENTAGE',
  },
  {
    label: 'Dinero',
    value: 'CASH',
  },
];
export const restrictionTypesEnum = {
  MAX_VALUE: 'MAX_VALUE',
  MIN_VALUE: 'MIN_VALUE',
  ALLOW_DISH: 'ALLOW_DISH',
  REJECT_DISH: 'REJECT_DISH',
  RESTAURANT: 'RESTAURANT',
  CATEGORY: 'CATEGORY',
};
