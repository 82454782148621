/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNextOrderState, getUniqueId } from '../../utils/utils';

const initialState = {
  orderId: null,
  isOpen: false,
  dishes: [],
  couponCode: '',
  couponDiscount: 0,
  subtotal: 0,
  totalIGV: 0,
  total: 0,
  delivery: 0,
  locationWebPhone: '',
  location: null,
  customerAddress: '',
  /* customer: {
    name: '',
    phone: '',
    address: '',
    DNI: '',
    email: '',
  }, */
  paymentMethod: '',
  igv: 0.18,
  currentLocationId: 0,
  totalsWithDiscount: {
    subtotal: 0,
    totalIGV: 0,
    total: 0,
  },
};

export const ShoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    setState(state, action) {
      state.isOpen = action.payload;
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    setWebPhone(state, action) {
      state.locationWebPhone = action.payload;
    },
    setCouponDiscount(state, action) {
      state.couponDiscount = action.payload;
    },
    setDelivery(state, action) {
      state.delivery = action.payload;
    },
    setCouponCode(state, action) {
      state.couponCode = action.payload;
    },
    setCustomerAddress(state, action) {
      state.customerAddress = action.payload;
    },
    updateCartTotals(state) {
      let total = 0;
      let subtotal = 0;
      let totalIGV = 0;

      state.dishes.forEach((dish) => {
        const dishPrice = dish.price * dish.quantity;

        const noIGVPrice = dishPrice / (1 + state.igv);
        const igv = dishPrice - noIGVPrice;

        subtotal += noIGVPrice;
        totalIGV += igv;
        total += dishPrice;
      });

      state.total = Math.round(total * 100) / 100;
      state.subtotal = Math.round(subtotal * 100) / 100;
      state.totalIGV = Math.round(totalIGV * 100) / 100;
    },
    updateWebCartTotals(state) {
      let total = 0;
      let subtotal = 0;
      let totalIGV = 0;
      let subtotalWithD = 0;
      let totalIGVWithD = 0;
      let totalWithD = 0;

      state.dishes.forEach((dish) => {
        const dishPrice = dish.price * dish.quantity;

        const noIGVPrice = dishPrice / (1 + state.igv);
        const igv = dishPrice - noIGVPrice;

        subtotal += noIGVPrice;
        totalIGV += igv;
        total += dishPrice;
      });

      /** * SUMANOS EL DELIVERY */
      total += state.delivery;
      /** *** RECALCULAMOS SUBTOTALES */
      subtotal = total / 1.18;
      totalIGV = total - subtotal;
      /** * DESCONTAMOS EL DESCUENTO ** */
      if (state.couponDiscount > 0) {
        totalWithD = total - state.couponDiscount;
        subtotalWithD = totalWithD / 1.18;
        totalIGVWithD = totalWithD - subtotalWithD;
      }

      /** SETEAMOS TOTALES Y SUBTOTALES */
      state.total = Math.round(total * 100) / 100;
      state.subtotal = Math.round(subtotal * 100) / 100;
      state.totalIGV = Math.round(totalIGV * 100) / 100;

      if (totalWithD > 0) {
        state.totalsWithDiscount.total = Math.round(totalWithD * 100) / 100;
        state.totalsWithDiscount.subtotal = Math.round(subtotalWithD * 100) / 100;
        state.totalsWithDiscount.totalIGV = Math.round(totalIGVWithD * 100) / 100;
      }
    },
    addToCart(state, action) {
      const notModifiedDishIndex = state.dishes.findIndex(
        (dish) => !dish.isModified && dish.id === action.payload.id,
      );
      if (
        !(action.payload.dishes?.length > 0)
        && !action.payload.isModified
        && notModifiedDishIndex > -1
      ) {
        const oldQuantity = state.dishes[notModifiedDishIndex].quantity;
        state.dishes.splice(notModifiedDishIndex, 1);
        action.payload.quantity += oldQuantity;
      }
      action.payload.uid = getUniqueId();
      state.dishes.push(action.payload);
    },
    setDishQuantity(state, action) {
      const dishIndex = state.dishes.findIndex((dish) => dish.uid === action.payload.uid);
      if (dishIndex > -1) {
        state.dishes[dishIndex].quantity = action.payload.quantity;
      }
    },
    removeFromCart(state, action) {
      const dishToRemoveUniqueId = action.payload;
      const dishToRemoveIndex = state.dishes.findIndex((dish) => dish.uid === dishToRemoveUniqueId);

      if (dishToRemoveIndex > -1) {
        state.dishes.splice(dishToRemoveIndex, 1);
      }
    },
    setCurrentCartLocationId(state, action) {
      state.currentLocationId = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  addToCart,
  setDishQuantity,
  setWebPhone,
  removeFromCart,
  updateCartTotals,
  updateWebCartTotals,
  reset,
  setState,
  setCurrentCartLocationId,
  setOrderId,
  setCouponCode,
  setCouponDiscount,
  setDelivery,
  setCustomerAddress,
} = ShoppingCartSlice.actions;

export const updateCartTotalsWithDelivery = createAsyncThunk(
  'shoppingCart/updateCartTotalsWithDelivery',
  async (newDeliveryPrice, { dispatch }) => {
    await dispatch(setDelivery(newDeliveryPrice));
    await dispatch(updateWebCartTotals());
  },
);

export const selectShoppingCart = (state) => state.shoppingCart;
export const selectShoppingCartState = (state) => state.shoppingCart.isOpen;
export const selectShoppingCartCoupon = (state) => state.shoppingCart.couponCode;
export const selectShoppingCartDiscount = (state) => state.shoppingCart.couponDiscount;
export const selectShoppingCartDishes = (state) => state.shoppingCart.dishes;
export const selectShoppingCartDelivery = (state) => state.shoppingCart.delivery;
export const selectShoppingCustomerAddress = (state) => state.shoppingCart.customerAddress;
export const selectShoppingCartTotals = (state) => ({
  subtotal: state.shoppingCart.subtotal,
  totalIGV: state.shoppingCart.totalIGV,
  total: state.shoppingCart.total,
  totalWithDiscount: state.shoppingCart.totalsWithDiscount.total,
});
export const selectCurrentCartLocationId = (state) => state.shoppingCart.currentLocationId;
export const selectOrderId = (state) => state.shoppingCart.orderId;
export const selectShoppingCartJson = (state) => {
  const orderState = getNextOrderState(0);
  const json = {
    dishes: [],
    state: `${orderState.id}:${orderState.name}`,
    subtotal: state.shoppingCart.subtotal,
    totalIGV: state.shoppingCart.totalIGV,
    total: state.shoppingCart.total,
    couponCode: state.shoppingCart.couponCode,
    delivery: state.shoppingCart.delivery,
  };
  json.dishes = state.shoppingCart.dishes.map((d) => (
    {
      ...d,
      ingredients: d.ingredients ? JSON.stringify(d.ingredients) : '',
      addons: d.addons ? JSON.stringify(d.addons) : '',
      dishes: (d.dishes && d.dishes?.length > 0) ? JSON.stringify(d.dishes) : '',
    }
  ));
  return json;
};

export default ShoppingCartSlice.reducer;
