/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../features/login/user';
import shoppingCartReducer from '../common/components/ShoppingCartMenu/shoppingCartSlice';
import dishesReducer from '../features/dish/dishesSlice';
import restaurantsReducer from '../features/restaurant/restaurantsSlice';
import restaurantsLocationReducer from '../features/restaurantLocation/restaurantLocationSlice';
import ordersReducer from '../features/order/ordersSlice';
import globalSpinnerReducer from '../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';
import admDishesReducer from '../features/admin/dish/admDishesSlice';
import admIngredientsReducer from '../features/admin/ingredients/admIngredientsSlice';
import admSuppliersReducer from '../features/admin/supplier/admSuppliersSlice';
import admOrdersReducer from '../features/admin/order/admOrdersSlice';
import appLayoutReducer from './Layouts/appLayoutSlice';
import webReducer from './Layouts/WebLayout/webSlice';

// export const RootReducer = combineReducers({
const AppReducer = combineReducers({
  user: persistReducer(
    {
      key: 'user',
      storage,
    },
    userReducer,
  ),
  dishes: dishesReducer,
  shoppingCart: persistReducer(
    {
      key: 'shoppingCart',
      storage,
    },
    shoppingCartReducer,
  ),
  restaurants: restaurantsReducer,
  restaurantsLocations: restaurantsLocationReducer,
  orders: ordersReducer,
  globalSpinner: globalSpinnerReducer,
  appLayout: appLayoutReducer,
  /** ADMIN REDUCERS */
  admDishes: admDishesReducer,
  admIngredients: admIngredientsReducer,
  admSuppliers: admSuppliersReducer,
  admOrders: admOrdersReducer,
  /** ***** WEB ****** */
  web: webReducer,
});
// eslint-disable-next-line import/prefer-default-export
export const RootReducer = (state, action) => {
  if (action.type === 'user/resetApp') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
    storage.removeItem('persist:root');
  }
  return AppReducer(state, action);
};
