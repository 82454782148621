import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { CompassOutlined, CopyOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { orderMould } from '../../../common/utils/objectsPropsMould';
import { getUrlByFileRoot } from '../../../common/utils/utils';
import './modal-dish-card.css';
import { notify } from '../../../common/utils/notifications';

const { Text, Title } = Typography;

export default function ModalDishCardDetails({
  isOpen,
  orderObj,
  onCloseModal,
}) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    setLoading(true);
    setModalState(isOpen);
    if (isOpen) {
      setCurrentOrder(orderObj);
    }
    setLoading(false);
  }, [isOpen]);

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      notify({
        text: 'Copiado al portapapeles',
        type: 'success',
      });
    }, () => {
      notify({
        text: 'No se pudo copias la información',
        type: 'error',
      });
    });
  };

  return (
    <Modal
      width={900}
      destroyOnClose
      open={modalState}
      cancelText="Cerrar"
      title={`Detalles de la orden N° ${currentOrder?.id.toString().padStart(8, 0)}`}
      maskClosable={false}
      onCancel={onCloseModal}
      footer={[
        <Button
          onClick={onCloseModal}
          danger
        >
          Cerrar
        </Button>,
      ]}
      className="modal-card-details"
    >
      <Row gutter={[24, 18]}>
        <Col xs={24} sm={24} md={12}>
          <Title level={4}>
            Datos del cliente
          </Title>
          <p>
            <b>Nombre:</b>
            {' '}
            {currentOrder?.customer.name}
            <br />
            <b>Celular:</b>
            {' '}
            {currentOrder?.customer.phone}
            {' '}
            {
              (currentOrder?.customer.link !== '')
              && (
              <Link href={currentOrder?.customer.link} target="_blank">
                <WhatsAppOutlined />
              </Link>
              )
            }
            <br />
            <b>Dirección:</b>
            {' '}
            {currentOrder?.customer.address}
            <br />
            {
                currentOrder?.customer?.googleMapsLink !== ''
                && (
                <Space
                  direction="horizontal"
                >
                  <Tooltip placement="top" title="Ir a Google Maps">
                    <a
                      target="_blank"
                      href={currentOrder?.customer?.googleMapsLink}
                      rel="noreferrer"
                    >
                      <CompassOutlined />
                    </a>

                  </Tooltip>
                  <Tooltip placement="top" title="Copiar Google Maps">
                    <Button
                      type="link"
                      onClick={() => copyTextToClipboard(currentOrder?.customer?.googleMapsLink)}
                    >
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                </Space>
                )
            }
            {
                (currentOrder?.customer?.messageToDelivery !== undefined
                && currentOrder?.customer?.messageToDelivery !== '')
                && (
                <Tooltip placement="top" title="Copiar Mensaje">
                  <Button
                    type="link"
                    onClick={() => copyTextToClipboard(currentOrder?.customer?.messageToDelivery)}
                  >
                    <CopyOutlined />
                  </Button>
                </Tooltip>
                )
            }
          </p>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Title level={4}>
            Datos de la orden
          </Title>
          <p>
            {currentOrder?.isForPickup
              ? 'Es para recojo en tienda'
              : 'Es para delivery'}
            <br />
            <b>Canal:</b>
            {' '}
            {currentOrder?.channel}
            <br />
            <b>Fecha:</b>
            {' '}
            {new Date(currentOrder?.startDateTime).toLocaleString('es-US', { timeZone: 'America/Lima' })}
          </p>
        </Col>
        {
                currentOrder?.dishes.map((d) => (
                  <Col xs={24} sm={24} md={12}>
                    <div style={{
                      borderRadius: '10px',
                      padding: '10px',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 10px 1px',
                    }}
                    >
                      <Row gutter={[24, 18]}>
                        <Col xs={24} sm={24} md={8}>
                          <img
                            style={{
                              width: '100%',
                              height: 'auto',
                              borderRadius: '10px',
                            }}
                            alt={d.name}
                            src={getUrlByFileRoot(d.img)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Text strong>
                            {d.name}
                            {' '}
                            X
                            {' '}
                            {d.quantity}
                          </Text>
                          <p>
                            {
                    (d.dishes && d.dishes.length > 1 && d.dishes != null)
                    && (
                    <>
                      <Text type="secondary" strong>
                        Platos:
                        {' '}
                      </Text>
                      <Text type="secondary" className="text-lowercase">{d.dishes?.map((ing) => ing.title).join(', ')}</Text>
                      <br />
                    </>

                    )
                  }
                            <Text type="secondary" strong>
                              No Agregar:
                            </Text>
                            {' '}
                            {
                      (d.ingredients && d.ingredients.length > 0)
                        ? <Text type="secondary">{d.ingredients.map((ing) => ing.name).join(', ')}</Text>
                        : <Text type="secondary"> Sin especificar </Text>
                    }
                            <br />
                            <Text type="secondary" strong>
                              Adicionales:
                            </Text>
                            {' '}
                            {
                      (d.addOns && d.addOns.length > 0)
                        ? <Text type="secondary">{d.addOns.map((ing) => ing.name).join(', ')}</Text>
                        : <Text type="secondary"> Sin especificar </Text>
                    }
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                ))
            }
        <div />
      </Row>

    </Modal>
  );
}
ModalDishCardDetails.defaultProps = {
  isOpen: false,
  orderObj: orderMould,
  onCloseModal: () => {},
};

ModalDishCardDetails.propTypes = {
  isOpen: PropTypes.bool,
  orderObj: PropTypes.shape(orderMould),
  onCloseModal: PropTypes.func,
};
