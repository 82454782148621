import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/plots';
import {
  Button, Space, Table, Tag,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { menuMould } from '../../../../common/utils/objectsPropsMould';

function AdmCouponList({ coupons, isLoading, onSelectedCoupon }) {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: (_, { name, isActive }) => (
        <>
          {name}
          {' '}
          {
            isActive
              ? <Tag color="success">Activo</Tag>
              : <Tag color="error">Desactivado</Tag>
          }
        </>
      ),
    },
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Tipo de descuento',
      dataIndex: 'discountType',
    },
    {
      title: 'Restricciones',
      dataIndex: 'restrictions',
      render: (_, { restrictions }) => (
        <>
          {restrictions.map((res) => (
            <Tag color="blue" key={res}>
              {res.restrictionType.toUpperCase()}
            </Tag>
          ))}
        </>
      ),
    },
  ];
  return (
    <Table
      pagination={false}
      dataSource={coupons}
      loading={isLoading}
    >
      {
                columns.map((c) => (
                  <Column
                    dataIndex={c.dataIndex}
                    key={c.dataIndex}
                    title={c.title}
                    render={c.render}
                  />
                ))
            }
      <Column
        title="Acciones"
        key="actions"
        render={(_, record) => (
          <Space>
            <Button onClick={() => onSelectedCoupon(record)}>
              <EditOutlined />
            </Button>
            <Button icon={<DeleteOutlined />} type="text" danger />
          </Space>
        )}
      />
    </Table>
  );
}

export default AdmCouponList;

AdmCouponList.defaultProps = {
  coupons: [],
  isLoading: false,
  onSelectedCoupon: () => {},
};

AdmCouponList.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.shape(menuMould)),
  isLoading: PropTypes.bool,
  onSelectedCoupon: PropTypes.func,
};
