import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import RestaurantLocationCard from './RestaurantLocationCard';
import { selectRestaurantsLocations, fetchRestaurantsLocations } from './restaurantLocationSlice';

function RestaurantsLocationsPage() {
  const dispatch = useDispatch();
  const abort = useRef();

  const restaurantsLocations = useSelector(selectRestaurantsLocations);
  useEffect(() => {
    abort.current = new AbortController();
    dispatch(fetchRestaurantsLocations({ signal: abort.current.signal }));

    return () => {
      abort.current.abort();
    };
  }, []);

  return (
    <Row gutter={[16, 18]} style={{ height: '100%' }} justify="center" align="middle">
      {restaurantsLocations.map((rest) => (
        <Col xs={24} sm={12} md={7} offset={1} className="gutter-row" key={rest.id}>
          <RestaurantLocationCard restaurantLocation={rest} />
        </Col>
      ))}
    </Row>
  );
}

export default RestaurantsLocationsPage;
