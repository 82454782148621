import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Space, Table } from 'antd';
import { Link } from 'react-router-dom';
import Column from 'antd/es/table/Column';
import { EditOutlined, InfoCircleFilled } from '@ant-design/icons';
import { dishMould } from '../../../../../common/utils/objectsPropsMould';
import DishInfoMenu from '../../DishInfoMenu/DishInfoMenu';
import { selectAdmDishById } from '../../admDishesSlice';

function AdmDishesList({ dishes, isLoading }) {
  const [selectedDishId, setSelectedDishId] = useState(-1);
  const newSelectedDish = useSelector(selectAdmDishById(selectedDishId));
  const [dishInfoMenuState, setDishInfoMenuState] = useState(false);
  // const [selectedDish, setSelectedDish] = useState(null);

  const [columns] = useState([
    {
      title: 'Nombre',
      dataIndex: 'title',
      sorter: (a, b) => a.title > b.title,
    },
    {
      title: 'Restaurante',
      dataIndex: 'restaurantLocationName',
    },
    {
      title: 'Costo',
      dataIndex: 'cost',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
    },
  ]);

  /** useEffect(() => {
    setSelectedDish(newSelectedDish);
  }, [selectedDishId]); * */

  useEffect(() => {
    console.log('estos son los platos', dishes);
  }, [dishes]);

  const handleInfoMenu = (isInfoMenuOpen, dishId = null) => {
    console.log('entrando al metodo de handle info menu', dishId);
    setDishInfoMenuState(isInfoMenuOpen);
    console.log(' lo habemos pasado');
    if (!isInfoMenuOpen) {
      setSelectedDishId(-1);
    }
    if (dishId) {
      setSelectedDishId(dishId);
      console.log('seteando new selected dish', newSelectedDish);
    }
  };

  return (
    <>
      <DishInfoMenu
        isOpen={dishInfoMenuState}
        dishId={selectedDishId}
        onStateChange={handleInfoMenu}
      />
      <Table
        pagination={false}
        dataSource={dishes}
        loading={isLoading}
      >
        {
          columns.map((c) => (
            <Column
              dataIndex={c.dataIndex}
              key={c.dataIndex}
              title={c.title}
              sorter={c.sorter}
            />
          ))
        }
        <Column
          title="Acciones"
          key="actions"
          render={(_, record) => (
            <Space>
              <Link to={`edit/${record.id}`} className="is-inline-block button is-white">
                <EditOutlined />
              </Link>
              {/* <Button icon={<DeleteOutlined />} type="text"
              danger onClick={() => { handleDelete(record.id); }} /> */}
              <Button icon={<InfoCircleFilled />} type="text" onClick={() => { handleInfoMenu(true, record.id); }} />
            </Space>
          )}
        />

      </Table>
    </>
  );
}

export default AdmDishesList;

AdmDishesList.defaultProps = {
  dishes: [],
  isLoading: false,
};

AdmDishesList.propTypes = {
  dishes: PropTypes.arrayOf(PropTypes.shape(dishMould)),
  isLoading: PropTypes.bool,
};
