/* eslint-disable no-unused-vars */
import axios from 'axios';
import { api } from './config/axios';
import { getOptChain } from '../common/utils/utils';

const getIngredients = () => [
  {
    id: 1,
    name: 'Masa',
    isSelected: true,
    isOptional: false,
  },
  {
    id: 2,
    name: 'Champiñones',
    isSelected: true,
    isOptional: false,
  },
  {
    id: 3,
    name: 'Pimientos',
    isSelected: true,
    isOptional: true,
  },
  {
    id: 4,
    name: 'Cebolla',
    isSelected: true,
    isOptional: false,
  },
  {
    id: 5,
    name: 'Aceitunas',
    isSelected: true,
    isOptional: true,
  },
];

const getAddons = () => [
  {
    id: 1,
    name: 'Extra picante',
    price: 10,
    isSelected: false,
    isOptional: true,
  },
  {
    id: 2,
    name: 'Borde de queso',
    price: 1.5,
    isSelected: false,
    isOptional: true,
  },
  {
    id: 3,
    name: 'Queso gouda',
    price: 1.5,
    isSelected: false,
    isOptional: true,
  },
  {
    id: 4,
    name: 'Queso fundido',
    price: 1.5,
    isSelected: false,
    isOptional: true,
  },
  {
    id: 5,
    name: 'Queso azul',
    price: 1.5,
    isSelected: false,
    isOptional: true,
  },
  {
    id: 6,
    name: 'Queso azul',
    price: 1.5,
    isSelected: false,
    isOptional: true,
  },
  {
    id: 7,
    name: 'Queso azul',
    price: 1.5,
    isSelected: false,
    isOptional: true,
  },
];
const getIngredientsAddonsFromDish = (dishId) => api.get(`/dish/get-ingredients-addons/${dishId}`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: 'false',
    data: '',
    message: 'Ha habido un error, contactar al administrador',
  }));

const loadDishes = (form) => api.post(
  '/dish/load-dishes-excel/',
  form,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
)
  .then(({ data }) => data)
  .catch((error) => ({
    success: 'false',
    data: '',
    message: 'Ha habido un error, contactar al administrador',
  }));

const addDish = (form) => api.post(
  '/dish/add',
  form,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, por favor contactar con el administrador',
  }));

const editDish = (form, dishId) => api.post(
  `/dish/edit/${dishId}`,
  form,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, por favor contactar con el administrador',
  }));

const getAllAdm = async (userId, optObj = {}) => {
  const optStringChain = `?${getOptChain(optObj)}`;
  return api.get(`/dish/get-all-adm/${userId}/${optStringChain}`)
    .then(({ data }) => data)
    .catch((error) => ({
      success: false,
      data: '',
      message: 'Ha habido un error en la consulta',
    }));
};

const getAdmParams = async (optObj = {}) => {
  const optStringChain = `?${getOptChain(optObj)}`;
  return api.get(`/dish/get-adm-params/?${optStringChain}`)
    .then(({ data }) => data)
    .catch((error) => ({
      success: false,
      data: '',
      message: 'Ha habido un error en la consulta',
    }));
};

const getOne = (dishId) => api.get(`/dish/single/${dishId}`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: '',
    message: 'Ha habido un error en la consulta',
  }));

const del = (dishId) => api.delete(`/dish/delete/${dishId}`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: '',
    message: 'No se ha podido eliminar el registros',
  }));

export {
  getIngredientsAddonsFromDish,
  getAdmParams,
  getAllAdm,
  loadDishes,
  addDish,
  editDish,
  getOne,
  del,
};
