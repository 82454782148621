// import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Pagination, Row } from 'antd';
import usePagination from '../../../hooks/usePagination';
// import { paginatorDots } from '../../../utils/constants';

export default function Paginator({
  onPageChange,
  rowCount,
  rowsPerPage,
  siblingCount = 1,
  currentPage,
}) {
  const paginationRange = usePagination({
    rowCount,
    rowsPerPage,
    siblingCount,
    currentPage,
  });

  if (paginationRange.length < 1) {
    return null;
  }

  return (
    <Row gutter={[16, 48]} justify="center">
      <Col className="mt-1">
        <Pagination
          current={currentPage}
          onChange={onPageChange}
          pageSize={rowsPerPage}
          total={rowCount}
        />
      </Col>
    </Row>
  );
}

Paginator.defaultProps = {
  onPageChange: null,
  rowCount: 1,
  rowsPerPage: 1,
  siblingCount: 1,
  currentPage: 1,
};

Paginator.propTypes = {
  onPageChange: PropTypes.func,
  rowCount: PropTypes.number,
  rowsPerPage: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
};
