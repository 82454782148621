import {
  Button,
  Checkbox,
  Col, Form, Input, InputNumber, Row, Select, Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
// import { KeyOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  MailOutlined,
  PhoneOutlined,
  PushpinOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { regexValidations } from '../../../utils/utils';
import { notify } from '../../../utils/notifications';
import {
  selectShoppingCartDelivery,
  // reset as resetCart,
  selectShoppingCartJson,
  selectShoppingCartTotals,
  selectShoppingCustomerAddress,
  setCustomerAddress,
  setOrderId,
  updateCartTotalsWithDelivery,
} from '../shoppingCartSlice';
import { saveWebOrder } from '../../../../api/web';
import { selectCurrentCity, selectCurrentLocation } from '../../../../app/Layouts/WebLayout/webSlice';
import useWhatsapp from '../../../hooks/useWhatsapp';
import AutoCompleteAddressInputWeb from '../../Extras/AutoCompleteAddressInput/AutoCompleteAddressInputWeb';
import useGoogleMasApi from '../../../hooks/useGoogleMapsApi';

const customerDataModel = {
  city: '',
  district: '',
  couponCode: '',
  email: '',
  name: '',
};
const citiesCollection = [
  {
    name: 'Lima',
    id: 1,
    districts: [
      'Miraflores',
      'San Isidro',
      'Barranco',
      'Surco',
      'Surquillo',
      'Jesus Maria',
      'Lince',
      'La Victoria',
      'Pueblo Libre',
      'San Borja',
      'Lima',
    ],
  },
  {
    name: 'Arequipa',
    id: 2,
    districts: [
      'Selva Alegre',
      'Arequipa',
      'Cayma',
      'Cerro Colorado',
      'Jacobo Hunter',
      'Jose Luis Bustamante',
      'Miraflores',
      'Paucarpata',
      'Sachaca',
      'Socabaya',
      'Tiabaya',
      'Yanahuara',
    ],
  },
];

const totalsStyle = {
  width: '100%',
  display: 'block',
  marginTop: '10px',
  color: '#135200',
};

export default function CustomerWebDataForm(
  {
    onFormCompleted,
    onLoadingChange,
    handleWhatsappOrder,
  },
) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);
  const [isDataSumitted, setDataSubmittedState] = useState(false);

  const [form] = Form.useForm();
  const watchCity = Form.useWatch('city', form);

  const dispatch = useDispatch();
  const shoppingCartData = useSelector(selectShoppingCartJson);

  const citiesOpt = citiesCollection.map((c) => ({ label: c.name, value: c.name, key: c.id }));
  const [districtsOpt, setDistrictsOpt] = useState([]);

  const currentCity = useSelector(selectCurrentCity);
  const currentDeliveryPrice = useSelector(selectShoppingCartDelivery);
  const shoppingCartTotals = useSelector(selectShoppingCartTotals);
  const customerAddress = useSelector(selectShoppingCustomerAddress);
  const currentLocation = useSelector(selectCurrentLocation);

  /** GOOGLE LOCATION */
  const googleMapId = 'google-map-container';

  /**
   * Se recibe un objeto con el formato {formatted_address,geometry: {location: {lat, lng}} }
   *  */
  const onMapMarkerChange = (newSelectedLocation) => {
    form.setFieldValue('address', newSelectedLocation.formatted_address);
  };

  const {
    onPlaceChangedHandler,
    setMap,
    // userCoords,
    // originLatLng,
    validCoords,
    deliveryPrice,
    currentOrigin, // {lat,lng}
  } = useGoogleMasApi(onLoadingChange, onMapMarkerChange);

  const handleAddressPlaceChange = (placeObj) => {
    onPlaceChangedHandler(placeObj);
  };
  useEffect(() => {
    dispatch(updateCartTotalsWithDelivery(deliveryPrice));
  }, [deliveryPrice]);
  /** ----------------- */
  /** * CHECKING IF IS FOR PICKUP */
  const orderIsForPickup = Form.useWatch('isForPickup', form);

  useEffect(() => {
    // setMapVisible(!orderIsForPickup);
    if (orderIsForPickup) {
      form.setFieldsValue({ address: 'Para recojo', reference: '' });
      dispatch(updateCartTotalsWithDelivery(0));
    } else {
      setMap(googleMapId);
    }
  }, [orderIsForPickup]);
  /* --------------- */

  useEffect(() => {
    form.setFieldValue('city', currentCity);
    setMap(googleMapId);
  }, []);

  useEffect(() => {
    const newDistrictsOpt = citiesCollection
      .find(
        (c) => c.name === watchCity,
      )
      ?.districts.map((d) => ({ label: d, value: d, key: d }));

    if (newDistrictsOpt) {
      setDistrictsOpt(newDistrictsOpt);

      const firstValue = newDistrictsOpt[0].value;
      form.setFieldValue('district', firstValue);
    }
  }, [watchCity]);

  const {
    sendWhatsappOrder,
  } = useWhatsapp();

  const getFullAddressFromCD = (customerData) => {
    const customerAddressReference = `${customerData?.reference || '-'}`;
    const customerAddressCity = `${customerData?.city || ''}`;
    const customerAddressDistrict = `${customerData?.district || ''}`;
    const fullddress = `${customerAddressCity} - ${customerAddressDistrict} - ${customerData?.address || ''} | Referencia: ${customerAddressReference}`;
    return fullddress;
  };

  const saveOrder = (customerData) => {
    console.log('este es el customer data enviado', customerData);
    onLoadingChange(true);
    saveWebOrder({ ...shoppingCartData, customerData })
      .then(({ success, message, data }) => {
        notify({
          text: message,
          type: success ? 'success' : 'error',
        });
        if (success) {
          setDataSubmittedState(true);
          // dispatch(resetCart());
          // sendWhatsappOrder(newMessage);
          // console.log(jsonData);
          if (data.orderId) {
            dispatch(setOrderId(data.orderId));
            onFormCompleted();
            // activateCulqiCheckout({ total: jsonData.total, id: data.orderId });
          }
          if (handleWhatsappOrder) {
            const extraMessage = `
              Esta es mi dirección: ${customerAddress}
              %0A
              Este es el costo de mi delivery: ${deliveryPrice}
            `;
            sendWhatsappOrder(extraMessage);
          }
          //
        }
      })
      .finally(() => {
        onLoadingChange(false);
      });
  };

  const handleFormChange = () => {
    // form.validateFields()
    form.validateFields().then((vals) => {
      /** GUARDAMOS LA DIRECCIÓN EN EL STORE */
      dispatch(setCustomerAddress(getFullAddressFromCD(vals)));
      /** ------------ */
      if ((!validCoords && !orderIsForPickup)
        || (!(deliveryPrice > 0) && !orderIsForPickup)
      ) {
        notify({
          text: 'Se debe especificar una dirección válida',
          type: 'error',
        });
        return;
      }
      saveOrder({ ...vals, ...currentOrigin });
      // onFormCompleted(vals);
    })
      .catch((error) => {
        console.log(error);
        notify({
          text: 'Algunos campos son incorrectos',
          type: 'error',
        });
      });
  };

  return (
    <Form
      form={form}
      onFinish={handleFormChange}
      initialValues={customerDataModel}
      layout="vertical"
    >
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Ciudad"
            name="city"
            rules={[
              {
                required: true,
                message: 'Es obligatorio especificar un correo electrónico válido',
              },
            ]}
          >
            <Select
              disabled
              options={citiesOpt}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Distrito"
            name="district"
            rules={[
              {
                required: true,
                message: 'Es obligatorio especificar un correo electrónico válido',
              },
            ]}
          >
            <Select
              disabled={isDataSumitted}
              options={districtsOpt}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            name="name"
            label="Nombre completo"
            rules={[
              {
                required: true,
                message: 'Es obligatorio especificar su nombre',
              },
            ]}
          >
            <Input
              disabled={isDataSumitted}
              prefix={<UserOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[
              {
                required: true,
                message: 'Es obligatorio especificar un correo electrónico válido',
                pattern: regexValidations.email,
              },
            ]}
          >
            <Input
              disabled={isDataSumitted}
              prefix={<MailOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="phone"
            label="Celular"
            rules={[
              {
                required: true,
                message: 'Es obligatorio que especifique un número de contacto',
              },
            ]}
            hasFeedback
          >
            <InputNumber
              style={{ width: '100%' }}
              prefix={<PhoneOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            name="isForPickup"
            valuePropName="checked"
            label=""
          >
            <Checkbox style={{ textAlign: 'left' }}>
              ¿Es para recojo en local?
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="address"
            label="Dirección"
            rules={[
              {
                required: !orderIsForPickup,
                message: 'Es obligatorio que especifique su dirección',
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <AutoCompleteAddressInputWeb
              onPlaceChanged={handleAddressPlaceChange}
              disabled={orderIsForPickup}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="reference"
            label="Referencia"
            rules={[
              {
                required: !orderIsForPickup,
                message: 'Es obligatorio que especifique una referencia',
              },
            ]}
          >
            <Input
              disabled={orderIsForPickup}
              prefix={<PushpinOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {
                orderIsForPickup
                && (
                  <Typography.Text
                    align="left"
                    style={totalsStyle}
                  >
                    <b>Dirección de la sede:</b>
                    {' '}
                    {currentLocation?.address ? currentLocation.address : 'Sin dirección Especificada'}
                  </Typography.Text>
                )
              }
          {
            (!orderIsForPickup && !validCoords)
            && (
              <Typography.Text
                align="left"
                type="danger"
              >
                La dirección especificada no se encuentra dentro de nuestro rango aceptado
              </Typography.Text>
            )
          }
          <Typography.Text
            align="left"
            style={totalsStyle}
          >
            <b>Delivery:</b>
            {' S/ '}
            {currentDeliveryPrice}
          </Typography.Text>
          <Typography.Text
            align="left"
            style={totalsStyle}
          >
            <b>Total de tu compra:</b>
            {' S/ '}
            {shoppingCartTotals.totalWithDiscount > 0
              ? shoppingCartTotals.totalWithDiscount
              : shoppingCartTotals.total}
          </Typography.Text>
        </Col>
        {
          !orderIsForPickup
          && (
          <Col span={24}>
            <div
              id={googleMapId}
              style={{
                minHeight: '300px',
                margin: '25px 0',
                borderRadius: '10px',
              }}
            />
          </Col>
          )
        }

        {/** <Col span={12}>
          <Form.Item
            name="couponCode"
            placeholder="Código de cupón"
          >
            <Input
              disabled={isDataSumitted}
              prefix={<KeyOutlined />}
            />
          </Form.Item>
          </Col>* */}
        <Col span={24}>
          <Button
            htmlType="submit"
            type="primary"
          >
            Realizar pedido
          </Button>
        </Col>
      </Row>
    </Form>

  );
}
CustomerWebDataForm.defaultProps = {
  onFormCompleted: () => { },
  onLoadingChange: () => { },
  handleWhatsappOrder: false,
};
CustomerWebDataForm.propTypes = {
  onFormCompleted: PropTypes.func,
  onLoadingChange: PropTypes.func,
  handleWhatsappOrder: PropTypes.bool,
};
