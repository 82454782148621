import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Card,
  Col,
  Row,
  Typography,
} from 'antd';
import {
  FileExcelOutlined,
  InboxOutlined,
  OrderedListOutlined,
  SettingOutlined,
  StarOutlined,
  TagsOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

const optionsStyles = {
  fontSize: '100px',
  textAlign: 'center',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
};

function AdminPage() {
  const options = [
    {
      id: 1,
      name: 'Menus',
      to: 'menus',
      icon: <OrderedListOutlined style={optionsStyles} />,
    },
    {
      id: 2,
      name: 'Tiendas',
      to: 'stores',
      icon: <StarOutlined style={optionsStyles} />,
    },
    {
      id: 3,
      name: 'Platos',
      to: 'dishes',
      icon: <InboxOutlined style={optionsStyles} />,
    },
    {
      id: 4,
      name: 'Insumos',
      to: 'ingredients',
      icon: <SettingOutlined style={optionsStyles} />,
    },
    {
      id: 5,
      name: 'Proveedores',
      to: 'suppliers',
      icon: <UsergroupAddOutlined style={optionsStyles} />,
    },
    {
      id: 6,
      name: 'Reportes',
      to: 'orders',
      icon: <FileExcelOutlined style={optionsStyles} />,
    },
    {
      id: 7,
      name: 'Cupones',
      to: 'coupons',
      icon: <TagsOutlined style={optionsStyles} />,
    },
    {
      id: 8,
      name: 'Variables Generales',
      to: 'system-vars',
      icon: <SettingOutlined style={optionsStyles} />,
    },
  ];

  return (
    <div className="container">
      <Row gutter={[16, 16]} align="middle" justify="center">
        {options.map((opt) => (
          <Col xs={24} sm={12} md={6} key={opt.id}>
            <Card
              cover={(
                <Link to={opt.to} style={{ height: '250px', width: '100%' }}>
                  {opt.icon}
                </Link>
              )}
            >
              <div className="card-content">
                <Row align="center" justify="center">
                  <Text strong>
                    {opt.name}
                  </Text>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default AdminPage;
