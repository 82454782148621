import { regexValidations } from './utils';
import { documentTypes } from './constants';
/** CREAR UN ARCHIVO DE CONSTANTES */
/* eslint-disable import/prefer-default-export */
const validateDocumentNumberByType = (documentId, documentNumber) => {
  let isValid = true;
  console.log('validando documentos', documentTypes, documentId, documentNumber);
  switch (parseInt(documentId, 11)) {
    case documentTypes.DNI:
      isValid = (documentNumber.length === 8
        && documentNumber.match(regexValidations.onlyNumbers));
      break;
    case documentTypes.RUC:
      isValid = (documentNumber.length === 11
        && documentNumber.match(regexValidations.onlyNumbers));
      break;
    case documentTypes.CE:
      isValid = (documentNumber.length === 9
        && documentNumber.match(regexValidations.onlyNumbers));
      break;
    default:
      isValid = false;
      break;
  }
  return isValid;
};

export { validateDocumentNumberByType };
