/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  ShopOutlined,
  ProjectOutlined,
  AppstoreOutlined,
  ReconciliationOutlined,
  LogoutOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from '@auth0/auth0-react';
import Icon from '@ant-design/icons/lib/components/Icon';
/* import {
  faHouse,
  faReceipt,
  faArrowRightToBracket,
  faKitchenSet,
  faBars,
  faXmark,
  faCashRegister,
} from '@fortawesome/free-solid-svg-icons'; */
import ankiLogo from '../../../assets/img/logo-anki.png';
import { useProvideAuth } from '../../utils/auth';
import './left-navbar.css';

const { Sider } = Layout;
const { useToken } = theme;

const routes = [
  {
    to: 'admin',
    name: 'Admin',
    icon: <ShopOutlined />,
  },
  {
    to: 'restaurants',
    name: 'Caja',
    icon: <ProjectOutlined />,
  },
  {
    to: 'kitchen',
    name: 'Cocina',
    icon: <AppstoreOutlined />,
  },
  {
    to: 'cash-register-orders',
    name: 'Pedidos de Caja',
    icon: <ReconciliationOutlined />,
  },
];

function IconLogo() { return (<img src={ankiLogo} width={64} alt="logo" />); }

function LeftNavbar({ onFixed }) {
  const auth = useProvideAuth();
  const { token } = useToken();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const location = useLocation();
  const currentKey = `${location.pathname.split('/')[2]}`;
  console.log(currentKey, 'aqui es', location.pathname.split('/'));
  console.log('current location', location);

  const auth0Logout = useAuth0().logout;

  // eslint-disable-next-line no-unused-vars
  const [isOpen, setState] = useState(false);

  const menuItems = routes.map((i) => ({
    key: i.to,
    label: (
      <NavLink
        to={`${i.to}`}
      >
        {i.name}
      </NavLink>
    ),
    icon: i.icon,
  }));

  useEffect(() => {
    onFixed?.(isOpen);
  }, [isOpen]);

  const logout = ({ domEvent }) => {
    domEvent.currentTarget.blur();
    auth.signout(() => { });
    auth0Logout({ logoutParams: { returnTo: window.location.origin } });
  };

  /** const handleState = (evt) => {
    evt.currentTarget.blur();
    setState(!isOpen);
  }; */

  return (
    <Sider
      style={{
        color: '#fff',
        backgroundColor: '#131231',
        position: isOpen ? 'fixed' : undefined,
        height: '100vh',
        zIndex: 2,
      }}
      className="left-navbar"
      trigger={null}
      collapsible
      collapsed={isOpen}
    >
      <Menu
        style={{ color: '#fff', backgroundColor: token.darkColor }}
        className="left-navbar__menu"
        theme="dark"
        defaultSelectedKeys={[routes[0].to]}
        selectedKeys={[currentKey]}
        mode="inline"
      >
        <Menu.Item
          key="state"
          className="trigger"
          // title={undefined}
          // title={`${isOpen ? 'Cerrar' : 'Abrir'}`}
          onClick={() => {
            const newValue = !isOpen;
            onFixed?.(newValue);
            setState(newValue);
          }}
        >
          <MenuOutlined />
        </Menu.Item>
        {
          menuItems.map((i) => (
            <Menu.Item
              key={i.key}
              title={i.name}
              path={i.key}
            >
              {i.icon}
              <span>
                {i.label}
              </span>
            </Menu.Item>
          ))
        }
        <Menu.Item key="logout" onClick={logout} style={{ position: 'absolute', bottom: '40px' }}>
          <LogoutOutlined />
          <span>Cerrar Sesión</span>
        </Menu.Item>
        <Menu.Item key="logo" title="Inicio" style={{ position: 'absolute', bottom: 0 }}>
          <NavLink to="/app">
            <Icon component={IconLogo} />
          </NavLink>
        </Menu.Item>

      </Menu>
    </Sider>
  );
}

LeftNavbar.defaultProps = {
  onFixed: null,
};
LeftNavbar.propTypes = {
  onFixed: PropTypes.func,
};

export default LeftNavbar;
