/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllByUser } from '../../api/restaurant';
import { setLoading } from '../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';
import { notify } from '../../common/utils/notifications';

const initialState = {
  collection: [],
};

const restaurantSlice = createSlice({
  name: 'restaurants',
  initialState,
  reducers: {
    setRestaurantsCollection: (state, action) => {
      state.collection = action.payload;
    },
  },
});

export const {
  setRestaurantsCollection,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;

export const selectRestaurants = (state) => state.restaurants.collection;

/** LLAMADAS ASICRONAS */
export const fetchRestaurants = createAsyncThunk(
  'restaurants/fetchRestaurants',
  async (user, { dispatch }) => {
    dispatch(setLoading(true));
    dispatch(setRestaurantsCollection([]));
    console.log('seleccionando restaurantes');
    // const { success, data } = await getAllByUser(user);
    const { data, success } = await getAllByUser(user.id);
    console.log(data);
    console.log('data de restaurantes', data, success);
    if (success) {
      dispatch(setRestaurantsCollection(data));
    } else {
      notify({
        text: 'Ha habido un error, comuníquese con el administrador',
        type: 'error',
      });
    }
    dispatch(setLoading(false));
  },
);
