/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCulqiVars, sendToken } from '../../api/culqi';
import { notify } from '../utils/notifications';
import { selectOrderId } from '../components/ShoppingCartMenu/shoppingCartSlice';

const setScriptsTags = (scriptUrlId) => {
  // const publicKey = '';

  let scriptUrl = document.getElementById(`${scriptUrlId}`);

  if (!scriptUrl) {
    scriptUrl = document.createElement('script');
    scriptUrl.id = scriptUrlId;
    scriptUrl.src = 'https://js.culqi.com/checkout-js';
    document.body.appendChild(scriptUrl);
  }

  window.Culqi = null;
};
const removeScriptsTags = (scriptUrlId, scriptPublicKeyId) => {
  const scriptUrl = document.getElementById(`${scriptUrlId}`);
  const scriptPublicKey = document.getElementById(`${scriptPublicKeyId}`);

  if (scriptUrl) {
    scriptUrl.remove();
  }
  if (scriptPublicKey) {
    scriptPublicKey.remove();
  }
};
/** const getCulqiVars = async () => ({
  xculqirsaid: 'Inserta aquí el id de tu llave pública RSA',
  rsapublickey: 'Inserta aquí tu llave pública RSA',
});* */
// eslint-disable-next-line no-unused-vars

export default function useCulqi(
  culqiContainerId,
  handleChargeCreation,
) {
  const scriptUrlId = 'culqi-url';
  // const scriptPublicKeyId = 'culqi-public-key';
  const [culqiVars, setCulqiVars] = useState(null);
  const [culqiIsLoading, setCulqiLoading] = useState(false);
  const [isPaymentProcessed, setPaymentProcessed] = useState(false);
  const orderId = useSelector(selectOrderId);
  // eslint-disable-next-line no-unused-vars
  const [culqiIsActive, setCulqiState] = useState(false);

  const getCulqiVars = async () => {
    if (!culqiVars) {
      setCulqiLoading(true);
      return fetchCulqiVars()
        .then(({ data, success }) => {
          if (success) {
            setCulqiVars(data);
          }
          return success ? data : null;
        })
        .catch(() => null)
        .finally(() => {
          setCulqiLoading(false);
        });
    }
    return culqiVars;
  };

  useEffect(() => {
    setScriptsTags(scriptUrlId);
    return () => {
      removeScriptsTags();
    };
  }, []);

  const verifyIsCulqiObjExists = () => {
    const culqiObj = window.Culqi;
    if (!culqiObj) {
      notify({
        text: 'Ha habido un error para conectarse a Culqi',
        type: 'error',
      });
      throw new Error('No se configuró Culqi correctamente');
    }
  };

  const openCulqiWindow = () => {
    verifyIsCulqiObjExists();
    window.Culqi.open();
  };
  const closeCulqiWindow = () => {
    verifyIsCulqiObjExists();
    window.Culqi.close();
  };

  const handleCulqiAction = () => {
    verifyIsCulqiObjExists();
    if (window.Culqi.token) {
      const token = window.Culqi.token.id;
      setCulqiLoading(true);
      sendToken({ tokenId: token, orderId })
        .then(({ message, success }) => {
          notify({
            text: message,
            type: success ? 'success' : 'error',
          });
          setPaymentProcessed(success);
          const title = success ? 'Transacción Realizada' : 'Ha habido un error para procesar el pago';
          handleChargeCreation(
            title,
            message,
            success,
          );
          if (success) {
            closeCulqiWindow();
          }
        })
        .catch(() => {
          notify({
            text: 'Ha habido un error para procesar su pago, por favor intente mas tarde',
            type: 'error',
          });
        })
        .finally(() => {
          setCulqiLoading(false);
        });
      console.log('Se ha creado un Token: ', token);
      // En esta linea de codigo debemos enviar el "Culqi.token.id"
      // hacia tu servidor con Ajax
    } else if (window.Culqi.order) { // ¡Objeto Order creado exitosamente!
      const { order } = window.Culqi;
      console.log('Se ha creado el objeto Order: ', order);
    } else {
      // Mostramos JSON de objeto error en consola
      console.log('Error : ', window.Culqi.error);
    }
  };

  const activateCulqiCheckout = (orderObj) => {
    getCulqiVars().then((vars) => {
      const { publicKey } = vars;
      const settings = {
        title: 'Anki Store',
        currency: 'PEN', // Este parámetro es requerido para realizar pagos yape
        amount: orderObj.total * 100, // Este parámetro es requerido para realizar pagos yape
      };
      const appearance = {
        theme: 'default',
        hiddenCulqiLogo: false,
        hiddenBannerContent: false,
        hiddenBanner: false,
        hiddenToolBarAmount: false,
        menuType: 'sliderTop', // sidebar / sliderTop / select
        buttonCardPayText: `Pagar ${orderObj.total}`, //
      };
      const options = {
        lang: 'auto',
        installments: false, // Habilitar o deshabilitar el campo de cuotas
        modal: false,
        paymentMethods: {
          tarjeta: true,
          yape: true,
          bancaMovil: true,
          agente: true,
          billetera: true,
          cuotealo: true,
        },
        container: `#${culqiContainerId}`,
      };
      const config = {
        settings,
        options,
        appearance,
      };
      const Culqi = new CulqiCheckout(publicKey, config);
      Culqi.open();

      Culqi.culqi = handleCulqiAction;

      window.Culqi = Culqi;
    });
  };

  return {
    activateCulqiCheckout,
    openCulqiWindow,
    closeCulqiWindow,
    culqiIsLoading,
    isPaymentProcessed,
    culqiIsActive,
  };
}
