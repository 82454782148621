/* eslint-disable max-len */
import { Tabs } from 'antd';
import React from 'react';
import styles from './style.module.css';

function TermsAndConditionsTab() {
  return (
    <>
      <h2>TÉRMINOS Y CONDICIONES DE USO DE LOS SITIOS DE E-COMMERCE PROVISTOS POR ANCANA</h2>
      <p>
        Los presentes términos y condiciones tienen por objeto establecer las directrices que regulan el uso de los Sitios de E-Commece provistos por ANCANA S.A.C., a todos los consumidores establecidos en territorio peruano que acepten los presentes términos y condiciones.
      </p>
      <p>
        El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los presentes términos y condiciones, con anterioridad a su acceso como Usuario. Asimismo, es recomendable que revise dicho documento antes de cada uno de sus ingresos, toda vez que ANCANA S.A.C. se reserva el derecho de actualizarlo y/o modificarlo en cualquier momento.
      </p>
      <p>
        Si el Usuario no está de acuerdo con alguno de los términos aquí expuestos, no deberá acceder y/o utilizar los Sitios de E-Commece que ofrece ANCANA S.A.C. para ordenar productos.
      </p>
      <ol className={styles['main-list']}>
        <li>
          <span>DEFINICIONES</span>
          <ul>
            <li>

              Para los efectos de los presentes términos y condiciones, se entiende por:
              <ul>
                <li>
                  <b>Ancana:</b>
                  {' '}
                  ANCANA S.A.C., con Registro Único de Contribuyente N° 20609412811, domiciliado en Cal. Gustavo Cornejo NRO. 148 Urb Umacollo, distrito, departamento y región de Arequipa.
                </li>
                <li>
                  <b>Sitios de E-Commerce:</b>
                  {' '}
                  Son las distintas plataformas virtuales de E-Commerce, desarrolladas por Ancana y de titularidad de esta última, en base a contratos de prestación de servicios suscritos con Empresas, que Ancana ofrece para que los Usuarios puedan ingresar a ellos a fin de visualizar los productos ofrecidos por las Empresas y, de considerarlo apropiado, celebrar transacciones con ellas.
                </li>
                <li>
                  <b>Usuario/Usuarios:</b>
                  {' '}
                  Persona natural o jurídica que acepta el presente documento y accede como usuario de los Sitios de E-Commerce.
                </li>
                <li>
                  <b>Empresas/Operadores:</b>
                  {' '}
                  Personas jurídicas o naturales que desarrollan el negocio de restaurantes y/o de venta de comida rápida y/o venta de productos y que, a su vez, han celebrado un acuerdo comercial con Ancana para ofertar sus productos a través del Sitio de E-Commerce. En tal sentido, las Empresas son las responsables exclusivas de los productos que comercializan a través de determinar las condiciones en las que se ofertan sus productos y con quienes -finalmente- los Usuarios celebran las transacciones de compraventa.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <span>OBJETO DEL CONTRATO</span>
          <ul>
            <li>
              Conforme a los presentes términos y condiciones, Ancana brinda a los Usuarios un servicio, gratuito, a través de cada uno de sus Sitios de E-Commerce, que tiene la finalidad de que los Usuarios puedan ingresar a ellos para visualizar los productos ofrecidos por las Empresas y, de considerarlo apropiado, celebrar transacciones con ellas. Asimismo, los consumidores aceptan las definiciones, condiciones técnicas, comerciales y de uso de los Sitios de E-Commerce, reconociendo expresamente que la labor de Ancana concluye al brindar el servicio necesario para que los Usuarios y las Empresas puedan celebrar transacciones y, en consecuencia, no es responsable de si dichas transacciones se celebran en condiciones equitativas y/o si se ejecutan idóneamente, como tampoco, de la calidad e inocuidad de los referidos productos.
            </li>
          </ul>
        </li>
        <li>
          <span>CONDICIONES DE USO DEL SERVICIO</span>
          <ul>
            <li>
              El Usuario se compromete a hacer un uso responsable y correcto del servicio proporcionado. Por ello, conoce, declara y acepta que el uso de la información que se brinde mediante los Sitios de E-Commerce se encuentra limitado a la finalidad señalada en el objeto de los presentes términos y condiciones. Cualquier uso de dicha información para fines distintos a los señalados en el objeto del presente documento, como la obtención de un beneficio económico y/o una investigación de mercado, será considerado irregular.

            </li>
          </ul>
        </li>
        <li>
          <span>CONDICIONES TÉCNICAS Y COMERCIALES</span>
          <ol>
            <li>
              Para la prestación del servicio que ofrece cada uno de los Sitios de E-Commerce, se debe contar con un correo electrónico.
            </li>
            <li>
              Los servicios son proporcionados a través de un medio virtual; por lo cual, para acceder a ellos, es necesario contar con un acceso a internet y un equipo con características tecnológicas mínimas para que dicho servicio se pueda ejecutar. El referido equipo y acceso a internet no es suministrado ni financiado por Ancana.
            </li>
            <li>
              Una vez que el Usuario se informe sobre los servicios que se ofrecen mediante los Sitios de E-Commerce, acepte estos términos y condiciones, se informe sobre la Política de Privacidad de los Sitios de E-commerce y gestione su acceso como Usuario, podrá acceder de manera inmediata al servicio ofrecido.
            </li>
          </ol>
        </li>
        <li>

          <span>ACCESO DE USUARIOS</span>
          <ul>
            <li>
              El acceso de los Usuarios es necesario para que Ancana pueda brindar los servicios que ofrece a los Operadores y estos, a los Usuarios, lo que tendrá lugar por intermedio de cada uno de los Sitios de E-Commerce. Dicho proceso consiste en el ingreso, por parte del Usuario, de su correo electrónico en el Sitio de E-Commerce al que desea ingresar para que, posteriormente, Ancana pueda confirmar la identidad del Usuario a través del envío de un correo de verificación. De esta manera, cada vez que Ancana detecte el uso de un mismo correo electrónico para ingresar a uno de los Sitios de E-Commerce -sin importar su Operador- podrá utilizar los datos que el mismo Usuarios ha registrado en otros Sitios de E-Commerce (como, por ejemplo, la dirección del Usuario y/o su número telefónico) para facilitar su proceso de compra al evitarle tener que volver a ingresarlos. No obstante, se deja constancia que el operador de un sitio de E-Commerce no tiene acceso a la información del Usuario que no se ha registrado en su Sitio de E-Commerce.
            </li>
          </ul>
        </li>
        <li>
          <span>DEL SERVICIO DE LOS SITIOS DE E-COMMERCE</span>
          <ul>
            <li>
              <b>Alcance geográfico del Servicio</b>
              <br />
              Mediante los Sitios de E-Commerce, e Usuario podrá optar por el retiro en el local del Operador o bien, solicitar el despacho o Delivery al domicilio que indique. En este último caso, el servicio de Delivery sólo será posible cuando el domicilio indicado por el Usuario se encuentre ubicado dentro de un polígono, el que será fijado por los Operadores. En caso de que un Usuario se encuentre fuera del polígono, se le indicará que el despacho no estará disponible en la ubicación que indicó.
            </li>
            <li>
              <b>Transacciones</b>
              <br />
              Las transacciones que los Usuarios celebren con las Empresas estarán sujetas a los precios establecidos por estas últimas, quienes además son las responsables de emitir los correspondientes comprobantes de pago en favor de los Usuarios por las transacciones que éstos realicen a través de los Sitios de E-Commerce.

            </li>
            <li>

              <b>Celebración de la transacción</b>
              <br />
              Las transacciones se celebrarán exclusivamente entre las Empresas y los Usuarios. Ancana no tiene ninguna influencia y/o participación en los términos, condiciones y precios acordados entre las Empresas y los Usuarios. Los Usuarios manifestarán su aceptación con los términos y condiciones, y los precios ofertados por las Empresas, haciendo click en el botón de aceptar. A partir de ese momento la transacción se considera como realizada.
            </li>
            <li>
              <b>Límites de pedidos</b>
              <br />
              El límite de productos que los Usuarios podrán solicitar a las Empresa será determinado por cada una de ellas en los Sitios de E-Commerce que les corresponda. En tal sentido, los Usuarios declaran conocer que las Empresas pueden tener una cantidad diferente de unidades disponibles de productos ofrecidos (stock) y/o capacidad de entrega.

            </li>
            <li>
              <b>Restricciones para algunos productos y protección de menores de edad</b>
              <br />
              Para el acceso a los Sitios de E-Commerce y, por ende, para la adquisición de los productos que ahí se ofrecen, es necesario ser mayor de edad (18 años) conforme a la legislación peruana, por lo que, al momento de realizar la transacción con las Empresas, el Usuario declara y garantiza que tiene la mayoría de edad requerida. Asimismo, debido a que legalmente no se permite suministrar ciertos productos a Usuarios que sean menores de edad, los Usuarios declaran que las personas que recibirán los productos de las Empresas son mayores de edad. Ancana se reserva la facultad de requerir a los Usuarios (o a cualquier persona designada por éstos para recibir los productos) prueba de su mayoría de edad al momento de su acceso, ejecución de las prestaciones contractuales con arreglo a los Sitios de E-Commerce y/o entrega de los productos.

            </li>
            <li>
              <b>Cancelación y devolución de los pedidos</b>
              <br />
              Una vez confirmada la compra por el Usuario, no será posible cancelar o devolver el pedido a través de los Sitios de E-Commerce. Cualquier pacto respecto a devoluciones y/o cancelaciones de pedidos serán acordados exclusivamente entre las Empresas y los Usuarios, pero -en algunos casos, cuando así sea acordado entre Empresa y Usuario- podrían ser ejecutados mediante los Sitios de E-Commerce.

            </li>
            <li>
              <b>Servicio al cliente</b>
              <br />
              Sin perjuicio de que los reclamos e inquietudes relacionadas con la idoneidad de los productos, celebración y ejecución de los acuerdos arribados con las Empresas, deberán ser dirigidas a estas últimas a través de los canales previstos por las Empresas, el Usuario contará con un Libro de Reclamaciones en cada uno de los Sitios de E-Commerce para remitirle que pueda consignar sus quejas y/o reclamos relacionados con los servicios o funcionalidades de Ancana:

            </li>
          </ul>
        </li>
        <li>
          <span>IMÁGENES DE LOS PRODUCTOS</span>
          <ul>
            <li>
              Las fotografías, representaciones gráficas o iconográficas y videos relativos a los productos ofertados por las Empresas (en adelante, las “Imágenes”) que figuran en los Sitios de E-Commerce son proporcionados por las Empresas y tienen un propósito únicamente orientador que carece de carácter exhaustivo. Las Imágenes se exhiben únicamente con fines referenciales y son meramente ilustrativas; por lo tanto, podrían existir diferencias entre las Imágenes y las dimensiones o características reales de los productos recibidos. En todo caso, los Usuarios declaran saber y entender que cualquier discrepancia entre las Imágenes de los productos en los Sitios de E-Commerce y los productos que finalmente reciban, será de responsabilidad única de la Empresa correspondiente.

            </li>
          </ul>
        </li>
        <li>
          <span>MEDIOS DE PAGO</span>
          <ul>
            <li>
              Los productos ofrecidos en los Sitios de E-Commerce, salvo que se señale una forma diferente de pago para casos particulares u ofertas de determinados productos, solo podrán ser pagados utilizando tarjetas de crédito o débito aceptadas por las respectivas pasarelas de pago. Las medidas de seguridad de su medio de pago serán aquellas que brinden las entidades financieras proveedoras de las tarjetas y/o las pasarelas de pago que utilice el Usuario. Ancana no asumirá ninguna responsabilidad por aquellos montos que se hayan cargado en las cuentas de los Usuarios, pero que no hayan llegado a percibirse en las cuentas de Ancana. Asimismo, tampoco se responsabilizará por violaciones en las medidas de seguridad que ofrecen estos proveedores y que se materialicen en transacciones a través de los Sitios de E-Commerce.

            </li>
          </ul>
        </li>
        <li>
          <span>RESPONSABILIDAD</span>
          <ul>
            <li>
              Respecto de la transacción

              El Usuario sabe y declara que Ancana, no toma decisiones ni asume responsabilidad sobre la calidad, idoneidad y condiciones de oferta de los productos que proveen las Empresas, lo que será de exclusiva responsabilidad de estas últimas. Por el contrario, el servicio ofrecido por Ancana, y en consecuencia su responsabilidad, se limita exclusivamente a brindar el servicio para que los Usuarios puedan visualizar, adquirir y pagar los productos que ofrecen los Operadores. En ese sentido, el Usuario declara que será responsable por las decisiones que adopte al celebrar sus transacciones con las Empresas, asumiendo asimismo los riesgos comerciales o económicos de las mismas. Además, tiene pleno conocimiento y declara entender que cualquier queja y/o reclamo que pudiese tener con respecto a la idoneidad del producto, la información brindada sobre los mismos y/o los términos de la transacción celebrada con las Empresas, deberán ser dirigidos directamente a esta.

            </li>
            <li>
              Respecto de la entrega

              El plazo para la entrega del producto dependerá de lo pactado entre las Empresas y los Usuarios para cada producto en específico. La responsabilidad frente al Usuario por alguna eventual demora en la entrega de los productos y/o del incumplimiento de los plazos de entrega que las Empresas hayan pactado con los Usuarios, será de exclusivamente de la Empresa.

            </li>
          </ul>
        </li>
        <li>

          <span>FUERZA MAYOR</span>
          <ul>
            <li>
              Ancana no será responsable de cualquier incumplimiento o retraso en el cumplimiento de cualquiera de sus obligaciones, cuando ello sea causado por un hecho que escape de su control, caso fortuito, hecho determinante de terceros o fuerza mayor. Fuerza mayor significa, para el propósito de esta cláusula, cualquier acto o hecho fuera del control razonable de Ancana, incluyendo, sin limitación, huelgas, cierres patronales u otras medidas reivindicativas por terceros, por defecto del proveedor o retraso, conmoción civil, disturbios, invasión, ataque terrorista o amenaza de ataque terrorista, guerra (declarada o no), amenaza o preparación para la guerra, incendio, explosión, tormenta, inundación, terremoto, hundimiento, epidemia, pandemia o cualquier desastre natural, o el fracaso o interrupción de las redes de telecomunicaciones públicas o privadas. De darse un evento de fuerza mayor, que afecte al cumplimiento de las obligaciones de Ancana, las obligaciones pactadas y plazos serán automáticamente suspendidos hasta la culminación de los hechos de fuerza mayor. Una vez superado el evento de fuerza mayor, los plazos continuarán con su cómputo.

            </li>
          </ul>
        </li>
        <li>
          <span>TRATAMIENTO DE DATOS PERSONALES</span>
          <ul>
            <li>
              Ancana tratará los datos personales de los Usuarios, para poder brindarles los servicios de los Sitios de E-Commerce, conforme a lo dispuesto en las normas de protección de datos personales y lo regulado en la Política de Privacidad de los Sitios de E-Commerce, la cual se encuentra publicada en el siguiente link: https://app.anki.pe. Los mecanismos y herramientas con las que cuentan los Usuarios para ejercer sus derechos, se encuentran indicados en el capítulo 9 de la Política de Privacidad de los Sitios de E-Commerce.

            </li>
          </ul>
        </li>
        <li>
          <span>PROPIEDAD INTELECTUAL DE LOS CONTENIDOS</span>
          <ul>
            <li>
              El contenido, organización, publicaciones, softwares y demás creaciones y/o invenciones utilizadas en los Sitios de E-Commerce, son de propiedad o de licencia exclusiva de Ancana. No obstante, los signos distintivos utilizados para identificar a las Empresas y las Imágenes que puedan encontrarse en los Sitios de E-Commerce, son de propiedad o uso de las Empresas.

            </li>
          </ul>
        </li>
      </ol>

    </>
  );
}

export default function TermsAndConditionsPage() {
  const items = [
    {
      key: '1',
      label: 'Términos y condiciones',
      children: <TermsAndConditionsTab />,
    },
  ];

  return (
    <Tabs items={items} />
  );
}
