/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Switch,
  Table,
} from 'antd';

const EditableContext = React.createContext(null);

export function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

export function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type = 'text',
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    // eslint-disable-next-line react/destructuring-assignment
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      // eslint-disable-next-line react/destructuring-assignment
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  const inputTypes = {
    number: <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />,
    boolean: <Switch ref={inputRef} onChange={save} onBlur={save} />,
    text: <Input ref={inputRef} onPressEnter={save} onBlur={save} />,
  };
  if (editable) {
    childNode = editing ? (
      <Form.Item
        valuePropName={type === 'boolean' ? 'checked' : 'value'}
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        {
            inputTypes[type]
        }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
}
