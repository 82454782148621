/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getOne as getAllDataByRestaurant } from '../../api/restaurant';
import { getOne as getAllDataByRestaurant } from '../../api/locationOnRestaurant';
import { setQueueTime } from '../order/ordersSlice';

const initialState = {
  collection: [],
  restaurant: {
    id: 0,
    name: '',
  },
  categories: [],
  // currentDish: { id: 0 },
};

export const dishesSlice = createSlice({
  name: 'dishes',
  initialState,
  reducers: {
    setDishesCollection: (state, action) => {
      action.payload.forEach((dish) => {
        dish.quantity = dish.quantity ? dish.quantity : 1;
      });
      state.collection = action.payload;
    },
    setDishesRestaurant: (state, action) => {
      state.restaurant = action.payload;
    },
    setDishesCategories: (state, action) => {
      state.categories = action.payload;
    },
    // setCurrentDish: (state, action) => {
    //   const currentDishIndex = state.collection.findIndex(
    //     (dish) => dish.id === parseInt(action.payload, 10),
    //   );
    //   if (currentDishIndex > -1) {
    //     state.currentDish = { ...state.collection[currentDishIndex] };
    //   } else {
    //     state.currentDish = { id: 0 };
    //   }
    // },
    setDishQuantity: (state, action) => {
      const modifiedDish = { ...action.payload };
      const dishIndex = state.collection.findIndex((dish) => dish.id === modifiedDish.id);
      if (dishIndex > -1) {
        state.collection[dishIndex].quantity = modifiedDish.quantity;
      }
    },
  },
});

export const
  {
    setDishesCollection,
    setDishesRestaurant,
    setDishesCategories,
    setDishQuantity,
    // setCurrentDish,
  } = dishesSlice.actions;

export const selectDishesCollection = (state) => state.dishes.collection;
export const selectDishesRestaurant = (state) => state.dishes.restaurant;
export const selectDishesCategories = (state) => state.dishes.categories;
export const selectDishById = (id) => (state) => {
  const dishIndex = state.dishes.collection.findIndex((d) => d.id === id);
  return (dishIndex > -1) ? state.dishes.collection[dishIndex] : null;
};

// export const selectCurrentDish = (state) => state.dishes.currentDish;

export default dishesSlice.reducer;

export const fetchDataByRestaurant = createAsyncThunk(
  'dishes/fetchDataByRestaurant',
  async (restaurantId, { dispatch, getState }) => {
    dispatch(setDishesRestaurant({ ...initialState.restaurant }));
    dispatch(setDishesCategories(initialState.categories));
    dispatch(setDishesCollection(initialState.collection));
    const { user } = getState();

    const { success, data } = await getAllDataByRestaurant(restaurantId, user.id);
    if (success) {
      console.log(data.restaurant, 'este es el restaurante');
      dispatch(setDishesRestaurant(data.restaurant));
      dispatch(setDishesCategories(data.menus[0].categories));
      dispatch(setDishesCollection(data.menus[0].dishes));
      if (data.extras && typeof data.extras?.queueTime === 'number') {
        dispatch(setQueueTime(data.extras?.queueTime));
      }
    }
  },
);
