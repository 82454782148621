import React from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Space, Typography,
} from 'antd';
import Card from 'antd/es/card/Card';
import { DollarCircleOutlined, EditOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { notify } from '../../../utils/notifications';
import { dishMould } from '../../../utils/objectsPropsMould';
import { setDishQuantity } from '../../../../features/dish/dishesSlice';
import Quantifier from '../../Quantifier/Quantifier';
import { addToCart, setState as setCartState } from '../../ShoppingCartMenu/shoppingCartSlice';
import './dish-row.css';
import { filesRoot } from '../../../../api/config/axios';
import CustomImg from '../../Extras/CustomImg/CustomImg';

const { Text } = Typography;

function DishRow({
  dish,
  onSelectCombo,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const columnsHeight = { height: '12rem' };
  const onChangeHandler = (newQuantity) => {
    dispatch(setDishQuantity({ id: dish.id, quantity: newQuantity }));
  };

  const addDishToCart = () => {
    if (dish.comboDetail && dish.comboDetail.length > 0) {
      onSelectCombo?.(dish.id);
      return;
    }

    dispatch(setDishQuantity({ id: dish.id, quantity: 1 }));
    dispatch(addToCart({ ...dish }));
    setTimeout(() => {
      dispatch(setCartState(true));
      notify({
        text: 'Se ha agregado el plato al carrito',
        type: 'success',
        autoClose: 500,
      });
    }, 100);
  };

  return (
    <Card>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <Row justify="center">
            <Col span={24}>
              <Row justify="center">
                <CustomImg
                  url={(dish.img && dish.img !== '') ? `${filesRoot}/${dish.img}` : 'https://bulma.io/images/placeholders/256x256.png'}
                  width="150px"
                  height="150px"
                  border="10px"
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={10} lg={14} xl={14}>
          <div>
            <Text strong>
              {dish.name}
            </Text>
          </div>
          <div>
            <Text type="secondary">
              {dish.desc}
            </Text>
          </div>
          <div>
            <div className="table-dishes__item-price">
              <DollarCircleOutlined />
              <span>{dish.price.toFixed(2)}</span>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={4}>
          <Row justify="center" align="center" gutter={[0, 16]}>
            <Col span={24}>
              <Quantifier value={dish.quantity} onQuantityChange={onChangeHandler} />
            </Col>
            <Col span={24} align="start">
              <Space wrap>
                {
                  (!dish.comboDetail || !(dish.comboDetail?.length > 0))
                  && (
                    <Button type="default" shape="circle" icon={<EditOutlined />} onClick={() => { navigate(`${dish.id}`); }} />
                  )
                }
                <Button type="default" shape="circle" icon={<ShoppingCartOutlined />} onClick={addDishToCart} />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

DishRow.defaultProps = {
  onSelectCombo: null,
};

DishRow.propTypes = {
  dish: PropTypes.shape(
    dishMould,
  ).isRequired,
  onSelectCombo: PropTypes.func,
};

export default DishRow;
