import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '@ant-design/plots';
import {
  Button, Space, Table, Tag,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { menuMould } from '../../../../common/utils/objectsPropsMould';

function AdmMenuList({ menus, isLoading, onSelectedMenu }) {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
    },
    {
      title: 'Categorías',
      dataIndex: 'categoriesList',
      render: (_, { categoriesList }) => (
        <>
          {categoriesList.map((cat) => (
            <Tag color="blue" key={cat}>
              {cat.toUpperCase()}
            </Tag>
          ))}
        </>
      ),
    },
  ];
  return (
    <Table
      pagination={false}
      dataSource={menus}
      loading={isLoading}
    >
      {
                columns.map((c) => (
                  <Column
                    dataIndex={c.dataIndex}
                    key={c.dataIndex}
                    title={c.title}
                    render={c.render}
                  />
                ))
            }
      <Column
        title="Acciones"
        key="actions"
        render={(_, record) => (
          <Space>
            <Button onClick={() => onSelectedMenu(record)}>
              <EditOutlined />
            </Button>
            <Button icon={<DeleteOutlined />} type="text" danger />
          </Space>
        )}
      />
    </Table>
  );
}

export default AdmMenuList;

AdmMenuList.defaultProps = {
  menus: [],
  isLoading: false,
  onSelectedMenu: () => {},
};

AdmMenuList.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape(menuMould)),
  isLoading: PropTypes.bool,
  onSelectedMenu: PropTypes.func,
};
