/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import {
  selectShoppingCart,
  setDishQuantity,
  selectShoppingCartState,
  setState,
  updateCartTotals,
  removeFromCart,
  reset as resetCart,
} from './shoppingCartSlice';
import PurchaseModal from './ShoppingCartPurchaseModal/PurchaseModal';
import Quantifier from '../Quantifier/Quantifier';
import { notify } from '../../utils/notifications';
import './shopping-car-menu.css';
// import CustomInput from '../Extras/CustomInput/CustomInput';
import AutoCompleteAddressInput from '../Extras/AutoCompleteAddressInput/AutoCompleteAddressInput';
import { getDeliveryPrice, getKmFromM, getNextOrderState } from '../../utils/utils';
import { saveWebOrder } from '../../../api/web';
// import CustomImg from '../Extras/CustomImg/CustomImg';
// import { filesRoot } from '../../../api/config/axios';

const { Text, Title } = Typography;

function ShoppingCartMenu({
  isWeb,
}) {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectShoppingCartState);
  const [isLoading, setLoading] = useState(false);
  const [isPurchaseModalOpen, setPurchaseModalState] = useState(false);
  const [clientAddress, setClientAddress] = useState('');
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const userLocation = useRef(null);
  // const [clientAddressObj, setClientAddressObj] = useState('');

  // const shoppingCartMenuWidth = '31rem';

  const shoppingCartData = useSelector(selectShoppingCart);

  const onClickHandler = () => {
    dispatch(setState(!isOpen));
  };

  const onQuantityChangeHandler = (obj) => {
    dispatch(setDishQuantity(obj));
  };

  const fetchCurrentUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        userLocation.current = position;
      });
    }
  };

  useEffect(() => {
    fetchCurrentUserLocation();
  });

  useEffect(() => {
    dispatch(updateCartTotals());
  }, [shoppingCartData]);

  const cleanFields = () => {
    setClientAddress('');
    setDeliveryPrice(0);
  };

  const reset = () => {
    dispatch(resetCart());
    cleanFields();
    dispatch(setState(!isOpen));
    // notifySuccess();
    notify({
      text: 'Se ha limpiado el carrito',
      type: 'success',
      autoClose: 1000,
    });
  };

  const changePurchaseModalState = (val, resetFields = false) => {
    console.log('seteamos el stado del modal en el carrito', val);
    if (resetFields) {
      cleanFields();
    }
    setPurchaseModalState(val);
  };

  const onPlaceChangedHandler = (newAddressObj) => {
    // const userLocation = { current: null };
    // if ('geolocation' in navigator) {
    // navigator.geolocation.getCurrentPosition(async (position) => {
    // userLocation.current = position;
    // eslint-disable-next-line no-undef
    if (userLocation) {
      // eslint-disable-next-line no-undef
      const distanceMatrixService = new google.maps.DistanceMatrixService();
      const origin = ({
        lat: userLocation.current.coords.latitude,
        lng: userLocation.current.coords.longitude,
      });
      const destination = newAddressObj.geometry.location;
      const request = {
        origins: [origin],
        destinations: [destination],
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        // eslint-disable-next-line no-undef
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      };
      setLoading(true);
      distanceMatrixService.getDistanceMatrix(request)
        .then((response) => {
          const distance = response.rows[0].elements[0].distance.value;
          const currentDistance = getKmFromM(distance);
          const currentDeliveryPrice = getDeliveryPrice(currentDistance);
          setDeliveryPrice(currentDeliveryPrice);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  };
  // };
  const onAddressChangeHandler = (newValue) => {
    // const { value } = evt.target;
    setClientAddress(newValue);
  };

  const openPurchaseModal = () => {
    const message = shoppingCartData.dishes.length < 1 && 'Carrito vacío';
    if (shoppingCartData.dishes.length > 0) {
      changePurchaseModalState(true);
    } else {
      notify({
        text: message,
        type: 'error',
        autoClose: 1000,
      });
    }
  };

  /** * WEB METHODS */
  const sendWhatsappOrder = () => {
    const defaultPhoneNumber = '51955756006';
    const selectedDishes = shoppingCartData.dishes.map((d) => ({
      name: d.name,
      price: d.price,
      quantity: d.quantity,
    }));
    const message = `
Hola, quisiera realizar el siguiente pedido realizado desde ${window.location.origin}:%0A
%0A
${selectedDishes.map((d) => `${d.quantity} x ${d.name} (P/U ${d.price}) ----> Total del Plato: ${(d.quantity * d.price).toFixed(2)}`).join('%0A')}
%0A
    `;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `https://api.whatsapp.com/send/?phone=${shoppingCartData.locationWebPhone || defaultPhoneNumber}&text=${message}`);
    // document.appendChild(link);
    link.click();

    link.remove();
  };
  const generateJson = () => {
    const orderState = getNextOrderState(0);
    const json = {
      dishes: [],
      state: `${orderState.id}:${orderState.name}`,
      subtotal: shoppingCartData.subtotal,
      totalIGV: shoppingCartData.totalIGV,
      total: shoppingCartData.total,
    };
    json.dishes = shoppingCartData.dishes.map((d) => (
      {
        ...d,
        ingredients: d.ingredients ? JSON.stringify(d.ingredients) : '',
        addons: d.addons ? JSON.stringify(d.addons) : '',
        dishes: (d.dishes && d.dishes?.length > 0) ? JSON.stringify(d.dishes) : '',
      }
    ));
    return json;
  };
  const saveOrder = () => {
    const jsonData = generateJson();
    setLoading(true);
    saveWebOrder(jsonData).then(({ success, message }) => {
      notify({
        text: message,
        type: success ? 'success' : 'error',
      });
      if (success) {
        dispatch(resetCart());
        sendWhatsappOrder();
      }
    })
      .finally(() => {
        setLoading(false);
      });
  };

  /** ********************* */

  return (
    <React.StrictMode>
      {
        !isWeb
        && (
          <PurchaseModal
            isOpen={isPurchaseModalOpen}
            onModalStateChange={changePurchaseModalState}
            clientAddress={clientAddress}
            deliveryPrice={deliveryPrice}
          />
        )
      }
      <div className={`${isWeb && !isOpen ? 'btn-car-container-web' : 'btn-car-container'}`}>
        <Button
          icon={isOpen ? <CloseCircleOutlined /> : <ShoppingOutlined />}
          type="primary"
          ghost
          onClick={onClickHandler}
        />
      </div>
      {/** OPTIMIZAR CLICK HANDLER */}
      <div className={`car-menu ${isOpen ? 'is-open' : ''}`} onClick={onClickHandler} >
        {/** OPTIMIZAR CLICK HANDLER */}
        <div id="car-menu-container" className="car-menu__container" onClick={(e) => { e.stopPropagation(); }} >
          <div className="cart-menu__title-container">
            <Title level={2} strong style={{ marginTop: 0 }} >
              <ShoppingCartOutlined />
              {' '}
              Carrito
            </Title>
          </div>
          <div style={{ overflowY: 'scroll' }}>
            <Card size="small" style={{ overflowY: 'scroll', maxHeight: '65vh', minHeight: '65vh' }}>
              <Space
                direction="vertical"
                style={{
                  display: 'flex',
                }}
              >
                {shoppingCartData.dishes.map((dish) => (
                  <Card
                    key={`item_${dish.uid}`}
                  >
                    <div className="car-menu__items-grid">
                      <div>
                        <div>
                          <Title strong level={5} className="items__title" >
                            {dish.name}
                          </Title>
                          {/** <CustomImg
                            url={(dish.img && dish.img !== '') ? `${filesRoot}/${dish.img}` : 'https://bulma.io/images/placeholders/256x256.png'}
                            border="5px"
                            width="100px"
                            height="100px"
                          />* */}
                        </div>
                        <div>
                          {(dish.ingredients && dish.ingredients.length > 0)
                            && (
                              <p>
                                <Text strong type="secondary">
                                  No agregar:
                                  {' '}
                                </Text>
                                <Text type="secondary">
                                  {dish.ingredients.map((ing) => ing.name).join(', ')}
                                </Text>
                              </p>
                            )}
                          {(dish.addons && dish.addons.length > 0)
                            && (
                              <p>
                                <Text strong type="secondary">
                                  Adicionales:
                                  {' '}
                                </Text>
                                <Text type="secondary">
                                  {dish.addons.map((addon) => addon.name).join(', ')}
                                </Text>
                              </p>
                            )}
                          {(dish.dishes && dish.dishes.length > 0)
                            && (
                              <p>
                                <span className="has-text-weight-bold has-text-grey-dark is-size-7">
                                  Platos:
                                  {' '}
                                </span>
                                <span className="is-size-7 has-text-grey">
                                  {dish.dishes.map((d) => d.title).join(', ')}
                                </span>
                              </p>
                            )}
                        </div>
                      </div>
                      <div>
                        <Space direction="vertical" align="end" style={{ display: 'flex' }}>
                          <Button
                            icon={<DeleteOutlined />}
                            shape="circle"
                            danger
                            onClick={() => { dispatch(removeFromCart(dish.uid)); }}
                          />
                          <Quantifier
                            value={dish.quantity}
                            onQuantityChange={(newValue) => {
                              onQuantityChangeHandler({ uid: dish.uid, quantity: newValue });
                            }}
                            isSmall
                          />
                          <div>
                            <Text strong>
                              Precio /u:
                              {' '}
                            </Text>
                            <Text type="secondary">
                              {dish.price.toFixed(2)}
                            </Text>
                          </div>
                        </Space>
                      </div>
                    </div>
                  </Card>
                ))}
              </Space>
            </Card>
            <Card size="small">
              <Space direction="vertical" style={{ display: 'flex' }}>
                {
                  !isWeb
                  && (
                    <Row>
                      <Col span={24} >
                        <AutoCompleteAddressInput
                          value={clientAddress}
                          onInputChange={onAddressChangeHandler}
                          onPlaceChanged={onPlaceChangedHandler}
                          centerPointObj={userLocation.current ? {
                            lat: userLocation.current.coords.latitude,
                            lng: userLocation.current.coords.longitude,
                          } : null}
                        />
                      </Col>
                    </Row>
                  )
                }
                <Row justify="space-between">
                  <Col>
                    <Text strong>
                      Subtotal:
                    </Text>
                  </Col>
                  <Col>
                    <Text>
                      {shoppingCartData.subtotal}
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong>
                      Total (inc. IGV):
                    </Text>
                  </Col>
                  <Col>
                    <Text>
                      {shoppingCartData.total}
                    </Text>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Text strong>
                      Delivery:
                    </Text>
                  </Col>
                  <Col>
                    {deliveryPrice}
                  </Col>
                </Row>
                <Row justify="start">
                  <Space direction="horizontal" >
                    <Button onClick={reset} danger disabled={isLoading} >
                      Cancelar Pedido
                    </Button>
                    {
                      isWeb
                        ? (
                          <Button type="primary" onClick={saveOrder} >
                            Enviar Pedido
                          </Button>
                        )
                        : (
                          <Button type="primary" onClick={openPurchaseModal} disabled={isLoading}>
                            Ingresar Pedido
                          </Button>
                        )
                    }
                  </Space>
                  {/** <button
                 * type="button" className="button is-danger mr-2" onClick={reset}>
                 * Cancelar Pedido </button>
                <button
                type="button" className="button is-success"
                onClick={openPurchaseModal}> Ingresar Pedido </button>* */}
                </Row>
              </Space>
            </Card>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
}

ShoppingCartMenu.defaultProps = {
  isWeb: false,
};

ShoppingCartMenu.propTypes = {
  isWeb: PropTypes.bool,
};

export default ShoppingCartMenu;
