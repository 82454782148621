import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import AdmDishesList from './AdmDishesList/AdmDishesList';
import { getAllDishesByCurrentUser, selectAdmDishesCollection } from '../admDishesSlice';
// import LoadFileForm from '../LoadFileForm/LoadFileForm';
import Paginator from '../../../../common/components/Extras/Paginator/Paginator';
import { setCurrentPageTitle } from '../../../../app/Layouts/appLayoutSlice';
import { getAdmParams } from '../../../../api/dish';

function AdmDishesPage() {
  const dispatch = useDispatch();
  const dishes = useSelector(selectAdmDishesCollection);

  /** ******* FORM ******* */
  // eslint-disable-next-line no-unused-vars
  const [lRestaurantsOpt, setLRestaurantsOpt] = useState([]);
  const [isFiltersLoading, setFiltersLoading] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [form] = Form.useForm();
  const model = {
    q: '',
    lRestaurantsIds: [],
  };
  /** *********************** */
  /** **** PAG */
  const [page, setCurrentPage] = useState(1);
  const [paginationProps, setPaginatioProps] = useState({
    rowCount: 0,
    rowsPerPage: 10,
  });

  const executeSearch = (optObj = {}) => {
    const formValues = form.getFieldsValue();
    const currentOptObj = { ...formValues, ...optObj };
    setDataLoading(true);
    dispatch(getAllDishesByCurrentUser(currentOptObj))
      .then(({ payload }) => {
        if (payload) {
          const { rowCount, currentPage, rowsPerPage } = payload;
          setCurrentPage(currentPage);
          setPaginatioProps({
            ...paginationProps,
            rowCount: rowCount || 0,
            rowsPerPage,
          });
        }
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const fetchAdmParams = () => {
    setFiltersLoading(true);
    getAdmParams()
      .then(({ data: { lRestaurantCollection } }) => {
        setLRestaurantsOpt(lRestaurantCollection.map((lr) => ({ value: lr.id, label: lr.name })));
      })
      .finally(() => {
        setFiltersLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setCurrentPageTitle('Platos'));
    executeSearch({ page });
    fetchAdmParams();
    console.log('primer use effect del admDishes page');
  }, []);

  const resetForm = () => {
    form.resetFields();
    executeSearch();
  };

  return (
    <>
      {/** <LoadFileForm state={modalState} changeState={setModalState} />* */}
      <div className="btn-item-form__container">
        {/** <button type="button" className="button mr-4" onClick={() => { setModalState(true); }}>
          <FontAwesomeIcon icon={faFileExcel} />
  </button>* */}
        <Link to="add" className="button">
          <FontAwesomeIcon icon={faAdd} />
        </Link>
      </div>
      <div>
        <Form
          initialValues={model}
          form={form}
          onFinish={executeSearch}
        >
          <Row>
            <Space>
              <Form.Item
                name="q"
              >
                <Input
                  placeholder="Busca por nombre"
                />
              </Form.Item>
              <Form.Item
                name="lRestaurantsIds"
              >
                <Select
                  mode="multiple"
                  placeholder="Restaurantes (Todos)"
                  loading={isFiltersLoading}
                  style={{ width: 350 }}
                  options={lRestaurantsOpt}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  htmlType="submit"
                  loading={isDataLoading}
                >
                  Buscar
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="link"
                  icon={<ClearOutlined />}
                  onClick={resetForm}
                  disabled={isDataLoading}
                />
              </Form.Item>
            </Space>
          </Row>

        </Form>
      </div>
      <div>
        <AdmDishesList dishes={dishes} isLoading={isDataLoading} />
        <Paginator
          rowCount={paginationProps.rowCount}
          rowsPerPage={paginationProps.rowsPerPage}
          currentPage={page}
          onPageChange={(newPage, pageSize) => {
            executeSearch({ page: newPage, limit: pageSize });
          }}
        />
      </div>
    </>
  );
}

export default AdmDishesPage;
