import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { dishCategoryMould } from '../../utils/objectsPropsMould';

// eslint-disable-next-line no-unused-vars
function BtnGroupCategories({ categories, selectedCategory, setSelectedCategory }) {
  const onClickHandler = (evt) => {
    setSelectedCategory(evt.target.value);
  };

  return (
    <Radio.Group value={selectedCategory.id} onChange={onClickHandler}>
      {
        categories.map((cat) => (
          <Radio.Button key={cat.id} value={cat.id}>
            {cat.name}
          </Radio.Button>
        ))
      }
    </Radio.Group>
  );
}

BtnGroupCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(
    dishCategoryMould,
  )).isRequired,
  selectedCategory: PropTypes.shape(
    dishCategoryMould,
  ).isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
};

export default BtnGroupCategories;
