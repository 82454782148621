import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { socketServerURL } from '../../api/config/axios';
import {
  areaStates,
  channelsWithDeliveryService,
  orderStateAreas,
  orderStatesCollection,
} from '../../common/utils/constants';
import { selectUser } from '../login/user';
import { fetchOrders, selectCurrentSelectedLocationId, selectOrders } from './ordersSlice';

const socket = io(socketServerURL);

export default function useCashRegisterOrders() {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);
  const currentUser = useSelector(selectUser);
  const currentSelectedLocationId = useSelector(selectCurrentSelectedLocationId);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchOrders(currentUser))
      .finally(() => { setLoading(false); });
    socket.off('orders-dispatched').on('saved-order', async () => {
      console.log('el socket escucha');
      setLoading(true);
      dispatch(fetchOrders(currentUser))
        .finally(() => { setLoading(false); });
    });
    socket.once('orders-dispatched-kitchen', async () => {
      setLoading(true);
      dispatch(fetchOrders(currentUser))
        .finally(() => { setLoading(false); });
    });
  }, []);
  console.log('se renderizo de nuevo');
  console.log('current selected id', currentSelectedLocationId, orders);
  const filteredOrders = useMemo(() => {
    const newFilteredOrders = [];

    const kitchenStates = orderStatesCollection
      .filter((state) => state.areas.indexOf(orderStateAreas.KITCHEN) > -1);

    const kitchenStateObj = areaStates
      .find((state) => state.areas.indexOf(orderStateAreas.KITCHEN) > -1);

    orderStatesCollection.filter((state) => state.areas.indexOf(orderStateAreas.CHECKOUT) > -1)
      .sort((a, b) => a.sequence > b.sequence).forEach((state) => {
        let ordersCollection = orders
          .filter((order) => order.state === state.id
          && order.locationId === currentSelectedLocationId)
          .sort((a, b) => (
            (new Date(a.startDateTime)).getTime()
              - (new Date(b.startDateTime)).getTime()));

        /** * DELIVERY FILTER */
        /** * NOTA: DS significa DELIVERY SERVICE */
        const ordersCollectionWithDS = ordersCollection.filter(
          (o) => channelsWithDeliveryService.map((c) => c.name).indexOf(o.channel) > -1,
        );
        const ordersCollectionWithoutDS = ordersCollection.filter(
          (o) => channelsWithDeliveryService.map((c) => c.name).indexOf(o.channel) < 0,
        );
        ordersCollection = [...ordersCollectionWithDS, ...ordersCollectionWithoutDS];
        /** ***************** */

        const kitchenStateIndex = kitchenStates
          .findIndex((kitchenState) => kitchenState.id === state.id);

        if (kitchenStateIndex > -1) {
          const filteredOrdersByKitchenObjIndex = newFilteredOrders
            .findIndex((obj) => obj.state.id === kitchenStateObj.id);
          console.log(newFilteredOrders, 'newFilteredOrders', ordersCollection);
          if (filteredOrdersByKitchenObjIndex > -1) {
            newFilteredOrders[filteredOrdersByKitchenObjIndex].orders = [
              ...newFilteredOrders[filteredOrdersByKitchenObjIndex].orders,
              ...ordersCollection,
            ].sort((a, b) => (
              (new Date(a.startDateTime)).getTime()
                - (new Date(b.startDateTime)).getTime()));
          } else {
            newFilteredOrders.push({
              state: { ...kitchenStateObj },
              orders: ordersCollection,
            });
          }
        } else {
          newFilteredOrders.push({
            state: { ...state },
            orders: ordersCollection,
          });
        }
      });

    return newFilteredOrders;
  }, [orders, currentSelectedLocationId]);

  return [filteredOrders, isLoading];
}
