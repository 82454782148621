import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import CustomInput from '../../../../common/components/Extras/CustomInput/CustomInput';
import CustomSelect from '../../../../common/components/Extras/CustomSelect/CustomSelect';
import useForm from '../../../../common/hooks/useForm';
import { channels, paymentMethods } from '../../../../common/utils/constants';
import { fetchObjectArraysToCustomSelectOptions, ValidationMould } from '../../../../common/utils/utils';
import { orderData as orderInitialData, orderValidations as orderInitialValidations } from './model';

export default function OrderForm() {
  // eslint-disable-next-line no-unused-vars
  const [order, setOrder] = useState(orderInitialData);
  const orderForm = useForm(order, orderInitialValidations);

  const handleFormControlChange = (evt) => {
    const { name, value } = evt.target;
    const propExist = orderForm.changeDataProperty(name, value);
    if (propExist) {
      const isValid = orderForm.checkValidationByField(name, value);
      orderForm.updatingValidation({
        [name]: new ValidationMould({ isValid }),
      });
    }
  };

  const channelOptions = fetchObjectArraysToCustomSelectOptions(channels, ['id', 'name']);
  const paymentMethodOptions = fetchObjectArraysToCustomSelectOptions(paymentMethods, ['id', 'name']);

  return (
    <>
      <div className="is-size-3 has-text-weight-bold has-text-centered has-text-gray mb-5">
        Agregar Proveedor
      </div>
      <div>
        <div className="columns is-multiline">
          <div className="column is-4">
            <CustomInput
              value={orderForm.data.customerName}
              inputId="customerName"
              inputName="customerName"
              placeholder="Ingresar nombre del proveedor"
              label="Nombre"
              fieldValidations={orderForm.validations.customerName}
              onInputChange={handleFormControlChange}
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-4">
            <CustomInput
              value={orderForm.data.customerDocumentNumber}
              onInputChange={handleFormControlChange}
              inputId="customerDocumentNumber"
              inputName="customerDocumentNumber"
              placeholder="Ingresar documento"
              label="RUC / ID"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-4">
            <CustomInput
              value={orderForm.data.customerAddress}
              onInputChange={handleFormControlChange}
              inputId="customerAddress"
              inputName="customerAddress"
              placeholder="Direccion"
              label="Dirección"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-3">
            <CustomInput
              value={orderForm.data.customerPhone}
              onInputChange={handleFormControlChange}
              inputId="customerPhone"
              inputName="customerPhone"
              placeholder="Teléfono"
              label="Teléfono"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-3">
            <CustomInput
              value={orderForm.data.customerEmail}
              onInputChange={handleFormControlChange}
              inputId="customerEmail"
              inputName="customerEmail"
              placeholder="Correo"
              label="Correo"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-3">
            <CustomSelect
              value={orderForm.data.channel}
              options={channelOptions}
              onInputChange={handleFormControlChange}
              inputId="channel"
              inputName="channel"
              placeholder="Canal"
              label="Canal"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-3">
            <CustomSelect
              value={orderForm.data.paymentMethod}
              options={paymentMethodOptions}
              onInputChange={handleFormControlChange}
              inputId="paymentMethod"
              inputName="paymentMethod"
              placeholder="Método de Pago"
              label="Método de Pago"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-4">
            <CustomInput
              value={orderForm.data.subtotal}
              onInputChange={handleFormControlChange}
              inputId="subtotal"
              inputName="subtotal"
              inputType="number"
              placeholder="Subtotal"
              label="Subtotal"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-4">
            <CustomInput
              value={orderForm.data.delivery}
              onInputChange={handleFormControlChange}
              inputId="delivery"
              inputName="delivery"
              inputType="number"
              placeholder="Delivery"
              label="Delivery"
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-4">
            <CustomInput
              value={orderForm.data.total}
              onInputChange={handleFormControlChange}
              inputId="total"
              inputName="total"
              inputType="number"
              placeholder="Total"
              label="Total"
              leftIcon={faBuilding}
            />
          </div>
        </div>
        <div className="is-flex is-justify-content-center">
          <table className="table anki-table is-hoverable">
            <thead>
              <tr>
                <th>Restaurante</th>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>M. Pago</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>.</td>
                <td>.</td>
                <td>.</td>
                <td>.</td>
              </tr>
              <tr>
                <td>.</td>
                <td>.</td>
                <td>.</td>
                <td>.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
