/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAdmOrders } from '../../../api/orders';
import { setLoading } from '../../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';

const initialState = {
  collection: [],
};

const admOrdersSlice = createSlice({
  name: 'admOrders',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.collection = action.payload;
    },
  },
});

const {
  setOrders,
} = admOrdersSlice.actions;

export const fetchAdmOrders = createAsyncThunk(
  'admOrders/fetchAdmOrders',
  async (optObj, { dispatch, getState }) => {
    const { user } = getState();
    dispatch(setLoading(true));
    dispatch(setOrders([]));
    const { data, success, extras } = await getAdmOrders(user.id, optObj);
    console.log('el adm crequest es', success);
    if (success) {
      dispatch(setOrders(data));
    }
    dispatch(setLoading(false));

    return extras;
  },
);

export const selectAdmOrders = (state) => state.admOrders.collection;

export default admOrdersSlice.reducer;
