import { documentsTypesCollection } from '../common/utils/constants';
import { api } from './config/axios';

const defaultDocType = `${documentsTypesCollection[0].id}:${documentsTypesCollection[0].name}`;
const getOneByDoc = async (docNumber, docType = defaultDocType) => api.get(`/customer/get-one/${docType}/${docNumber}`)
  .then(({ data }) => ({
    ...data,
  }))
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));
const fetchCustomerSunatApi = async (docNumber) => api.get(`/customer/fetch-doc/${docNumber}`)
  .then(({ data }) => ({
    ...data,
  }))
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));

// eslint-disable-next-line import/prefer-default-export
export { getOneByDoc, fetchCustomerSunatApi };
