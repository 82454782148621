import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllAdm } from '../../../api/dish';

const initialState = {
  collection: [],
};

const admDishesSlice = createSlice({
  name: 'admDishes',
  initialState,
  reducers: {
    setDishes(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.collection = action.payload;
    },
  },
});

export const {
  setDishes,
} = admDishesSlice.actions;

export default admDishesSlice.reducer;

export const selectAdmDishesCollection = (state) => state.admDishes.collection;

export const selectAdmDishById = (id) => (state) => {
  const dishIndex = state.admDishes.collection.findIndex((dish) => dish.id === id);
  console.log('este es el dish index', dishIndex, id, state.admDishes.collection);
  const dish = dishIndex > -1 ? state.admDishes.collection[dishIndex] : null;
  return dish;
};

export const getAllDishesByCurrentUser = createAsyncThunk(
  'dishes/getAllDishesByCurrentUser',
  async (optObj, { dispatch, getState }) => {
    // dispatch(setLoading(true));
    const { user } = getState();
    const { success, data, extras } = await getAllAdm(user.id, optObj);
    if (success) {
      dispatch(setDishes(data));
    }
    // dispatch(setLoading(false));

    return extras;
  },
);
