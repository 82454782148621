import { getOptChain } from '../common/utils/utils';
import { api } from './config/axios';

const getOrdersByUser = async (userId, optObj = {}) => {
  const optChain = `?${getOptChain(optObj)}`;

  return api.get(`/order/get-all/${userId}/${optChain}`)
    .then(({ data }) => data)
    .catch((error) => ({
      success: false,
      data: error,
      message: 'Ha habido un error',
    }));
};

const setNextOrderState = async (orderId, nextState) => {
  const jsonData = {
    id: orderId,
    state: `${nextState.id}:${nextState.name}`,
  };
  return api.post('/order/save-state', jsonData)
    .then(({ data }) => data)
    .catch((error) => ({
      success: false,
      data: '',
      message: error.message,
    }));
};

const saveOrder = async (form) => api.post('/order/add', form, {
  headers: {
    'Content-Type': 'application/json',
  },
})
  .then(({ data }) => ({
    success: true,
    data: data.data,
    message: data.message,
  }))
  .catch((error) => (
    {
      success: false,
      data: error,
      message: 'Ha Habido un error',
    }
  ));

const getAdmOrders = async (userId, optObj) => {
  const optChain = `?${getOptChain(optObj)}`;
  return api.get(`/order/get-all-adm/${userId}/${optChain}`)
    .then(({ data }) => data)
    .catch((error) => {
      console.log('Ha habido un error', error);

      return {
        success: false,
        data: '',
        message: 'Ha habido un error al consultar los datos',
      };
    });
};

const getReportByUser = async (userId, optObj) => {
  const optChain = `?${getOptChain(optObj)}`;

  return api.get(`/order/get-report/${userId}/${optChain}`, {
    headers:
              {
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              },
    responseType: 'arraybuffer',
  })
    .then(({ data }) => ({
      success: true,
      data,
      message: 'Consulta exitosa',
    }))
    .catch(() => ({
      success: false,
      data: '',
      message: 'Ha habido un error al extraer el reporte',
    }));
};

export {
  getOrdersByUser,
  setNextOrderState,
  saveOrder,
  getAdmOrders,
  getReportByUser,
};
