import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import DishRow from './Row/DishRow';
import { dishMould } from '../../utils/objectsPropsMould';
import './dishes-list.css';
import FormSelectDishes from '../../../features/dish/FormSelectDishes';

export function DishesList({
  dishes,
}) {
  const [isModalOpen, setModalState] = useState(false);
  const [dishId, setDishId] = useState(0);

  const handleSelectCombo = (currentDishId) => {
    setDishId(currentDishId);
    setModalState(true);
  };

  return (
    <>
      <FormSelectDishes
        currentState={isModalOpen}
        dishId={dishId}
        onStateChange={setModalState}
      />
      <Row gutter={[0, 8]}>
        {dishes.map((dish) => (
          <Col span={24} key={dish.id}>
            <DishRow
              key={dish.id}
              dish={dish}
              onSelectCombo={handleSelectCombo}
            />
          </Col>
        ))}
      </Row>

    </>
  );
}

DishesList.propTypes = {
  dishes: PropTypes.arrayOf(
    PropTypes.shape(dishMould),
  ).isRequired,
};

export default DishesList;
