/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable default-param-last */
// const SET_USER = 'SET_USER';

const initialState = {
  id: '',
  auth0Id: '',
  username: '',
  password: '',
  isValid: false,
  token: '',
};

// export const setUser = (payload) => ({
//   type: SET_USER,
//   payload,
// });

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.id;
      state.auth0Id = action.payload.auth0Id;
      state.username = action.payload.username;
      state.locations = action.payload.locations;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    resetApp: (state) => {
      state = initialState;
    },
  },
});

// export const userReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_USER:
//       return { ...state, ...action.payload };
//     default:
//       return state;
//   }
// };

export const {
  setUser,
  resetApp,
  setToken,
} = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state) => state.user;
export const selectUserToken = (state) => state.user.token;
