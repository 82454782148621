/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPageTitle: '',
  rightSideItems: [],
};

const appLayoutSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    setCurrentPageTitle: (state, action) => {
      state.currentPageTitle = action.payload;
    },
    setExtrasNavbarItems: (state, action) => {
      console.log('setting items', action.payload);
      state.rightSideItems = action.payload;
    },
  },
});

export default appLayoutSlice.reducer;

export const {
  setCurrentPageTitle,
  setExtrasNavbarItems,
} = appLayoutSlice.actions;

// export const selectCurrenPageTitle = (state) => state.appLayout.currentPageTitle;
export const selectLayoutNavbarItems = (state) => state.appLayout;
