/* eslint-disable import/prefer-default-export */
import { api } from './config/axios';

const baseEndpoint = '/service/system';

export const fetchSystemVars = () => api.get(`${baseEndpoint}`)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, por favor contactarse con el administrador',
  }));

export const saveSystemVars = (form) => api.post(`${baseEndpoint}`, form)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: null,
    message: 'Ha habido un error, por favor contactarse con el administrador',
  }));
