import React, { useEffect, useState } from 'react';
import {
  // Button,
  // Col,
  // Row,
  Select,
  Space,
} from 'antd';
import PropTypes from 'prop-types';
import Typography from 'antd/es/typography/Typography';
import { fetchCities } from '../../../api/locationOnRestaurant';
import { notify } from '../../../common/utils/notifications';

export default function CitySelect({
  onLocationChange,
  value,
}) {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);
  const [locationsOpt, setLocationsOpt] = useState([]);
  const [selectedCity, setSelectedCity] = useState(undefined);

  useEffect(() => {
    fetchCities()
      .then(({ data, success }) => {
        console.log('esta es la data quellga', data, selectedCity);
        if (success) {
          setLocationsOpt(
            data.map((cityName) => ({
              key: cityName,
              value: cityName.toLowerCase(),
              label: cityName,
            })),
          );
        }
      });
  }, []);

  useEffect(() => {
    const currentValue = value?.toLowerCase();
    const currentValueIndex = locationsOpt.findIndex((l) => l?.value === currentValue);
    console.log(currentValue, 'valor actual', locationsOpt);
    if (currentValueIndex > -1) {
      setSelectedCity(currentValue);
    }
  }, [value, locationsOpt]);

  const handleCitySelected = async (newVal) => {
    // navigate(`/${selectedCity}`);
    if (newVal) {
      setLoading(true);
      await onLocationChange(newVal);
      setLoading(false);
      return;
    }
    notify({
      text: ' Debe especificar una dirección válida',
      type: 'error',
    });
  };

  return (
    <Space
      direction="horizontal"
    >
      <Typography.Text strong color="rgb(1, 41, 95)">
        Seleccionaste:
      </Typography.Text>
      <Select
        style={{ minWidth: '220px' }}
        value={selectedCity}
        options={locationsOpt}
        onChange={(val) => { setSelectedCity(val); handleCitySelected(val); }}
        loading={isLoading}
        placeholder="Todas las ciudades"
      />

    </Space>
  );
}
CitySelect.defaultProps = {
  value: undefined,
  onLocationChange: () => {},
};

CitySelect.propTypes = {
  value: PropTypes.string,
  onLocationChange: PropTypes.func,
};
