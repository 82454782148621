import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { validationMouldPropTypes } from '../../../utils/utils';

function CustomInput({
  value,
  inputId,
  inputName,
  inputType,
  label,
  isDisabled,
  placeholder,
  fieldValidations,
  onInputChange,
  autoComplete,
  isSearch,
  style,
  leftIcon,
  min,
  className,
  size,
}) {
  // const [currentValue, setCurrentValue] = useState(value);
  // const [currentFieldValidations, setCurrentFieldValidations] = useState(fieldValidations);
  const [currentValue, setCurrentValue] = useState('');
  const [currentFieldValidations, setCurrentFieldValidations] = useState(null);

  useEffect(() => {
    setCurrentValue(value);
    if (fieldValidations) {
      setCurrentFieldValidations({ ...fieldValidations });
    }
  }, []);

  useEffect(() => {
    console.log('setea el valor en el input custom', value);
    setCurrentValue(value);
  }, [value]);
  useEffect(() => {
    console.log('detecta cambio en la validacion', fieldValidations, { ...fieldValidations }, currentFieldValidations);
    if (fieldValidations) {
      setCurrentFieldValidations({ ...fieldValidations });
    }
  }, [fieldValidations]);

  useEffect(() => {
    console.log('current field validations ha cambiado', currentFieldValidations);
  }, [currentFieldValidations]);

  return (
    <div className={`field ${className}`}>
      {
        label !== ''
        && (
          <label htmlFor={inputId} className="label">
            {label}
            {' '}
            {
              currentFieldValidations
              && (
                currentFieldValidations.isRequired
                && (
                  <span className="has-text-danger">*</span>
                ))
            }
            {' '}
          </label>
        )
      }
      <div className={`control has-icons-right ${leftIcon && 'has-icons-left'} `}>
        {
          inputType === 'textarea'
            ? (
              <textarea
                id={inputId}
                name={inputName}
                className="textarea"
                placeholder={placeholder}
                value={currentValue}
                onChange={onInputChange}
                disabled={isDisabled}
                autoComplete={autoComplete ? 'on' : 'off'}
                style={style || { ...style }}
              />
            ) : (
              <input
                id={inputId}
                name={inputName}
                className={`input is-${size}`}
                type={inputType}
                placeholder={placeholder}
                value={currentValue}
                min={inputType === 'number' ? min : ''}
                onChange={onInputChange}
                disabled={isDisabled}
                autoComplete={autoComplete ? 'on' : 'off'}
                style={style || { ...style }}
              />
            )
}
        {
          leftIcon
          && (
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={leftIcon} />
            </span>
          )
        }
        {
          currentFieldValidations
          && (
            <span className="icon is-small is-right">
              <FontAwesomeIcon className={`${currentFieldValidations.isValid ? 'has-text-success' : 'has-text-danger'}`} icon={faCheck} />
            </span>
          )
        }
        {
          isSearch
          && (
            <span className="icon is-small is-right">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          )
        }
      </div>
      {currentFieldValidations
        && (
          !currentFieldValidations.isValid
          && (
            <div className="help has-text-danger">
              {currentFieldValidations.errorMessage}
            </div>
          ))}
    </div>
  );
}

CustomInput.defaultProps = {
  inputId: '',
  inputName: '',
  inputType: 'text',
  placeholder: '',
  label: '',
  isDisabled: false,
  fieldValidations: null,
  autoComplete: false,
  isSearch: false,
  style: null,
  leftIcon: null,
  min: '0',
  className: '',
  size: 'normal',
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  fieldValidations: PropTypes.shape({ ...validationMouldPropTypes }),
  autoComplete: PropTypes.bool,
  isSearch: PropTypes.bool,
  style: PropTypes.shape({}),
  leftIcon: PropTypes.shape({}),
  min: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default CustomInput;
