import React from 'react';
import { Route, Routes } from 'react-router';
import Dashboard from '../../features/dashboard/Dashboard';
import AdminPage from '../../features/admin/AdminPage';
import IngredientsPage from '../../features/admin/ingredients/Page/IngredientsPage';
import IngredientForm from '../../features/admin/ingredients/Form/IngredientForm';
import AdmDishesPage from '../../features/admin/dish/Page/AdmDishesPage';
import DishForm from '../../features/admin/dish/Form/DishForm';
import AdmSuppliersPage from '../../features/admin/supplier/Page/AdmSuppliersPage';
import SupplierForm from '../../features/admin/supplier/Form/SupplierForm';
import AdmOrdersPage from '../../features/admin/order/Page/AdmOrdersPage';
import OrderForm from '../../features/admin/order/Form/OrderForm';
import RestaurantsLocationsPage from '../../features/restaurantLocation/RestaurantsLocationsPage';
import DishesPage from '../../features/dish/DishesPage';
import SingleDishPage from '../../features/dish/SingleDishPage';
import KitchenOrdersPage from '../../features/order/KitchenOrdersPage';
import CashRegisterOrdersPage from '../../features/order/CashRegisterOrdersPage';
import AdmMenuPage from '../../features/admin/menu/Page/AdmMenuPage';
import FeIssuerPage from '../../features/admin/feIssuer/Page/FeIssuerPage';
import AdmCouponPage from '../../features/admin/coupons/Page/AdmCouponPage';
import AdmSystemVarPage from '../../features/admin/systemVars/AdmSystemVarPage';
import StoresPage from '../../features/admin/stores/Page/StoresPage';

export default function AppLayoutRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/admin/ingredients" element={<IngredientsPage />} />
      <Route path="/admin/ingredients/add" element={<IngredientForm />} />
      <Route path="/admin/dishes" element={<AdmDishesPage />} />
      <Route path="/admin/menus" element={<AdmMenuPage />} />
      <Route path="/admin/dishes/add" element={<DishForm />} />
      <Route path="/admin/dishes/edit/:id" element={<DishForm />} />
      <Route path="/admin/suppliers" element={<AdmSuppliersPage />} />
      <Route path="/admin/suppliers/add" element={<SupplierForm />} />
      <Route path="/admin/coupons" element={<AdmCouponPage />} />
      <Route path="/admin/system-vars" element={<AdmSystemVarPage />} />
      <Route path="/admin/orders" element={<AdmOrdersPage />} />
      <Route path="/admin/orders/add" element={<OrderForm />} />
      <Route path="/admin/stores" element={<StoresPage />} />
      {/** * FACTURACIÓN ELECTRÓNICA */}
      <Route path="/fe/emisores" element={<FeIssuerPage />} />
      {/** <Route exact path="restaurants" element={<RestaurantPage />} /> */}
      <Route exact path="/restaurants" element={<RestaurantsLocationsPage />} />
      <Route exact path="/restaurants/:id" element={<DishesPage />} />
      <Route exact path="/restaurants/:id/:dishId" element={<SingleDishPage />} />
      <Route exact path="/kitchen" element={<KitchenOrdersPage />} />
      <Route exact path="/cash-register-orders" element={<CashRegisterOrdersPage />} />
    </Routes>
  );
}
