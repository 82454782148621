import { regexValidations, ValidationMould } from '../../../../common/utils/utils';

const supplierData = {
  name: '',
  documentNumber: '',
  address: '',
};

const supplierValidations = {
  name: new ValidationMould({
    isRequired: true,
  }),
  documentNumber: new ValidationMould({
    isRequired: true,
    regex: regexValidations.onlyNumbers,
  }),
  address: new ValidationMould({
    isRequired: false,
  }),
};

export { supplierData, supplierValidations };
