/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Divider,
  Form,
  Modal,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { AppstoreAddOutlined, ControlOutlined } from '@ant-design/icons';
import { notify } from '../../../../common/utils/notifications';
import { addToCart, setState as setCartState } from '../../../../common/components/ShoppingCartMenu/shoppingCartSlice';
import { dishMould } from '../../../../common/utils/objectsPropsMould';

const { Text, Title } = Typography;

export default function FormSelectDishes({
  dish,
  currentState,
  onStateChange,
}) {
  const [isOpen, setModalState] = useState(false);
  const [currentDish, setCurrentDish] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [selectedDishes, setSelectedDishes] = useState([]);
  const namePrefix = 'plato-dish';

  useEffect(() => {
    if (currentState) {
      form.resetFields();
    }
    setModalState(currentState);
  }, [currentState]);

  useEffect(() => {
    setCurrentDish(dish);
  }, [dish]);
  useEffect(() => {
    form.validateFields();
  }, [form]);

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        const idsArray = [];
        Object.keys(values).forEach((prop) => {
          idsArray.push(parseInt(prop.split(`${namePrefix}-`).pop(), 10));
        });
        const valuesArray = [];

        idsArray.forEach((id) => {
          valuesArray.push({
            ...values[`${namePrefix}-${id}`],
          });
        });
        console.log(valuesArray, 'valores de array', currentDish);
        dispatch(addToCart({ ...currentDish, dishes: valuesArray, quantity: 1 }));
        setTimeout(() => {
          dispatch(setCartState(true));
          /* notify({
            text: 'Se ha agregado el plato al carrito',
            type: 'success',
            autoClose: 500,
          }); */
        }, 1000);
        // console.log('valores', valuesArray);
        setTimeout(() => {
          // form.resetFields();
          onStateChange?.(false);
        }, 100);
      });
  };

  const handleCancel = () => {
    // form.resetFields();
    onStateChange?.(false);
  };
  return (
    <Modal
      open={isOpen}
      cancelButtonProps={{
        type: 'dashed',
        danger: true,
      }}
      okButtonProps={{
        type: 'primary',
      }}
      onCancel={handleCancel}
      cancelText="Cancelar"
      onOk={handleOk}
      title={(
        <Title level={4} className="mt-0">
          <AppstoreAddOutlined />
          {' '}
          {`${currentDish?.name}`}
        </Title>
      )}
    >
      <Form
        form={form}
        initialValues={{}}
        preserve={false}
        scrollToFirstError
        onFieldsChange={(_, allFields) => {
          form.setFieldsValue(allFields);
        }}
      >
        {
          currentDish?.comboDetail?.map((memberDish) => (
            <React.StrictMode
              key={memberDish.id}
            >
              <Row>
                <Text style={{ marginBottom: '15px' }} strong>
                  {memberDish.title}
                  {' '}
                  <Tag color="default">Obligatorio</Tag>
                </Text>
              </Row>
              <Form.Item
                key={memberDish.id}
                name={`${namePrefix}-${memberDish.id}`}
                rules={[
                  {
                    required: true,
                    message: 'Selecciona una opción',
                  },
                ]}
              >
                <Radio.Group size="middle">
                  <Space direction="vertical">
                    {memberDish?.dishes.map((d) => (
                      <Radio
                        key={d.id}
                        value={d}
                      >
                        {d.title}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>

              </Form.Item>
              {
                currentDish.comboDetail.findIndex(
                  (e) => e.id === memberDish.id,
                ) < (currentDish.comboDetail.lenght - 1)
                && <Divider />
              }
            </React.StrictMode>

          ))
        }
      </Form>

    </Modal>

  );
}

FormSelectDishes.defaultProps = {
  dish: null,
  currentState: false,
  onStateChange: null,
};

FormSelectDishes.propTypes = {
  dish: PropTypes.shape(dishMould),
  currentState: PropTypes.bool,
  onStateChange: PropTypes.func,
};
