import { useState } from 'react';

export default function useSearch() {
  const [search, setSearch] = useState('');

  const handleSearchInputChange = (evt) => {
    const { value } = evt.target;
    setSearch(value);
  };

  return {
    value: search,
    handleSearchInputChange,
  };
}
