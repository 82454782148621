/* eslint-disable import/prefer-default-export */
import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../features/login/user';
import { selectCurrentSelectedLocationId, setCurrentLocationId } from '../../../features/order/ordersSlice';
import './layout-header-components.css';

function LocationsSelect() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const currentSelectedLocationId = useSelector(selectCurrentSelectedLocationId);
  console.log('seteamos', currentSelectedLocationId);
  useEffect(() => {
    if (currentUser?.locations.length > 0 && currentSelectedLocationId === 0) {
      dispatch(setCurrentLocationId(currentUser.locations[0].id));
    }
  }, []);

  const onChange = (value) => {
    console.log('valur selected', value, currentUser);
    dispatch(setCurrentLocationId(value));
  };

  return (
    <Select
      value={currentSelectedLocationId}
      className="locations-select"
      onChange={onChange}
      options={currentUser.locations.map((l) => ({
        value: l.id,
        label: l.name,
      }))}
    />
  );
}
const layoutHeaderComponents = { LocationsSelect };
export default layoutHeaderComponents;
