/* eslint-disable import/prefer-default-export */
export const claimModel = {
  locationOnRestaurantId: null,
  businessName: '',
  businessDoc: '',
  businessAdress: '',
  customerFullName: '',
  customerAdress: '',
  customerDocType: 1,
  customerDocNumber: '',
  customerEmail: '',
  customerPhoneNumber: '',
  customerIsYounger: false,
  wellHiredType: '', // SERVICE OR PRODUCT
  amountSpent: 0.00,
  orderDescription: '',
  orderDate: null,
  claimType: '', // CLAIM or COMPLAINT
  claimDescription: '',
  orderClaimDescription: '',
};

export const wellHiredTypeOpts = [
  {
    value: 'PRODUCT',
    label: 'Producto',
    key: 'PRODUCT',
  },
  {
    value: 'SERVICE',
    label: 'Servicio',
    key: 'SERVICE',
  },
];

export const claimTypesOpt = [
  {
    label: 'Reclamo',
    value: 'CLAIM',
  },
  {
    label: 'Queja',
    value: 'Complaint',
  },
];
