import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SupplierMould } from '../../../../../common/utils/objectsPropsMould';
import AdmSupplierRow from './AdmSupplierRow';
import CustomHeaderRow from '../../../../../common/components/Extras/CustomHeaderRow/CustomHeaderRow';
import { colsOrder } from '../../../../../common/utils/constants';
import Paginator from '../../../../../common/components/Extras/Paginator/Paginator';

export default function AdmSuppliersList({ suppliers }) {
  const [columns, setColumns] = useState([
    {
      name: 'name',
      label: 'Nombre',
      order: colsOrder.DESC,
    },
    {
      name: 'address',
      label: 'Dirección',
    },
    {
      name: 'contact',
      label: 'Contacto',
    },
    {
      name: 'phone',
      label: 'Numero',
    },
    {
      name: 'email',
      label: 'Mail',
    },
    {
      name: 'Actions',
      label: 'Acciones',
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <table className="table anki-table">
        <thead>
          <CustomHeaderRow
            columns={columns}
            onColumnsChange={setColumns}
          />
        </thead>
        <tbody>
          {
            suppliers.map((supplier) => (
              <AdmSupplierRow supplier={supplier} key={supplier.id} />
            ))
          }
        </tbody>
      </table>
      <Paginator
        rowCount={1000}
        rowsPerPage={10}
        currentPage={currentPage}
        onPageChange={(newPage) => { setCurrentPage(newPage); }}
      />
    </>
  );
}

AdmSuppliersList.defaultProps = {
  suppliers: [],
};

AdmSuppliersList.propTypes = {
  suppliers: PropTypes.arrayOf(PropTypes.shape(SupplierMould)),
};
