import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import CustomInput from '../../../../common/components/Extras/CustomInput/CustomInput';
import useForm from '../../../../common/hooks/useForm';
import { supplierData as supplierInitialData, supplierValidations as supplierInitialDataValidations } from './model';

export default function SupplierForm() {
  // eslint-disable-next-line no-unused-vars
  const [supplier, setSupplier] = useState(supplierInitialData);
  const supplierForm = useForm(supplier, supplierInitialDataValidations);

  return (
    <>
      <div className="is-size-4 has-text-weight-bold has-text-centered has-text-gray mb-5">
        Agregar Proveedor
      </div>
      <div>
        <div className="columns is-multiline">
          <div className="column is-4">
            <CustomInput
              value={supplierForm.data.name}
              inputId="name"
              inputName="name"
              placeholder="Ingresar nombre del proveedor"
              label="Nombre"
              fieldValidations={supplierForm.validations.name}
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-4">
            <CustomInput
              value={supplierForm.data.documentNumber}
              inputId="documentNumber"
              inputName="documentNumber"
              placeholder="Ingresar documento"
              label="RUC / ID"
              fieldValidations={supplierForm.validations.documentNumber}
              leftIcon={faBuilding}
            />
          </div>
          <div className="column is-4">
            <CustomInput
              value={supplierForm.data.address}
              inputId="address"
              inputName="address"
              placeholder="Direccion"
              label="Dirección"
              fieldValidations={supplierForm.validations.address}
              leftIcon={faBuilding}
            />
          </div>
        </div>
      </div>
    </>
  );
}
