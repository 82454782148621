import { io } from 'socket.io-client';
import React, { useEffect, useState } from 'react';
import {
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import {
  Layout,
  Row,
  Col,
  theme,
} from 'antd';
import {
  socketServerURL,
  api,
  useApi,
} from '../../api/config/axios';
import LeftNavbar from '../../common/components/LeftNavbar/LeftNavbar';
// import RestaurantPage from '../../features/restaurant/RestaurantPage';
import GlobalSpinner from '../../common/components/Spinners/GlobalSpinner/GlobalSpinner';
import ShoppingCartMenu from '../../common/components/ShoppingCartMenu/ShoppingCartMenu';
import BtnGoBack from '../../common/components/BtnGoBack/BtnGoBack';
import { selectGlobalSpinnerState } from '../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';

import { selectLayoutNavbarItems, setCurrentPageTitle, setExtrasNavbarItems } from './appLayoutSlice';
import layoutHeaderComponents from '../../common/components/LayoutHeaderComponents/LayoutHeaderComponents';
import AppLayoutRoutes from './AppLayoutRoutes';
import { notify } from '../../common/utils/notifications';
import soundfile from '../../assets/music/beep.mp3';

const socket = io(socketServerURL);

const {
  Header,
  Content,
  Footer,
} = Layout;

const { useToken } = theme;

function AppLayout() {
  const location = useLocation();
  const isLoading = useSelector(selectGlobalSpinnerState);
  const dispatch = useDispatch();
  const { currentPageTitle, rightSideItems } = useSelector(selectLayoutNavbarItems);
  const [isLoadingAuth, setLoadingAuth] = useState(false);

  console.log('este es el title', currentPageTitle, rightSideItems, rightSideItems.map((i) => layoutHeaderComponents[i]));
  const { token } = useToken();
  const [isMenuFixed, setIsMenuFixed] = useState(false);

  const apiMiddleware = useApi(api);

  useEffect(() => {
    setLoadingAuth(true);
    apiMiddleware.setAxiosInstanceInterceptor()
      .then(() => (apiMiddleware.fetchCurrentLoggedUser()))
      .finally(() => {
        setLoadingAuth(false);
      });

    socket.off('orders-dispatched').on('orders-dispatched', async () => {
      notify({ text: 'Ha llegado un pedido, revísalo!!', type: 'success', autoClose: 4000 });

      const btn = document.createElement('button');
      btn.addEventListener('click', () => {
        const audio = new Audio(soundfile);
        audio.addEventListener('ended', (e) => {
          e.currentTime = 0;
          e.play();
        }, false);
        audio.play();
      });
      btn.click();
      btn.remove();
    });
    socket.off('orders-dispatched-kitchen').on('orders-dispatched-kitchen', async () => {
      notify({ text: 'NUEVO PEDIDO EN COCINA', type: 'success', autoClose: 4000 });

      const btn = document.createElement('button');
      btn.addEventListener('click', () => {
        const audio = new Audio(soundfile);
        audio.addEventListener('ended', (e) => {
          e.currentTime = 0;
          e.play();
        }, false);
        audio.play();
      });
      btn.click();
      btn.remove();
    });
  }, []);

  useEffect(() => {
    dispatch(setCurrentPageTitle(''));
    dispatch(setExtrasNavbarItems([]));
  }, [location]);

  if (isLoadingAuth) {
    return (
      <GlobalSpinner isLoading>
        <span>Confirmando usuario</span>
      </GlobalSpinner>
    );
  }

  return (
    <Layout className="main-layout" hasSider>
      <LeftNavbar onFixed={setIsMenuFixed} />

      <Layout className="site-layout" style={{ marginLeft: isMenuFixed ? 80 : 0, paddingBottom: '40px', background: token.lightColor }}>
        <Header
          style={{
            background: token.darkColor,
            paddingLeft: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          title={currentPageTitle}
        >
          {
            (location.pathname.includes('/app/restaurants/') || location.pathname.includes('/app/admin/'))
            && <BtnGoBack />
          }
          <h3
            className="layout-title"
            style={{ paddingLeft: '50px' }}
          >
            {currentPageTitle}
          </h3>
          <div>
            {
              rightSideItems
              // eslint-disable-next-line max-len, react/no-array-index-key
              && (rightSideItems.map((i, index) => (React.createElement(layoutHeaderComponents[i], { key: index }))))
            }
          </div>
          {
            location.pathname.includes('/app/restaurants')
            && <ShoppingCartMenu />
          }
        </Header>
        <Content
          className="main-layout__main-container"
        >
          <GlobalSpinner isLoading={isLoading} />
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <AppLayoutRoutes />
            </Col>
          </Row>
        </Content>
        <Footer className="main-layout__footer">
          Anki ©2023 Created by
          {' '}
          <b>ANCANA</b>
        </Footer>
      </Layout>
      <ToastContainer />
    </Layout>
  );
}

export default AppLayout;
