import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getMenuWeb } from '../../../api/web';
// import { notify } from '../../../common/utils/notifications';
import MenuList from './MenuList';
import FormSelectDishes from './FormSelectComboDishes/FormSelectDishes';
import RestaurantHeader from './RestaurantHeader';
import { setCurrentCity, setWebLoading, setLocation } from '../../../app/Layouts/WebLayout/webSlice';
import { setWebPhone } from '../../../common/components/ShoppingCartMenu/shoppingCartSlice';

export default function WebRestaurantLocation() {
  const dispatch = useDispatch();
  const { restaurantSlug, locationSlug } = useParams();
  const [restaurant, setRestaurant] = useState({});
  const [categories, setCategories] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingList, setListState] = useState(false);
  const [currentDishesByCategory, setCurrentDishesByCategory] = useState([]);

  /** SELECT COMBO */
  const [isModalOpen, setModalState] = useState(false);
  const [currentDishObj, setCurrentDishObj] = useState(0);

  const handleSelectCombo = (dishObj) => {
    setCurrentDishObj(dishObj);
    setModalState(true);
  };

  /** ************** */

  useEffect(() => {
    dispatch(setWebLoading(true));
    getMenuWeb(restaurantSlug, locationSlug)
      .then(({ data, success }) => {
        if (success) {
          if (data.categories.length > 0 && data.dishes.length > 0 && data.restaurant) {
            setCategories(data.categories);
            setDishes(data.dishes);
            setRestaurant({
              ...data.restaurant,
              restaurantName: `${data.restaurant.name} - ${data.location.name}`,
              schedule: data.scheduleText,
            });
            if (data.isAvailableOnWeb) {
              setSchedule(data.schedule);
            }
            dispatch(setWebPhone(data.location.webPhone));
            dispatch(setCurrentCity(data.location.city));
            dispatch(setLocation(data.location));
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setWebLoading(false));
        }, 2000);
      });
  }, []);

  useEffect(() => {
    const currentDayOfTheWeek = new Date().getDay();
    const currentHour = new Date().getHours();
    // const currentMinutes = new Date().getMinutes();

    if (schedule && schedule.length > 0) {
      schedule.forEach((s) => {
        if (
          currentHour >= s.startHour
          && currentHour <= s.endHour
          && currentDayOfTheWeek === s.dayOfWeek
        ) {
          setIsOpen(true);
        }
      });
    }
  }, [schedule]);

  const handleCategoryChange = (categoryId) => {
    setListState(true);
    setCurrentDishesByCategory(dishes.filter((d) => d.categoryId === categoryId));
    setTimeout(() => {
      setListState(false);
    }, 500);
  };

  return (
    <>
      <RestaurantHeader
        restaurant={restaurant}
        categories={categories}
        onCategoryChange={handleCategoryChange}
        isOpen={isOpen}
      />
      <FormSelectDishes
        currentState={isModalOpen}
        dish={currentDishObj}
        onStateChange={setModalState}
      />
      {
        isLoadingList
          ? (
            <div style={{
              height: '250px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <Spin
                indicator={(
                  <LoadingOutlined
                    style={{
                      fontSize: 63,
                    }}
                    spin
                  />
)}
              />
            </div>
          )
          : (
            <MenuList
              dishes={currentDishesByCategory}
              isOpen={isOpen}
              onSelectCombo={handleSelectCombo}
            />

          )
      }
    </>
  );
}
