/* eslint-disable no-param-reassign */
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
// import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../features/login/user';
// import { fetchUserByAuth0 } from '../user';
// import dotenv from 'dotenv';
// import { currentToken } from '../../common/utils/auth2';
// require('dotenv').config({ path: `${__dirname}../../../.env` });

const enviroment = process.env.NODE_ENV;

const api = axios.create({
  baseURL: (enviroment && enviroment === 'development') ? process.env.REACT_APP_DEVELOMENT_PATH_BACKEND : process.env.REACT_APP_PRODUCTION_PATH_BACKEND,
});

export const webApi = axios.create({
  baseURL: (enviroment && enviroment === 'development') ? process.env.REACT_APP_DEVELOMENT_PATH_BACKEND : process.env.REACT_APP_PRODUCTION_PATH_BACKEND,
});
// api.interceptors.request.use((req) => req);
/**
   * const options = {
    method: 'POST',
    url: 'http://dev-qdhlfiau2001arpm.us.auth0.com/oauth/token',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: new URLSearchParams({
      grant_type: 'read:restaurants',
      client_id: '0pncTT3KrlpjIARUoAcdbSsKH4BhzJYe',
      client_secret: '0pncTT3KrlpjIARUoAcdbSsKH4BhzJYe',
      audience: 'https://anki.la',
    }),
  };
  * */

// console.log('token guardado en utils', currentToken);

// if (currentToken.value) {
// eslint-disable-next-line no-param-reassign
// req.headers.Authorization = `Bearer ${currentToken.value}`;
// }

/** axios.request(options).then(({ data }) => {
     if (data.access_token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${data.access_token}`;
    }
  });
  */
/**
   * console.log('entrando al interceptor');
  const token = useSelector(selectUserToken);
  console.log('ingresando', token);
  if (token && token !== '') {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  */

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('error 401');
      // window.location.reload();
      // window.location.href = window.location.origin;
    }
  },
);

const useApi = (axiosInstance) => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  // const currentToken = useRef(null);
  // const currentAxiosInstance = useRef(axiosInstance);

  // eslint-disable-next-line no-unused-vars
  const setHeaderToken = (token, tokenPrefix = 'Bearer') => {
    axiosInstance.defaults.headers.Authorization = `${tokenPrefix} ${token}`;
    console.log('este eeee', axiosInstance.defaults.headers.Authorization);
  };

  // eslint-disable-next-line no-unused-vars
  const removeHeaderToken = () => {
    delete axiosInstance.defaults.headers.Authorization;
  };

  const fetchCurrentLoggedUser = async () => {
    if (Object.keys(user).length > 0) {
      const auth0Id = user.sub.split('|')[1];
      const userData = {
        name: user.name,
        email: user.email,
      };
      const { success, data } = await axiosInstance.post(`/user/fetch-auth0-user/${auth0Id}`, userData)
        // headers: {
        //  Authorization: `Bearer ${currentToken.current}`,
        // },
        .then((res) => (res.data))
        .catch(() => ({
          success: false,
          data: 'Error',
          message: 'Ha habido un error a consultar los datos',
        }));

      // fetchUserByAuth0(userData, authId);
      if (success) {
        dispatch(setUser(data));
      }
    }
  };

  const setAxiosInstanceInterceptor = async (tokenPrefix = 'Bearer') => {
    axiosInstance.interceptors.request.use(async (req) => {
      await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_AUDIENCE,
        },
      })
        .then((token) => {
          if (token) {
            // removeHeaderToken();
            // currentToken.current = token;
            // setHeaderToken(token);
            req.headers.Authorization = `${tokenPrefix} ${token}`;
          }
        })
        .catch((error) => {
          console.log('error al consultar token', error);
        });
      // console.log('este es el token devuelto', token);

      return req;
    });
    // await fetchCurrentLoggedUser();
  };

  return {
    setAxiosInstanceInterceptor,
    fetchCurrentLoggedUser,
  };
};

export { api, useApi };

export const socketServerURL = (enviroment && enviroment === 'development') ? process.env.REACT_APP_DEVELOMENT_PATH_BACKEND : process.env.REACT_APP_PRODUCTION_PATH_BACKEND;
export const filesRoot = (enviroment && enviroment === 'development') ? process.env.REACT_APP_DEVELOMENT_PATH_FILES : process.env.REACT_APP_PRODUCTION_PATH_FILES;
