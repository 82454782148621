import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { loadIngredients } from '../../../../api/ingredient';
import GlobalSpinner from '../../../../common/components/Spinners/GlobalSpinner/GlobalSpinner';
import { loadAddOns } from '../../../../api/addOn';

export default function LoadFileForm({ state, changeState }) {
  const [isOpen, setState] = useState(state);
  const [isLoading, setIsLoading] = useState(false);
  const modalBackground = useRef(null);

  const handleStateChange = (val) => {
    if (changeState) {
      changeState(val);
    }
  };

  useEffect(() => {
    let cleanupFunction = null;
    const handleOnClick = () => {
      handleStateChange(false);
    };
    if (modalBackground && modalBackground.current) {
      modalBackground.current.addEventListener('click', handleOnClick, false);

      cleanupFunction = () => {
        if (modalBackground.current) {
          modalBackground.current.removeEventListener('click', handleOnClick, false);
        }
      };
    }

    return cleanupFunction;
  }, []);

  useEffect(() => {
    setState(state);
  }, [state]);

  const fileRef = useRef();
  const addOnFileRef = useRef();
  const sendDishes = async (evt) => {
    evt.preventDefault();
    const form = new FormData();
    console.log('este es el archivo', fileRef.current.files[0], form);
    form.append('file', fileRef.current.files[0]);
    setIsLoading(true);
    const result = await loadIngredients(form)
      .finally(() => { setIsLoading(false); });
    console.log(result);
  };
  const sendAddOns = async (evt) => {
    evt.preventDefault();
    const form = new FormData();
    console.log('este es el archivo', addOnFileRef.current.files[0], form);
    form.append('file', addOnFileRef.current.files[0]);
    setIsLoading(true);
    const result = await loadAddOns(form)
      .finally(() => { setIsLoading(false); });
    console.log(result);
  };

  return (

    <div className={`modal ${isOpen && 'is-active'}`}>
      <GlobalSpinner isLoading={isLoading}>
        <span> Cargando Ingredientes</span>
      </GlobalSpinner>
      <div className="modal-background" ref={modalBackground} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Modal title</p>
          <button type="button" className="delete" aria-label="close" onClick={() => { changeState(false); }} />
        </header>
        <section className="modal-card-body">
          <div className="columns">

            <form onSubmit={sendDishes} encType="multipart/form-data">
              <div className="file">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume" ref={fileRef} />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                    <span className="file-label">
                      Choose a file…
                    </span>
                  </span>
                </label>
              </div>

              <button type="submit">
                Prueba
              </button>
            </form>
            <form onSubmit={sendAddOns} encType="multipart/form-data">
              <div className="file">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume" ref={addOnFileRef} />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                    <span className="file-label">
                      Choose a file…
                    </span>
                  </span>
                </label>
              </div>

              <button type="submit">
                Prueba addon
              </button>
            </form>
          </div>

        </section>
        <footer className="modal-card-foot">
          <button type="button" onClick={() => { handleStateChange(false); }} className="button">Cancel</button>
        </footer>
      </div>
    </div>
  );
}

LoadFileForm.defaultProps = {
  state: false,
  changeState: null,
};
LoadFileForm.propTypes = {
  state: PropTypes.bool,
  changeState: PropTypes.func,
};
