import React, { useEffect, useState } from 'react';
import { Button, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AdmCouponList from '../AdmCouponsList/AdmCouponsList';
import Paginator from '../../../../common/components/Extras/Paginator/Paginator';
import { fetchCoupons } from '../../../../api/coupon';
import CouponFormModal from '../CouponFormModal/CouponFormModal';

export default function AdmCouponPage() {
  const [isDataLoading, setDataLoading] = useState(false);
  /** **** PAG */
  const [page, setCurrentPage] = useState(1);
  const [paginationProps, setPaginatioProps] = useState({
    rowCount: 0,
    rowsPerPage: 10,
  });
  /** COUPONS DATA */
  const [coupons, setCoupons] = useState([]);
  /** FORM DATA */
  const [isFormOpen, setFormState] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  /** ********* */
  const getCoupons = (optObj = {}) => {
    setDataLoading(true);
    fetchCoupons(optObj)
      .then(({ success, data, extras }) => {
        const { rowCount, currentPage, rowsPerPage } = extras;
        setCurrentPage(currentPage);
        console.log(data);
        if (success) {
          setCoupons(data);
        }
        setPaginatioProps({
          ...paginationProps,
          rowCount: rowCount || 0,
          rowsPerPage,
        });
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCoupons();
  }, []);

  const handleFormCancel = () => {
    setSelectedMenu(null);
    setFormState(false);
  };

  const handleFormSubmit = () => {
    getCoupons();
    setFormState(false);
  };

  const openFormModal = (data = undefined) => {
    setSelectedMenu(data);
    setFormState(true);
  };

  return (
    <>
      <CouponFormModal
        isOpen={isFormOpen}
        menuData={selectedMenu}
        onCancel={handleFormCancel}
        onSubmit={handleFormSubmit}
      />
      <Row justify="space-between">
        <Space />
        <Space>
          <Button
            onClick={() => openFormModal()}
            icon={<PlusOutlined />}
          />
        </Space>
      </Row>
      <AdmCouponList
        coupons={coupons}
        isLoading={isDataLoading}
        onSelectedMenu={openFormModal}
      />
      <Paginator
        rowCount={paginationProps.rowCount}
        rowsPerPage={paginationProps.rowsPerPage}
        currentPage={page}
        onPageChange={(newPage, pageSize) => {
          getCoupons({ page: newPage, limit: pageSize });
        }}
      />
    </>
  );
}
