import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { filesRoot } from '../../api/config/axios';
import CustomImg from '../../common/components/Extras/CustomImg/CustomImg';

const { Meta } = Card;

function RestaurantCard({ restaurantLocation: { id, name, img } }) {
  return (
    <Card
      hoverable
      cover={
                (
                  <Link to={`${id}`} style={{ height: 'inherit' }}>
                    <CustomImg
                      height="230px"
                      url={(img && img !== '') ? `${filesRoot}/${img}` : 'https://bulma.io/images/placeholders/256x256.png'}
                    />
                  </Link>
                )
            }
    >
      <Meta title={name} style={{ textAlign: 'center' }} />
    </Card>
  );
}

RestaurantCard.propTypes = {
  restaurantLocation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    img: PropTypes.string,
  }).isRequired,
};

export default RestaurantCard;
