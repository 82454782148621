import { api } from './config/axios';

const getOne = (restaurantId, userId) => api.get(`/restaurant/get-all-by-restaurant/${restaurantId}/${userId}`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));

const getAllByUser = (userId) => api.get(`/restaurant/list/${userId}`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));

export { getOne, getAllByUser };
