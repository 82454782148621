import React, { useEffect, useState } from 'react';
import RestaurantaOnLocationList from '../RestaurantOnLocationList.js/RestaurantOnLocationList';
import Paginator from '../../../../common/components/Extras/Paginator/Paginator';
// import { fetchCoupons } from '../../../../api/coupon';
// import CouponFormModal from '../RestaurantOnLocationModal/RestaurantOnLocationModal';
import { fetchAllAdm as fetchStores } from '../../../../api/locationOnRestaurant';

export default function StoresPage() {
  const [isDataLoading, setDataLoading] = useState(false);
  /** **** PAG */
  const [page, setCurrentPage] = useState(1);
  const [paginationProps, setPaginatioProps] = useState({
    rowCount: 0,
    rowsPerPage: 10,
  });
  /** COUPONS DATA */
  const [stores, setStores] = useState([]);
  /** FORM DATA */
  // eslint-disable-next-line no-unused-vars
  const [isFormOpen, setFormState] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedMenu, setSelectedMenu] = useState(null);
  /** ********* */
  const getStores = (optObj = {}) => {
    setDataLoading(true);
    fetchStores(optObj)
      .then(({ success, data, extras }) => {
        const { rowCount, currentPage, rowsPerPage } = extras;
        setCurrentPage(currentPage);

        if (success) {
          setStores(data);
        }
        setPaginatioProps({
          ...paginationProps,
          rowCount: rowCount || 0,
          rowsPerPage,
        });
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getStores();
  }, []);

  const openFormModal = (data = undefined) => {
    setSelectedMenu(data);
    setFormState(true);
  };

  return (
    <>
      <RestaurantaOnLocationList
        stores={stores}
        isLoading={isDataLoading}
        onSelectedMenu={openFormModal}
        fetchData={getStores}
      />
      <Paginator
        rowCount={paginationProps.rowCount}
        rowsPerPage={paginationProps.rowsPerPage}
        currentPage={page}
        onPageChange={(newPage, pageSize) => {
          getStores({ page: newPage, limit: pageSize });
        }}
      />
    </>
  );
}
