import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Col,
  Row,
  Skeleton,
} from 'antd';
import BtnGroupCategories from '../../common/components/Dishes/BtnGroupCategories';
import {
  selectDishesCollection,
  selectDishesCategories,
  // selectDishesRestaurant,
  fetchDataByRestaurant,
  selectDishesRestaurant,
  // setDishesCollection,
  // setDishesCategories,
  // setDishesRestaurant,
} from './dishesSlice';
import { DishesList } from '../../common/components/Dishes/DishesList';
import { setCurrentPageTitle } from '../../app/Layouts/appLayoutSlice';
import { setCurrentCartLocationId } from '../../common/components/ShoppingCartMenu/shoppingCartSlice';
// import { getRestaurant } from '../../api/index';
// import { get as getDishes, getCategories as getDishesCategories } from '../../api/dish';
// import
// {
//   getOne as getAllDataByRestaurant,
// } from '../../api/restaurant';

function DishesPage() {
  const { id } = useParams();
  const dispatch = useDispatch();

  // const restaurant = useSelector(selectDishesRestaurant);
  const categories = useSelector(selectDishesCategories);
  const dishes = useSelector(selectDishesCollection);

  console.log('SE RENDERIZA DISHES PAGE', dishes);
  const [filteredDishes, setFilteredDishes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({ id: 0 });
  const [isLoading, setLoading] = useState(false);
  const currentRestaurant = useSelector(selectDishesRestaurant);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchDataByRestaurant(id))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log('current restaurant', currentRestaurant);
    dispatch(setCurrentCartLocationId(currentRestaurant?.locationId));
    dispatch(setCurrentPageTitle(currentRestaurant?.name));
  }, [currentRestaurant]);

  React.useEffect(() => {
    if (selectedCategory.id === 0 && categories.length > 0) {
      const firstCategory = categories[0];
      setSelectedCategory(firstCategory);
    }
  }, [categories]);

  const updateFilteredDishes = () => {
    const filteredArray = dishes.filter((dish) => dish.categoryId === selectedCategory.id);
    setFilteredDishes(filteredArray);
  };

  React.useEffect(() => {
    updateFilteredDishes();
  }, [selectedCategory, dishes]);

  const setSelectedCategoryHandler = (categoryId) => {
    // Cleaning filtered Dishes
    const catg = categories.find((cat) => cat.id === parseInt(categoryId, 10));
    setSelectedCategory(catg);
  };

  if (isLoading) {
    return (
      <div>
        <Row justify="center" align="middle">
          <Skeleton.Button loading={isLoading} />
          <Skeleton.Button loading={isLoading} />
          <Skeleton.Button loading={isLoading} />
        </Row>
        <br />
        <br />
        <br />
        <Skeleton
          avatar
          paragraph={{
            rows: 4,
          }}
          loading={isLoading}
          active
        />
        <Skeleton
          avatar
          paragraph={{
            rows: 4,
          }}
          loading={isLoading}
          active
        />
        <Skeleton
          avatar
          paragraph={{
            rows: 4,
          }}
          loading={isLoading}
          active
        />
      </div>
    );
  }

  return (
    <React.StrictMode>
      <div className="container">
        <Row justify="center" align="center">
          <Skeleton loading={isLoading} active>
            <Col>

              <BtnGroupCategories
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={(catId) => { setSelectedCategoryHandler(catId); }}
              />
            </Col>
          </Skeleton>
        </Row>
        <Row className="mt-5" justify="center" align="middle">

          {
            filteredDishes.length > 0
              ? (
                <Col span={24}>
                  <DishesList
                    dishes={filteredDishes}
                  />
                </Col>
              )
              : (
                <Col>
                  <Alert message="Todavía no tienes platos en esta categoría" type="warning" showIcon />
                </Col>
              )
          }

        </Row>
      </div>
    </React.StrictMode>
  );
}
export default DishesPage;
