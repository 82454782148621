/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH, PAUSE,
  PERSIST, PURGE,
  REGISTER, REHYDRATE,
} from 'redux-persist';
// import counterReducer from '../features/counter/counterSlice';
import { RootReducer } from './rootReducer';

// eslint-disable-next-line no-undef
export const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
