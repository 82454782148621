import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function CartSpinner({ isLoading }) {
  return (
    isLoading
      && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,0.15)',
            zIndex: 9999,
            width: '100%',
            height: '100%',
            overflow: 'visible',
            top: 0,
            left: 0,
            borderRadius: 10,
          }}
        >
          <Spin indicator={(
            <LoadingOutlined
              style={{
                fontSize: 54,
              }}
            />
          )}
          />
        </div>
      )
  );
}
CartSpinner.defaultProps = {
  isLoading: false,
};
CartSpinner.propTypes = {
  isLoading: PropTypes.bool,
};
