import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ankiLogo from '../../../../assets/img/logo-anki.png';
import './web-spinner.css';

export default function WebSpinner({ isLoading }) {
  useEffect(() => {
    if (isLoading) {
      window.scrollTo(0, 0);
      console.log('escrolling');
    }
    document.body.style.overflow = (isLoading ? 'hidden' : 'scroll');
  }, [isLoading]);

  return (
    isLoading
        && (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            backgroundColor: '#fff',
            zIndex: 9999,
            overflowY: 'hidden',
          }}
        >
          <img
            className="img-spinner"
            width={250}
            alt="Anki Logo"
            src={ankiLogo}
          />
        </div>
        )
  );
}

WebSpinner.defaultProps = {
  isLoading: true,
};

WebSpinner.propTypes = {
  isLoading: PropTypes.bool,
};
