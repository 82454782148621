/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Skeleton,
  Space,
  Tag,
} from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { orderMould, orderStateMould } from '../../../common/utils/objectsPropsMould';
import OrderCard from '../orderCard/OrderCard';
import './order-state-container.css';
import ModalDishCardDetails from '../modalDishCardDetails/ModalDishCardDetails';

// import { orderStatesCollection } from '../../utils/constants';
// import GlobalSpinner from '../Spinners/GlobalSpinner';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {  } from '@fortawesome/free-regular-svg-icons';

const checkScroll = (cardContentSelector, arrowUpSelector, arrowDownSelector) => {
  const cardContent = document.querySelector(cardContentSelector);
  const arrowUp = document.querySelector(arrowUpSelector);
  const arrowDown = document.querySelector(arrowDownSelector);

  if (cardContent.scrollHeight <= cardContent.offsetHeight) {
    arrowUp.style.display = 'none';
    arrowDown.style.display = 'none';
  } else {
    arrowUp.style.display = 'flex';
    arrowDown.style.display = 'flex';
  }

  if (arrowUp && arrowDown) {
    if (cardContent.scrollTop === 0) {
      arrowUp.classList.remove('arrow-active');
      arrowDown.classList.add('arrow-active');
    } else {
      arrowUp.classList.add('arrow-active');
      // console.log('inner height', cardContent.innerHeight);
      console.log('inner height', cardContent.offsetHeight);
      // console.log('inner height', cardContent.clientHeight);
      console.log('scrollo top', cardContent.scrollTop);
      console.log('scrollo height', cardContent.scrollHeight);
      // console.log('vaina rect', cardContent.getBoundingClientRect());
      if (
        Math.round(cardContent.scrollTop + cardContent.offsetHeight) === cardContent.scrollHeight) {
        arrowDown.classList.remove('arrow-active');
        console.log('llego al final');
      } else {
        arrowDown.classList.add('arrow-active');
      }
    }
  }
};

const getArrowsSelectorsByCardContentId = (cardContentId) => [
  `#${cardContentId} > .order-state-container__arrow-up-container`,
  `#${cardContentId} > .order-state-container__arrow-down-container`,
];

const createEventListenerWhenScrolling = (cardContentId) => {
  // console.log(arrowDown);
  // console.log('este es el elemento', element);
  const cardContent = document.getElementById(cardContentId);

  const [arrowUpSelector, arrowDownSelector] = getArrowsSelectorsByCardContentId(cardContentId);

  // const arrowUpSelector = `#${cardContentId} > .order-state-container__arrow-up-container`;
  // const arrowDownSelector = `#${cardContentId} > .order-state-container__arrow-down-container`;

  const arrowUp = document.querySelector(arrowUpSelector);
  const arrowDown = document.querySelector(arrowDownSelector);

  checkScroll(`#${cardContentId}`, arrowUpSelector, arrowDownSelector);

  cardContent.addEventListener('scroll', () => {
    console.log('arrowup', arrowUp);
    checkScroll(`#${cardContentId}`, arrowUpSelector, arrowDownSelector);
  });

  arrowUp.addEventListener('click', () => {
    cardContent.scrollTop -= 30;
  });
  arrowDown.addEventListener('click', () => {
    cardContent.scrollTop += 30;
  });
};

function OrderStateContainer({
  state,
  orders,
  areaToExclude,
  isLoading,
}) {
  const [currentOrder, setCurrentOrder] = useState(null);
  const [orderModalState, setOrderModalState] = useState(false);

  const handleToSeeOrder = (order) => {
    setCurrentOrder(order);
    setOrderModalState(true);
  };

  const cardContent = useRef(null);
  const arrowUp = useRef(null);
  const arrowDown = useRef(null);

  const cardContentId = `stateOrderContainer${state.id}`;

  useEffect(() => {
    const [arrowUpSelector, arrowDownSelector] = getArrowsSelectorsByCardContentId(cardContentId);
    checkScroll(`#${cardContentId}`, arrowUpSelector, arrowDownSelector);
  }, [orders]);

  useEffect(() => {
    createEventListenerWhenScrolling(cardContentId);
  }, []);

  return (
    <>

      <ModalDishCardDetails
        isOpen={orderModalState}
        orderObj={currentOrder}
        onCloseModal={() => setOrderModalState(false)}
      />
      <Card
        title={(
          <p>
            {state.text}
          </p>
      )}
        extra={(
          <div style={{ padding: '0.75rem 1rem' }}>
            <Tag color="black">
              {orders.length}
            </Tag>
          </div>
      )}
        className="card-state"
      >

        <div className="card-content order-state-container__content p-2" ref={cardContent} id={cardContentId}>
          <div className="order-state-container__arrow-up-container" ref={arrowUp}>
            <ArrowUpOutlined />
          </div>
          <Skeleton loading={isLoading} active>
            {/* <GlobalSpinner /> */}
            <Space direction="vertical" style={{ display: 'flex' }}>
              {
              orders.map((order) => (
                <OrderCard
                  onSeeDetails={handleToSeeOrder}
                  order={order}
                  key={order.id}
                  hasBtn={!(areaToExclude !== '' && state.areas.indexOf(areaToExclude) > -1)}
                />
              ))
            }
            </Space>
          </Skeleton>
          {/* <div className="is-fullwidth p-2" style={{ position: 'fixed' }}> */}
          <div className="order-state-container__arrow-down-container p-2" ref={arrowDown}>
            <ArrowDownOutlined />
          </div>
          {/* </div> */}
        </div>
      </Card>
    </>
  );
}

OrderStateContainer.defaultProps = {
  areaToExclude: '',
  isLoading: false,
};

OrderStateContainer.propTypes = {
  state: PropTypes.shape(orderStateMould).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape(orderMould)).isRequired,
  areaToExclude: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default OrderStateContainer;
