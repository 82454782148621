/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Form,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { ControlOutlined } from '@ant-design/icons';
import { notify } from '../../common/utils/notifications';
import { selectDishById } from './dishesSlice';
import { addToCart, setState as setCartState } from '../../common/components/ShoppingCartMenu/shoppingCartSlice';

const { Text, Title } = Typography;

export default function FormSelectDishes({
  dishId,
  currentState,
  onStateChange,
}) {
  const [isOpen, setModalState] = useState(false);
  const [currentDishId, setCurrentDishId] = useState(0);
  const currentDish = useSelector(selectDishById(currentDishId));
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [selectedDishes, setSelectedDishes] = useState([]);
  const namePrefix = 'plato-dish';

  useEffect(() => {
    if (currentState) {
      form.resetFields();
    }
    setModalState(currentState);
  }, [currentState]);

  useEffect(() => {
    console.log('cambia el dishId', dishId);
    setCurrentDishId(dishId);
  }, [dishId]);

  useEffect(() => {
    if (currentState) {
      console.log('plato seleiccionado en el modal', currentDish, currentDishId);
    }
  }, [currentDishId]);

  /** const dish = {
      id: 1,
      title: 'Plato',
      price: 29.90,
      comboDetail: [
        {
          id: 1,
          title: 'Plato 1',
          dishes: [
            {
              id: 2,
              title: 'Pizza Vegetariana',
            },
            {
              id: 3,
              title: 'Pizza Vegetariana',
            },
            {
              id: 4,
              title: 'Pizza Vegetariana',
            },
          ],
        },
        {
          id: 2,
          title: 'Plato 2',
          dishes: [
            {
              id: 2,
              title: 'Pizza Vegetariana',
            },
            {
              id: 3,
              title: 'Pizza Vegetariana',
            },
            {
              id: 4,
              title: 'Pizza Vegetariana',
            },
          ],
        },
      ],
    };* */

  useEffect(() => {
    form.validateFields();
  }, [form]);

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        const idsArray = [];
        Object.keys(values).forEach((prop) => {
          idsArray.push(parseInt(prop.split(`${namePrefix}-`).pop(), 10));
        });
        const valuesArray = [];

        idsArray.forEach((id) => {
          valuesArray.push({
            ...values[`${namePrefix}-${id}`],
          });
        });
        dispatch(addToCart({ ...currentDish, dishes: valuesArray }));
        setTimeout(() => {
          dispatch(setCartState(true));
          notify({
            text: 'Se ha agregado el plato al carrito',
            type: 'success',
            autoClose: 500,
          });
        }, 1000);
        console.log('valores', valuesArray);
        setTimeout(() => {
          // form.resetFields();
          onStateChange?.(false);
        }, 100);
      });
  };

  const handleCancel = () => {
    // form.resetFields();
    onStateChange?.(false);
  };
  return (
    <Modal
      open={isOpen}
      cancelButtonProps={{
        type: 'dashed',
        danger: true,
      }}
      okButtonProps={{
        type: 'primary',
      }}
      onCancel={handleCancel}
      onOk={handleOk}
      title={(
        <Title level={4} className="mt-0">
          <ControlOutlined />
          {' '}
          {`Seleccionar platos para: ${currentDish?.name}`}
        </Title>
      )}
    >
      <Form
        form={form}
        initialValues={{}}
        preserve={false}
        scrollToFirstError
        onFieldsChange={(_, allFields) => {
          console.log(allFields);
          form.setFieldsValue(allFields);
        }}
      >
        {
          currentDish?.comboDetail.map((memberDish) => (
            <React.StrictMode
              key={memberDish.id}
            >
              <Row>
                <Text type="danger">*</Text>
                <Text className="my-2" strong>
                  {memberDish.title}
                </Text>
              </Row>
              <Form.Item
                key={memberDish.id}
                name={`${namePrefix}-${memberDish.id}`}
                rules={[
                  {
                    required: true,
                    message: 'Selecciona una opción',
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {memberDish?.dishes.map((d) => (
                      <Radio
                        key={d.dish.id}
                        value={d.dish}
                      >
                        {d.dish.title}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>

              </Form.Item>

            </React.StrictMode>

          ))
        }
      </Form>

    </Modal>

  );
}

FormSelectDishes.defaultProps = {
  dishId: 0,
  currentState: false,
  onStateChange: null,
};

FormSelectDishes.propTypes = {
  dishId: PropTypes.number,
  currentState: PropTypes.bool,
  onStateChange: PropTypes.func,
};
