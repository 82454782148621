import React from 'react';
import PropTypes from 'prop-types';

function CustomImg({
  url,
  border,
  width,
  height,
}) {
  return (
    <div style={{
      backgroundImage: `url(${url})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width,
      height,
      borderRadius: border,
    }}
    />
  );
}

CustomImg.defaultProps = {
  url: '',
  border: '0',
  width: '100%',
  height: 'auto',
};

CustomImg.propTypes = {
  url: PropTypes.string,
  border: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CustomImg;
