import {
  Button,
  Checkbox,
  Col, Form, Input, Modal, Row, Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SaveOutlined } from '@ant-design/icons';
import { getFormParams, save as saveMenu } from '../../../../api/menu';
import { notify } from '../../../../common/utils/notifications';
import { menuMould } from '../../../../common/utils/objectsPropsMould';
import { isset } from '../../../../common/utils/utils';

export default function MenuFormModal({
  isOpen,
  menuData,
  onCancel,
  onSubmit,
}) {
  const [form] = Form.useForm();
  const LRIdFieldName = 'locationOnRestaurantId';
  const formLRIdValue = Form.useWatch(LRIdFieldName, form);
  const [isLoading, setLoading] = useState(false);
  const [loadingCats, setLoadingCats] = useState(false);
  const [lROpt, setLROpt] = useState([]);
  const [catsOpt, setCatsOpt] = useState([]);
  const [dishesCollection, setDishesCollection] = useState([]);
  const [dishesOpt, setDishesOpt] = useState([]);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue(menuData);
    } else {
      form.resetFields();
    }
  }, [isOpen]);

  useEffect(() => {
    if (dishesCollection.length > 0) {
      const menuId = form.getFieldValue('id');
      if (isset(menuId)) {
        const currentSelectedDishes = dishesCollection.filter(
          (d) => d.menuId === menuId,
        );
        if (currentSelectedDishes?.length > 0) {
          form.setFieldValue(
            'dishes',
            currentSelectedDishes.map((d) => d.id),
          );
        }
      }
      const filteredDishesCollection = dishesCollection.filter(
        (d) => d.locationOnRestaurantId === formLRIdValue
        || !isset(d.menuId),
      );

      setDishesOpt(filteredDishesCollection.map((d) => ({
        value: d.id,
        label: d.title,
        key: d.id,
      })));
    }
  }, [formLRIdValue]);
  const fetchFormParams = () => {
    setLoadingCats(true);
    getFormParams()
      .then(({ success, data: { categories, locationsOnRestaurants, dishes } }) => {
        // console.log('estas son las categorias', categories, success, dishes);
        if (success) {
          if (categories?.length > 0) {
            setCatsOpt(categories.map((c) => ({
              value: c.id,
              label: c.name,
              key: c.id,
            })));
          }
          if (locationsOnRestaurants?.length > 0) {
            setLROpt(locationsOnRestaurants.map((lr) => ({
              value: lr.id,
              label: lr.name,
              key: lr.id,
            })));
          }
          if (dishes?.length > 0) {
            setDishesCollection(dishes);
          }
        }
      })
      .finally(() => {
        setLoadingCats(false);
      });
  };
  useEffect(() => {
    fetchFormParams();
  }, []);
  const handleFormSubmit = () => {
    form.validateFields()
      .then((vals) => {
        console.log('enviando formulario');
        setLoading(true);
        saveMenu(vals, menuData?.id)
          .then(({ success, message }) => {
            notify({
              text: message,
              type: success ? 'success' : 'error',
            });
            if (success) {
              fetchFormParams();
              onSubmit();
            }
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={`${menuData ? 'Crear Menu' : 'Editar Menu'}`}
      open={isOpen}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          icon={<SaveOutlined />}
          onClick={handleFormSubmit}
          loading={isLoading}
          type="primary"
        >
          Guardar
        </Button>,
      ]}
      onCancel={onCancel}
      okButtonProps={false}
    >
      <Form
        form={form}
        initialValues={menuData}
        onFinish={handleFormSubmit}
        layout="vertical"
        preserve={false}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: 'Es obligatorio especificar un nombre para el menú',
                },
              ]}
            >
              <Input
                placeholder="Ingresa el nombre de tu menú"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="locationOnRestaurantId"
              label="Restaurante"
            >
              <Select
                placeholder="Selecciona un restaurante"
                style={{ width: '100%' }}
                options={lROpt}
                loading={loadingCats}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="categoriesIds"
              label="Categorías"
            >
              <Select
                mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                options={catsOpt}
                loading={loadingCats}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="dishes"
              label="Platos"
            >
              <Checkbox.Group
                style={{ width: '100%', flexWrap: 'wrap' }}
                options={dishesOpt}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

MenuFormModal.defaultProps = {
  isOpen: false,
  menuData: {
    id: null,
    name: '',
    categoriesIds: [],
    locationOnRestaurantId: null,
  },
  onCancel: () => { },
  onSubmit: () => { },
};

MenuFormModal.propTypes = {
  isOpen: PropTypes.bool,
  menuData: PropTypes.shape(menuMould),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
