/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
// import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Typography,
} from 'antd';
import {
  CopyOutlined,
  EnvironmentOutlined,
  MailOutlined,
  MinusCircleOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  clientDataInitialState,
  getClientDataValidationsInitialState,
} from './model';
import { channels, documentsTypesCollection as documentsTypes, paymentMethods } from '../../../utils/constants';
// import CustomInput from '../../Extras/CustomInput/CustomInput';
import { notify } from '../../../utils/notifications';
import {
  fetchObjectArraysToCustomSelectOptions,
  getDocTypeNameById,
  getNextOrderState,
  getPaymentMethodNameById,
  ValidationMould,
} from '../../../utils/utils';
import {
  createOrder,
  selectQueueTime,
} from '../../../../features/order/ordersSlice';
// import Quantifier from '../../Quantifier/Quantifier';
import useForm from '../../../hooks/useForm';
import {
  reset,
  selectCurrentCartLocationId,
  selectShoppingCartDishes,
  selectShoppingCartTotals,
} from '../shoppingCartSlice';
import { fetchCustomerSunatApi, getOneByDoc } from '../../../../api/customer';
import { validateDocumentNumberByType } from '../../../utils/documents';
// import DotSpinner from '../../Spinners/DotSpinner/DotSpinner';
import { selectUser } from '../../../../features/login/user';
import Quantifier from '../../Quantifier/Quantifier';
// import CustomSelect from '../../Extras/CustomSelect/CustomSelect';

const { Option } = Select;

function PurchaseModal({
  clientAddress,
  deliveryPrice,
  isOpen,
  onModalStateChange,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shoppingCartDishes = useSelector(selectShoppingCartDishes);
  const shoppingCartTotals = useSelector(selectShoppingCartTotals);
  const currentCartLocationId = useSelector(selectCurrentCartLocationId);
  const queueTime = useSelector(selectQueueTime);
  const currentUser = useSelector(selectUser);
  const [state, setState] = useState(null);
  const [isLoading, setLoading] = useState(false);
  // const [clientData, setClientData] = useState({});
  // eslint-disable-next-line no-unused-vars

  const orderForm = useForm({ ...clientDataInitialState }, getClientDataValidationsInitialState());
  // eslint-disable-next-line no-unused-vars
  const [form] = Form.useForm();
  const channelOptions = fetchObjectArraysToCustomSelectOptions(channels, ['name', 'text']);
  // const [clientDataValidations, setClientDataValidations] = useState({});
  const docRef = useRef('');
  const addressRef = useRef('');

  docRef.current = form.getFieldValue('documentNumber');

  useEffect(() => {
    setState(isOpen);
    console.log(currentCartLocationId, 'este es el location id');
  }, [isOpen]);

  useEffect(() => {
    form.setFieldsValue({
      address: clientAddress,
      delivery: deliveryPrice,
    });
  }, [clientAddress, deliveryPrice]);

  const fetchCustomerByDoc = async (doc) => {
    console.log(' consultando el siguiente documeot', doc, form.getFieldValue('documentTypeId'));

    if (validateDocumentNumberByType(form.getFieldValue('documentTypeId'), doc?.toString())) {
      setLoading(true);
      console.log('documento valido, consultando');
      const { success, data } = await getOneByDoc(doc, form.getFieldValue('documentTypeId'))
        .finally(() => { setLoading(false); });

      if (success) {
        form.setFieldValue('name', data.name);
        form.setFieldValue('phone', data.phone);
        addressRef.current = data.address;
      }
    }
  };
  const watchingDoc = Form.useWatch('documentNumber', form);

  const fetchCustomerSunat = async () => {
    const docNumber = form.getFieldValue('documentNumber');
    if (validateDocumentNumberByType(form.getFieldValue('documentTypeId'), docNumber?.toString())) {
      setLoading(true);
      const { success, data } = await fetchCustomerSunatApi(docNumber);
      if (success) {
        form.setFieldsValue({
          name: data.name,
          address: data.address,
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('el documento cambió', docRef.current);
    console.log('el documento cambió', watchingDoc);
    setTimeout((val) => {
      console.log('consutlando papus', val, docRef.current);
      if ((val === docRef.current) && val !== '') {
        console.log('de pana');
        fetchCustomerByDoc(val);
      }
    }, 2000, watchingDoc);
  }, [watchingDoc]);

  useEffect(() => {
    console.log('como va el formulario', form.getFieldsValue());
    form.validateFields();
  }, [form]);

  const resetModal = () => {
    console.log('reseteando modal');
    form.resetFields();
    console.log('se ha resetado el modal y la data de validacion colocada es', getClientDataValidationsInitialState());
  };

  /* const generateForm = () => {
    const form = new FormData();
    Object.keys(clientData).forEach((prop) => {
      form.append(prop, clientData[prop]);
    });
    console.log('este es el form', form);
    return form;
  }; */

  const closeModal = () => {
    onModalStateChange(false);
    resetModal();
  };

  const closeModalAfterSubmit = () => {
    resetModal();
    onModalStateChange(false, true);
  };

  const submitHandler = (evt) => {
    evt.preventDefault();

    // if (checkIsFormValid()) {
    if (orderForm.checkIsFormValid({
      documentNumber: [orderForm.data.documentTypeId],
    })) {
      // const data = generateForm();
      const orderState = getNextOrderState(0);
      const docTypeId = orderForm.data.documentTypeId;
      const docTypeName = getDocTypeNameById(orderForm.data.documentTypeId);
      const data = {
        ...orderForm.data,
        userId: currentUser.id,
        docType: `${docTypeId}:${docTypeName}`,
        docNumber: orderForm.data.documentNumber,
        paymentMethod: getPaymentMethodNameById(orderForm.data.paymentMethodId),
        state: `${orderState.id}:${orderState.name}`,
        ...shoppingCartTotals,
      };
      data.dishes = shoppingCartDishes.map((d) => (
        {
          ...d,
          ingredients: d.ingredients ? JSON.stringify(d.ingredients) : '',
          addons: d.addons ? JSON.stringify(d.addons) : '',
          dishes: (d.dishes && d.dishes?.length > 0) ? JSON.stringify(d.dishes) : '',
        }
      ));

      dispatch(createOrder(JSON.stringify(data)))
        // .then(unwrapResult)
        .then(({ payload }) => {
          console.log('entro al then del dispathc', payload);
          if (payload.success) {
            console.log('entro al if');
            closeModalAfterSubmit();
            setTimeout(() => {
              navigate('/app/cash-register-orders', { currentCartLocationId });
            }, 0);
          }
        });
    } else {
      notify({
        text: 'Algunos campos son incorrectos',
        type: 'error',
      });
    }
  };

  const handleSubmit = (values) => {
    console.log(values, 'enviando form');
    form.validateFields().then((vals) => {
      console.log(vals);
      console.log('destructurando array', vals);
      const orderState = getNextOrderState(0);
      const docTypeId = orderForm.data.documentTypeId;
      const docTypeName = getDocTypeNameById(orderForm.data.documentTypeId);
      const data = {
        ...vals,
        // ...orderForm.data,
        userId: currentUser.id,
        docType: `${docTypeId}:${docTypeName}`,
        docNumber: orderForm.data.documentNumber,
        paymentMethod: getPaymentMethodNameById(orderForm.data.paymentMethodId),
        state: `${orderState.id}:${orderState.name}`,
        ...shoppingCartTotals,
      };
      data.dishes = shoppingCartDishes.map((d) => (
        {
          ...d,
          ingredients: d.ingredients ? JSON.stringify(d.ingredients) : '',
          addons: d.addons ? JSON.stringify(d.addons) : '',
        }
      ));
      console.log('data a enviar', data);
      dispatch(createOrder(JSON.stringify(data)))
        // .then(unwrapResult)
        .then(({ payload }) => {
          console.log('entro al then del dispathc', payload);
          if (payload.success) {
            console.log('entro al if');
            closeModalAfterSubmit();
            setTimeout(() => {
              navigate('/app/cash-register-orders', { state: { currentCartLocationId } });
            }, 0);
          }
        });
    })
      .catch((error) => {
        console.log(error);
        notify({
          text: 'Algunos campos son incorrectos',
          type: 'error',
        });
      });
  };

  return (
    <Modal
      cancelText="Cancelar"
      cancelButtonProps={{
        type: 'dashed',
        danger: true,
      }}
      onCancel={() => { closeModal(); }}
      okButtonProps={{
        type: 'primary',
        onClick: handleSubmit,
      }}
      okText="Ingresar Pedido"
      title={(
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Datos de la compra
          {' '}
          <ShoppingCartOutlined />
        </Typography.Title>
      )}
      open={state}
      width={700}
    >
      <Form
        form={form}
        name="purchaseModal"
        preserve={false}
        initialValues={{
          ...clientDataInitialState,
          address: clientAddress,
          delivery: deliveryPrice,
        }}
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError
        onFieldsChange={(_, allFields) => { console.log('los valores', allFields); form.setFieldsValue(allFields); }}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="Documento"
              name="documentNumber"
              validateStatus={isLoading ? 'validating' : undefined}
              dependencies={['documentTypeId']}
              rules={[
                {
                  required: orderForm.validations.documentNumber.isRequired,
                  message: orderForm.validations.documentNumber.errorMessage,
                  validator: (_, value) => {
                    const isValid = orderForm.validations.documentNumber.checkExtraValidations(value ? value?.toString() : '', form.getFieldValue('documentTypeId'));

                    return isValid ? Promise.resolve()
                      : Promise.reject(new Error('Error de prueba'));
                  },
                },
              ]}
              hasFeedback
            >
              <Space.Compact direction="horizontal" block>
                <InputNumber
                  style={{ width: '100%' }}
                  type="number"
                  addonBefore={(
                    <Form.Item
                      name="documentTypeId"
                      dependencies={['documentNumber']}
                      rules={[
                        {
                          required: orderForm.validations.documentTypeId.isRequired,
                          message: orderForm.validations.documentTypeId.errorMessage,
                          validator: (_, value) => {
                            const isValid = orderForm.validations
                              .documentTypeId.checkExtraValidations(value);

                            return isValid ? Promise.resolve()
                              : Promise.reject(new Error('Error de prueba'));
                          },
                        },
                      ]}
                      noStyle
                    >
                      <Select
                        placeholder="Selecciona un documento"
                      >
                        {documentsTypes.map((document) => (
                          <Select.Option key={document.id} value={document.id}>
                            {document.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                />
                <Button icon={<UserSwitchOutlined />} onClick={fetchCustomerSunat} />
              </Space.Compact>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Nombre"
              rules={[
                {
                  required: orderForm.validations.name.isRequired,
                  message: orderForm.validations.name.errorMessage,
                },
              ]}
              hasFeedback
            >
              <Input
                prefix={<UserOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="phone"
              label="Celular"
              rules={[
                {
                  required: orderForm.validations.phone.isRequired,
                  pattern: orderForm.validations.phone.regex,
                  message: orderForm.validations.phone.errorMessage,
                },
              ]}
              hasFeedback
            >
              <Input
                type="text"
                prefix={<PhoneOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              label="Correo"
              rules={[
                {
                  required: orderForm.validations.email.isRequired,
                  pattern: orderForm.validations.email.regex,
                  message: orderForm.validations.email.errorMessage,
                },
              ]}
              hasFeedback
            >
              <Input prefix={<MailOutlined />} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="save"
              valuePropName="checked"
              label="¿Desea guardar el cliente?"
            >
              <Checkbox> Si </Checkbox>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              name="address"
              label="Dirección"
              rules={[
                {
                  required: orderForm.validations.address.isRequired,
                  message: orderForm.validations.address.errorMessage,
                },
              ]}
              help={(
                addressRef.current.length > 0
                  ? (
                    <Typography.Text type="secondary">
                      {`Dirección Guardada: ${addressRef.current}`}
                      <Button
                        icon={<CopyOutlined />}
                        type="link"
                        onClick={() => { form.setFieldValue('address', addressRef.current); }}
                      />
                    </Typography.Text>
                  )
                  : undefined
              )}
              hasFeedback
            >
              <Input
                prefix={<EnvironmentOutlined />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="delivery"
              label="Delivery"
              rules={[
                {
                  required: orderForm.validations.delivery.isRequired,
                  message: orderForm.validations.delivery.errorMessage,
                },
                {
                  validator: (_, value) => (value > -1 ? Promise.resolve() : Promise.reject(new Error('El precio debe ser mayor o igual a cero'))),
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="channel"
              label="Canal"
            >
              <Select>
                {
                  channelOptions.map((c) => (
                    <Option key={c.key}>{c.value}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="paymentMethodId"
              label="Método de Pago"
              rules={[
                {
                  required: orderForm.validations.paymentMethodId.isRequired,
                  message: orderForm.validations.paymentMethodId.errorMessage,
                  validator: (_, value) => {
                    const isValid = orderForm
                      .validations.paymentMethodId.checkExtraValidations(value);

                    return isValid ? Promise.resolve() : Promise.reject();
                  },
                },
              ]}
            >
              <Select>
                {
                  paymentMethods.map((method) => (
                    <Option key={method.id} value={method.id}>
                      {method.name}
                    </Option>

                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="preparationTime"
              trigger="onQuantityChange"
              label="Tiempo"
              help={(
                <Typography.Text type="secondary">
                  Tiempo en cola
                  {' '}
                  {queueTime}
                </Typography.Text>
              )}
            >
              <Quantifier
                maxQuantity={120}
                minQuantity={5}
                step={5}
                inputName="preparationTime"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="discount"
              label="Descuento"
            >
              <InputNumber
                placeholder="Descuento"
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

PurchaseModal.defaultProps = {
  isOpen: false,
  deliveryPrice: 0,
};

PurchaseModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalStateChange: PropTypes.func.isRequired,
  clientAddress: PropTypes.string.isRequired,
  deliveryPrice: PropTypes.number,
};

export default PurchaseModal;
