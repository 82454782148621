import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ingredientMould } from '../../../../../common/utils/objectsPropsMould';
import SupplyRow from './IngredientRow';
import { colsOrder } from '../../../../../common/utils/constants';
import CustomHeaderRow from '../../../../../common/components/Extras/CustomHeaderRow/CustomHeaderRow';
import Paginator from '../../../../../common/components/Extras/Paginator/Paginator';

export default function IngredientsList({ ingredients }) {
  const [columns, setColumns] = useState([
    {
      name: 'name',
      label: 'Nombre',
      order: colsOrder.DESC,
    },
    {
      name: 'measureUnit',
      label: 'Unidad',
      order: colsOrder.DESC,
    },
    {
      name: 'price',
      label: 'Precio',
    },
    {
      name: 'supplier',
      label: 'Proveedor',
    },
    {
      name: 'actions',
      label: 'Acciones',
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="table-container">
      <table className="table anki-table is-fullwidth is-hoverable">
        <thead>
          <CustomHeaderRow
            columns={columns}
            onColumnsChange={setColumns}
          />
        </thead>
        <tbody>
          {
                        ingredients.map((ingredient) => (
                          <SupplyRow key={ingredient.id} ingredient={ingredient} />
                        ))
                    }
        </tbody>
      </table>
      <Paginator
        rowCount={1000}
        rowsPerPage={10}
        currentPage={currentPage}
        onPageChange={(newPage) => { setCurrentPage(newPage); }}
      />
    </div>
  );
}

IngredientsList.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.shape(ingredientMould)).isRequired,
};
