import React from 'react';
import {
  // HashRouter,
  Routes,
  Route,
  useLocation,
  // useLocation,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Auth0Provider } from '@auth0/auth0-react';
// import { Auth0Provider } from '@auth0/auth0-react';
import { Login as LoginPage } from './features/login/Login';
import AppLayout from './app/Layouts/AppLayout';
import WebLayout from './app/Layouts/WebLayout/WebLayout';
import { AuthProvider, PrivateRoute } from './common/utils/auth';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';// Optimizar esto

function App() {
  const styleThemeProps = {
    token: {
      colorPrimary: '#E74949',
      colorPrimaryHover: '#181CB5',
      colorPrimaryActive: '#181CB5',
      darkColor: '#13122E',
      lightColor: '#EDF0F7',
    },
  };

  const webStyleThemeProps = {
    token: {
      colorPrimary: '#E74949',
      colorPrimaryHover: '#181CB5',
      colorPrimaryActive: '#181CB5',
      darkColor: '#01295F',
      lightColor: '#ffffff',
    },
  };
  const location = useLocation();
  if (location.pathname.includes('/app') || location.pathname.includes('/login')) {
    return (
      <React.StrictMode>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH_DOMAIN}
          clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/app`,
            audience: process.env.REACT_APP_AUTH_AUDIENCE,
            scope: process.env.REACT_APP_AUTH_SCOPE_TO_PROVIDER,
          }}
        >
          <AuthProvider>
            <Routes>
              <Route exact path="/login" element={<LoginPage />} />
              <Route
                path="/app/*"
                element={(
                  <ConfigProvider
                    theme={styleThemeProps}
                  >
                    <PrivateRoute>
                      <AppLayout />
                    </PrivateRoute>
                  </ConfigProvider>
                )}
              />
            </Routes>
          </AuthProvider>
        </Auth0Provider>
      </React.StrictMode>

    );
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={(
          <ConfigProvider
            theme={webStyleThemeProps}
          >
            <WebLayout />
          </ConfigProvider>
        )}
      />
    </Routes>
  );
}

export default App;

// <LoginPage/>
// <RestaurantPage />
// <div className="App">
//   <header className="App-header">
//     <img src={logo} className="App-logo" alt="logo" />
//     {/* <Counter /> */}

//     <p>
//       Edit <code>src/App.js</code> and save to reload.
//     </p>
//     <span>
//       <span>Learn </span>
//       <a
//         className="App-link"
//         href="https://reactjs.org/"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         React
//       </a>
//       <span>, </span>
//       <a
//         className="App-link"
//         href="https://redux.js.org/"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         Redux
//       </a>
//       <span>, </span>
//       <a
//         className="App-link"
//         href="https://redux-toolkit.js.org/"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         Redux Toolkit
//       </a>
//       ,<span> and </span>
//       <a
//         className="App-link"
//         href="https://react-redux.js.org/"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         React Redux
//       </a>
//     </span>
//   </header>
// </div>
