import { ValidationMould } from '../../../../common/utils/utils';

const ingredientData = {
  name: '',
  price: 0,
  categoryId: 1,
  description: '',
  quantity: 1,
  supplierId: 1,
  measureUnit: '',
};

const ingredientValidations = {
  name: new ValidationMould({ isRequired: true }),
  price: new ValidationMould({ isRequired: true }, (val) => val > -1),
};

export { ingredientData, ingredientValidations };
