/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  Row,
} from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useProvideAuth } from '../../common/utils/auth';
import {
  setUser, selectUser,
} from './user';
import './login.css';
import GlobalSpinner from '../../common/components/Spinners/GlobalSpinner/GlobalSpinner';
/** MEJORAR CODIGO */
function Login() {
  /** REGULAR EXPRESSIONS */
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // const passwordRegex = '';
  /** ******************** */
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const location = useLocation();
  const auth = useProvideAuth();
  const navigate = useNavigate();
  let user = useSelector(selectUser);
  const [
    formValidations,
    setFormValidations] = useState({
    username: true,
    password: true,
  });
  const dispatch = useDispatch();

  const onChange = (evt) => {
    const { value, name } = evt.target;
    const data = { ...user };

    dispatch(setUser({ ...data, [name]: value }));
  };
  user = useSelector(selectUser);

  const isUsernameValid = () => emailRegex.test(user.username);

  const isPasswordValid = () => user.password.length >= 8;

  const isFormValid = () => {
    const newValidationObj = {
      username: isUsernameValid(),
      password: isPasswordValid(),
    };
    setFormValidations(newValidationObj);
    for (const prop in newValidationObj) {
      if (!newValidationObj[prop]) {
        return false;
      }
    }
    return true;
  };

  const submitForm = (evt) => {
    evt.preventDefault();
    loginWithRedirect();

    /**
     * if (isFormValid()) {
      setTimeout(() => {
        auth.signin(() => { navigate('/app'); });
      }, 1000);
    }
    * */
  };

  if (isAuthenticated) {
    return (<Navigate to={{ pathname: '/app' }} />);
  }
  loginWithRedirect();
  return (
    <GlobalSpinner isLoading />
  );
  /** return (
    <div className="container">
      <Row justify="center" align="middle">
        <Card>
          <Button
            type="primary"
            onClick={loginWithRedirect}
          >
            Iniciar sesión
          </Button>
        </Card>
      </Row>
    </div>
  ); * */
}

export { Login };
