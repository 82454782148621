import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllByUser as getAllAdmIngredientsByUser } from '../../../api/ingredient';
import { setLoading } from '../../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';

const initialState = {
  collection: [],
};

const admIngredientsSlice = createSlice({
  name: 'admIngredients',
  initialState,
  reducers: {
    setIngredients: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.collection = action.payload;
    },
  },
});

export const {
  setIngredients,
} = admIngredientsSlice.actions;

export default admIngredientsSlice.reducer;

export const selectAdmIngredients = (state) => state.admIngredients.collection;
export const selectAdmIngredientById = (id) => (state) => {
  const ingredientIndex = state.admIngredients.collection.findIndex((ing) => ing.id === id);
  const ingredient = (
    ingredientIndex > -1)
    ? state.admIngredients.collection[ingredientIndex]
    : null;

  return ingredient;
};

export const fetchAdmIngredientsByUser = createAsyncThunk(
  'admIngredients/fetchAdmIngredientsByUser',
  async (_, { dispatch, getState }) => {
    dispatch(setLoading(true));
    const { user } = getState();
    const { success, data } = await getAllAdmIngredientsByUser(user);
    console.log(' se ha ejecutado el asynthun', success, data);
    if (success) {
      dispatch(setIngredients(data));
    }
    dispatch(setLoading(false));
  },
);
