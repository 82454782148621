/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  // useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import {
  selectOrderId,
  selectShoppingCartDelivery,
  selectShoppingCartTotals,
  selectShoppingCustomerAddress,
} from '../shoppingCartSlice';
import useCulqi from '../../../hooks/useCulqi';
import style from './styles.module.css';
import useWhatsapp from '../../../hooks/useWhatsapp';

export default function CustomerCulqi({
  onFormCompleted,
  onLoadingChange,
}) {
  const [culqiNotificationObj, setCulqiNotificationObj] = useState({
    title: 'Procesar pago',
    message: 'Accede a la venta de Culqi para procesar el pago',
    success: null,
  });
  const shoppingCartTotals = useSelector(selectShoppingCartTotals);
  const customerAddress = useSelector(selectShoppingCustomerAddress);
  const deliveryPrice = useSelector(selectShoppingCartDelivery);
  const orderId = useSelector(selectOrderId);
  // const cartDiscount = useSelector(selectShoppingCartDiscount);
  const culqiFormId = 'ankiCulqiContainer';
  const {
    sendWhatsappOrder,
  } = useWhatsapp();
  const handleChargeCreation = (title, message, success) => {
    setCulqiNotificationObj({
      title,
      message,
      success,
    });

    if (success) {
      const extraMessage = `
              Esta es mi dirección: ${customerAddress}
              Este es el costo de mi delivery: ${deliveryPrice}
            `;
      sendWhatsappOrder(extraMessage);
      onFormCompleted();
    }
  };
  const {
    activateCulqiCheckout,
    culqiIsLoading,
    isPaymentProcessed,
  } = useCulqi(culqiFormId, handleChargeCreation);

  useEffect(() => {
    onLoadingChange(culqiIsLoading);
  }, [culqiIsLoading]);
  useEffect(() => {

  }, [isPaymentProcessed]);

  const handleCulqiPayment = () => {
    const cartTotal = shoppingCartTotals.totalWithDiscount !== 0
      ? shoppingCartTotals.totalWithDiscount
      : shoppingCartTotals.total;
    if (cartTotal !== 0 && orderId != null) {
      activateCulqiCheckout({ total: cartTotal, id: orderId });
    }
  };

  useEffect(() => {
    handleCulqiPayment();
  }, []);

  useEffect(() => {
    handleCulqiPayment();
  }, [shoppingCartTotals, orderId]);

  return (
    <>
      {
        isPaymentProcessed
          ? (
            <p className={culqiNotificationObj.success
              ? style.notificationSuccess
              : style.notificationError}
            >
              <CheckCircleFilled color="green-8" />
              {' '}
              {culqiNotificationObj.title}
              {' '}
              {' '}
              <br />
              {culqiNotificationObj.message}
            </p>
          )
          : (
            <p className={style.notification}>
              {' '}
              <b>Realiza tu pago</b>
              {' '}
              {' '}
              <br />
              Tu orden ya ha sido creada, para continuar con el pedido
              debes realizar tu pago
            </p>
          )
    }
      <div id={culqiFormId} className={style.culqiFormContainer} />
    </>

  );
}
CustomerCulqi.defaultProps = {
  onFormCompleted: () => { },
  onLoadingChange: () => { },
};
CustomerCulqi.propTypes = {
  onFormCompleted: PropTypes.func,
  onLoadingChange: PropTypes.func,
};
