/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Button,
  // message,
  Steps,
  theme,
  Modal,
  Typography,
  Skeleton,
} from 'antd';
import PropTypes from 'prop-types';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import CustomerWebDataForm from './CustomerWebDataForm';
import CartSpinner from '../CartSpinner/CartSpinner';
import CustomerCulqi from './CustomerCulqi';
import { checkCulqiAvailability } from '../../../../api/culqi';
import { reset as resetCart, selectShoppingCart, selectShoppingCartDiscount } from '../shoppingCartSlice';
import useWhatsapp from '../../../hooks/useWhatsapp';

function CustomerWebModalCulqiLoading() {
  return (
    <Skeleton paragraph={{ rows: 4 }} />
  );
}

export default function CustomerWebModal({
  isOpen,
  onModalStateChange,
  onModalCompleted,
}) {
  /** MODAL PROPS */
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCulqiLoading, setCulqiLoading] = useState(false);
  const [isCulqiDisplayed, setIsCulqiDisplayed] = useState(false);

  // eslint-disable-next-line no-shadow, arrow-parens
  const shoppingCartData = useSelector(selectShoppingCart);
  const cartDiscount = useSelector(selectShoppingCartDiscount);
  // const [customerData, setCustomerData] = useState();

  /** * SEND WHATSAPP ORDER MESSAGE */
  /* const sendWhatsappOrder = (extraMessage = '', resetShoppinCart = false) => {
    console.log('es
    te es el número al que debería enviar', shoppingCartData, shoppingCartData.locationWebPhone);
    const defaultPhoneNumber = '51955756006';
    const selectedDishes = shoppingCartData.dishes.map((d) => ({
      name: d.name,
      price: d.price,
      quantity: d.quantity,
    }));
    const totalStore = shoppingCartData.total;
    let message = `
Hola, quisiera realizar el siguiente pedido realizado desde ${window.location.origin}:%0A
%0A
${selectedDishes.map((d) =>
   `${d.quantity} x ${d.name} (P/U ${d.price})
   ----> Total del Plato: ${(d.quantity * d.price).toFixed(2)}`).join('%0A')}
%0A%0A
%0A
Total de la compra: S/${totalStore.toFixed(2)}
%0A%0A
${extraMessage}
    `;
    const messageDiscount = `
    Tengo un descuento de ${cartDiscount},
    %0A
    El total de mi pedido es ${totalStore - cartDiscount}
    `;
    if (cartDiscount > 0) {
      message = `${message} %0A%0A${messageDiscount}`;
    }

    const urlToRedirect = `https://wa.me/${shoppingCartData.locationWebPhone || defaultPhoneNumber}/?text=${message}`;

    // window.location.href = urlToRedirect;
    const aT = document.createElement('a');
    aT.target = '_blank';
    aT.href = urlToRedirect;
    document.body.appendChild(aT);
    aT.click();
    document.body.removeChild(aT);

    if (resetShoppinCart) {
      console.log('resetea el carro');
      dispatch(resetCart());
    }
  }; */

  const closeModal = () => {
    onModalStateChange(false);
  };

  /** HANDLE FORM */
  const {
    sendWhatsappOrder,
  } = useWhatsapp();

  const handleFormCompleted = async (extraMessage = '') => {
    console.log('antes de enviar el mensaje', shoppingCartData);
    // sendWhatsappOrder(extraMessage, true);
    // dispatch(resetCart());
    dispatch(resetCart());
    closeModal();
  };

  const handleFormCompletedWithoutCulqi = (extraMessage = '') => {
    sendWhatsappOrder(extraMessage);
    // dispatch(resetCart());
    dispatch(resetCart());
    closeModal();
  };

  /** STEPS */
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const [steps, setSteps] = useState([
    {
      title: 'Datos',
      content: <CustomerWebDataForm
        onFormCompleted={isCulqiDisplayed ? next : handleFormCompleted}
        onLoadingChange={setLoading}
        handleWhatsappOrder={!isCulqiDisplayed}
      />,
    },
  ]);
  useEffect(() => {
    if (isCulqiDisplayed && steps.length === 1) {
      setSteps([
        {
          title: 'Datos',
          content: <CustomerWebDataForm
            onFormCompleted={next}
            onLoadingChange={setLoading}
          />,
        },
        {
          title: 'Pagar',
          content: <CustomerCulqi
            onLoadingChange={setLoading}
            onFormCompleted={() => { handleFormCompleted('El pago ha sido realizado satisfactoriamente'); }}
          />,
        },
      ]);
    }
  }, [isCulqiDisplayed]);
  /* ---------------------- */

  useEffect(() => {
    setCulqiLoading(true);
    checkCulqiAvailability()
      .then(({ data, success }) => {
        if (success) {
          setIsCulqiDisplayed(data.value);
        }
      })
      .finally(() => {
        setCulqiLoading(false);
      });
  }, []);
  useEffect(() => {
    setState(isOpen);
    if (isOpen) {
      setCurrent(0);
    }
  }, [isOpen]);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    padding: '20px 15px',
    marginTop: 16,
  };

  return (
    <Modal
      destroyOnClose
      cancelText="Cancelar"
      cancelButtonProps={{
        type: 'dashed',
        danger: true,
      }}
      onCancel={() => { closeModal(); }}
      okButtonProps={{ style: { display: 'none' } }}
      title={(
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Datos de la compra
          {' '}
          <ShoppingCartOutlined />
        </Typography.Title>
      )}
      open={state}
      width={700}
    >
      <CartSpinner isLoading={isLoading} />
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {/** current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )* */}
        {/** current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        ) * */}
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div>

    </Modal>
  );
}

CustomerWebModal.defaultProps = {
  isOpen: false,
  onModalCompleted: () => { },
};

CustomerWebModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalStateChange: PropTypes.func.isRequired,
  onModalCompleted: PropTypes.func,
};
