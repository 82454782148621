import { getDeeperCopyFromObjectWithObjectProps, regexValidations, ValidationMould } from '../../../utils/utils';
import { validateDocumentNumberByType } from '../../../utils/documents';
import { channelsObj, documentsTypesCollection as documentsTypes, paymentMethods } from '../../../utils/constants';

const clientDataInitialState = {
  documentTypeId: 1,
  discount: 0,
  documentNumber: '',
  docNumberName: '',
  name: '',
  email: '',
  phone: '',
  address: '',
  paymentMethodId: 1,
  preparationTime: 15,
  delivery: 0,
  channel: channelsObj.CAJA,
  save: false,
};

const clientDataValidationsInitialState = {
  documentTypeId: new ValidationMould(
    {
      isRequired: false,
      errorMessage: 'Debe elegir un documento válido',
    },
    (docId) => {
      const docIndex = documentsTypes.findIndex((document) => document.id === parseInt(docId, 11));
      console.log('validando documento', docIndex, docId);
      return docIndex > -1;
    },
  ),
  documentNumber: new ValidationMould(
    { isRequired: false, errorMessage: 'Debe ingresar un documento válido' },
    (val, documentTypeId) => {
      if (val.length > 0) {
        console.log('Validando document', val, documentTypeId);
        return validateDocumentNumberByType(documentTypeId, val);
      }
      return true;
    },
  ),
  name: new ValidationMould({ isRequired: true, errorMessage: 'Es obligatorio ingresar un nombre' }),
  email: new ValidationMould({ isRequired: false, regex: regexValidations.email, errorMessage: 'Debe ingresar un correo válido' }),
  phone: new ValidationMould({ isRequired: true, regex: regexValidations.onlyNumbers, errorMessage: 'Debe ingresar un celular válido' }),
  address: new ValidationMould({ isRequired: true, errorMessage: 'Este campo es obligatorio' }),
  paymentMethodId: new ValidationMould(
    { isRequired: true, errorMessage: 'Debe seleccionar un método de pago' },
    (paymentMethodId) => {
      console.log('verificando metodo de pago', paymentMethodId);
      const methodIndex = paymentMethods.findIndex(
        (method) => method.id === parseInt(paymentMethodId, 10),
      );
      console.log('modelo');
      return methodIndex > -1;
    },
  ),
  delivery: new ValidationMould({ isRequired: true, errorMessage: 'Debe ingresar un precio valido' }, (price) => price > -1),
};

const getClientDataValidationsInitialState = () => getDeeperCopyFromObjectWithObjectProps(
  clientDataValidationsInitialState,
);

export { clientDataInitialState, getClientDataValidationsInitialState };
