import { getOptChain } from '../common/utils/utils';
import { api } from './config/axios';

const baseEndpoint = '/restaurant-location';

export const fetchAll = () => api.get(baseEndpoint)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));
export const fetchAllAdm = (optObj = {}) => {
  const optChain = `?${getOptChain(optObj)}`;

  return api.get(`${baseEndpoint}/adm${optChain}`)
    .then(({ data }) => data)
    .catch((error) => ({
      success: false,
      data: error,
      message: 'Ha habido un error',
    }));
};

export const fetchCities = () => api.get(`${baseEndpoint}/cities`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));

export const getOne = (restaurantLocationId, userId) => api.get(`${baseEndpoint}/${restaurantLocationId}/user/${userId}/get-menu/`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));

export const getAllByUser = (userId) => api.get(`${baseEndpoint}/list/${userId}`)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));

export const admSave = (storeId, storeData) => api.post(`${baseEndpoint}/adm/${storeId}`, storeData)
  .then(({ data }) => data)
  .catch((error) => ({
    success: false,
    data: error,
    message: 'Ha habido un error',
  }));
