import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Row,
  Typography,
} from 'antd';
import { notify } from '../../common/utils/notifications';
import Quantifier from '../../common/components/Quantifier/Quantifier';
import {
  // selectCurrentDish,
  selectDishesCollection,
  // setCurrentDish,
} from './dishesSlice';
import { addToCart, setState as setCartState } from '../../common/components/ShoppingCartMenu/shoppingCartSlice';
// import CheckboxList from '../../common/components/CheckboxList/CheckboxList';
import './single-dish-page.css';
import { getIngredientsAddonsFromDish } from '../../api/dish';
// import CustomImg from '../../common/components/Extras/CustomImg/CustomImg';
import { getUrlByFileRoot } from '../../common/utils/utils';
import { setLoading } from '../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';

const {
  Title,
} = Typography;

function SingleDishPage() {
  const { dishId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dishesCollection = useSelector(selectDishesCollection);
  const [dish, setDish] = useState({});
  const [addons, setAddons] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0.00);
  const types = {
    ADDON: 'ADDON',
    INGREDIENT: 'INGREDIENT',
  };
  console.log('ESTE ES EL PLATO ACTUAL', dish);
  console.log('ESTOS SON LOS INGREDIENTES ACTUALES', ingredients);
  console.log('ESTOS SON LOS ADDONS ACTUALES', addons);

  const getDishData = (id) => {
    const dishIndex = dishesCollection.findIndex((d) => d.id === parseInt(id, 10));
    console.log('dish index', dishIndex);
    console.log('dish id', id);
    console.log('dishes collection', dishesCollection);
    console.log('type', typeof id);

    if (dishIndex > -1) {
      const originalDish = { ...dishesCollection[dishIndex] };
      return originalDish;
    }

    return { id: 0 };
  };

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      dispatch(setLoading(true));
      getIngredientsAddonsFromDish(dishId)
        .then(({ success, data, message }) => {
          console.log('data fetch', data);

          if (success) {
            if (!ignore) {
              setAddons(data.addOns);
              setIngredients(data.ingredients);

              const currentDish = getDishData(dishId);
              setDish(currentDish);
            }
          } else {
            notify({
              type: 'error',
              text: message,
            });
          }
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    };
    fetchData();

    return () => {
      ignore = true;
    };
    // dispatch(setCurrentDish(dishId));
  }, []);

  /**
   useEffect(() => {
    setDish({ ...currentDish });
  }, [currentDish]);
  */

  const calculateTotalPrice = () => {
    console.log('dish quantity', dish.quantity);
    const accumulatedTotal = { value: 0 };
    const addonsPriceSum = { value: 0 };

    if (addons && addons.length > 0) {
      const selectedAddons = addons.filter((addon) => addon.isSelected);
      if (selectedAddons.length > 0) {
        selectedAddons.forEach((addon) => {
          addonsPriceSum.value += addon.price;
        });
      }
    }
    accumulatedTotal.value = ((dish.price + addonsPriceSum.value) * dish.quantity);
    setTotalPrice(accumulatedTotal.value);
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [dish.quantity, addons]);

  useEffect(() => {
    console.log('ingredient has change', ingredients);
  }, [ingredients]);

  const setQuantity = (newQuantity) => {
    const dishCopy = { ...dish };
    dishCopy.quantity = newQuantity;
    setDish(dishCopy);
  };

  const onIsSelectedChange = (obj) => {
    console.log('entra al on is selected change', obj);
    if (obj.type === types.INGREDIENT) {
      const ingredientsCopy = [...ingredients];
      const ingredientIndex = ingredientsCopy.findIndex((ingredient) => ingredient.id === obj.id);

      if (ingredientIndex > -1) {
        console.log('encuentra al producto');
        ingredientsCopy[ingredientIndex].isSelected = obj.newSelectedValue;
        console.log('new ingredients', ingredientsCopy);
        setIngredients(ingredientsCopy);
      }
    } else if (obj.type === types.ADDON) {
      const addonsCopy = [...addons];
      const addonIndex = addonsCopy.findIndex((extra) => extra.id === obj.id);

      if (addonIndex > -1) {
        addonsCopy[addonIndex].isSelected = obj.newSelectedValue;
        setAddons(addonsCopy);
      }
      calculateTotalPrice();
    }
  };

  const fetchDishToPushToCart = () => {
    const newDish = { ...dish };
    const addonsPriceSum = { value: 0 };

    const notToUseIngredients = ingredients.filter((ing) => !ing.isSelected);
    const selectedAddons = addons.filter((addon) => addon.isSelected);

    if (selectedAddons.length > 0) {
      selectedAddons.forEach((addon) => {
        addonsPriceSum.value += addon.price;
      });
    }

    newDish.isModified = true;
    newDish.price = dish.price + addonsPriceSum.value;
    newDish.ingredients = notToUseIngredients;
    newDish.addons = selectedAddons;

    return newDish;
  };

  const addDish = (evt) => {
    evt.target.blur();
    const formatedDish = fetchDishToPushToCart();
    dispatch(addToCart(formatedDish));
    setTimeout(() => {
      dispatch(setCartState(true));
      // notifySuccess();
      notify({
        text: 'Se ha agregado el plato al carrito',
        type: 'success',
        autoClose: 500,
      });
    }, 1000);
    navigate(-1);
  };

  const reset = (evt) => {
    const ingredientsCopy = [...ingredients].map((ing) => ({ ...ing, isSelected: true }));
    const addonsCopy = [...addons].map((addon) => ({ ...addon, isSelected: false }));
    setIngredients(ingredientsCopy);
    setAddons(addonsCopy);

    evt.target.blur();
  };

  if (Object.keys(dish).length > 0) {
    return (
      <Row gutter={24}>
        <Col span={5}>
          {/** <CustomImg
            url={getUrlByFileRoot(dish.img)}
            border="10px"
    />* */}
          <img
            width="90%"
            height="auto"
            alt={dish?.name}
            src={getUrlByFileRoot(dish.img)}
            style={{ borderRadius: '10px' }}
          />
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <div className="mb-4">
                <Title className="mt-0" level={3}>
                  {dish.name}
                </Title>
                <Button type="primary" className="mb-1" ghost onClick={reset}>
                  Reset
                </Button>
              </div>
              <div className="list-ingredients">
                {ingredients?.map((add) => (
                  <div className="field" key={add.id}>
                    <label htmlFor={`ingredient_${add.id}`} className="checkbox">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={add.isSelected}
                        id={`ingredient_${add.id}`}
                        disabled={!add.isOptional}
                        onChange={(evt) => {
                          onIsSelectedChange({
                            id: add.id,
                            newSelectedValue: evt.target.checked,
                            type: types.INGREDIENT,
                          });
                        }}
                      />
                      {add.name}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
            <Col span={24}>
              <Title level={4}>Extras</Title>
              <div className="list-extras">
                {addons?.map((add) => (
                  <div className="field" key={add.id}>
                    <label htmlFor={`extra_${add.id}`} className="checkbox">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={add.isSelected}
                        id={`extra_${add.id}`}
                        disabled={!add.isOptional}
                        onChange={(evt) => {
                          onIsSelectedChange({
                            id: add.id,
                            newSelectedValue: evt.target.checked,
                            type: types.ADDON,
                          });
                        }}
                      />
                      {add.name}
                      {' '}
                      <span className="extra-price">
                        (
                        {'  '}
                        +
                        {add.price}
                        {'  '}
                        )
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <Card>
            <div className="dish-box  is-flex is-justify-content-center">
              <div>
                <Quantifier value={dish.quantity} onQuantityChange={setQuantity} isSmall />
              </div>
              <div className="dish-total__container">
                <span className="dish-total__label">Total: </span>
                <span className="dish-total__price">
                  {`S/ ${totalPrice.toFixed(2)}`}
                </span>
              </div>
              <div className="mt-2">
                <Button size="large" type="primary" onClick={addDish}>
                  Agregar al Carrito
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
  return <div />;
}

export default SingleDishPage;
