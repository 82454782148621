/* eslint-disable import/prefer-default-export */
import { api } from './config/axios';

const baseEndpoint = '/claim';

export const save = (form) => api.post(`${baseEndpoint}/save`, form)
  .then(({ data }) => data)
  .catch(() => ({
    success: false,
    data: [],
    message: 'Ha habido un error al consultar los datos',
  }));
