/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { getOrdersByUser, saveOrder, setNextOrderState } from '../../api/orders';
import { reset as resetCart } from '../../common/components/ShoppingCartMenu/shoppingCartSlice';
import { setLoading } from '../../common/components/Spinners/GlobalSpinner/globalSpinnerSlice';
import { notify } from '../../common/utils/notifications';

const initialState = {
  collection: [],
  queueTime: 0,
  currentSelectedLocationId: 0,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      /* if(state.collection.length > 0 ){
        const newStateCollection = [];
        state.collection.forEach((order) => {
          const newOrderIndex = action.payload.findIndex((newOrder) => newOrder.id === order.id );
          if(newOrderIndex > -1) {
            newStateCollection.push()
          }
        })
      } */
      state.collection = action.payload;
    },
    setOrderState: (state, action) => {
      console.log('seteando estado de la orden', action.payload);
      const orderIndex = state.collection.findIndex(
        (order) => order.id === parseInt(action.payload.orderId, 10),
      );
      if (state.collection[orderIndex]) {
        state.collection[orderIndex].state = action.payload.nextStateId;
      }
    },
    setQueueTime: (state, action) => {
      state.queueTime = action.payload;
    },
    setCurrentLocationId: (state, action) => {
      console.log('estoy setando el lcoation id', action.payload);
      state.currentSelectedLocationId = action.payload;
    },
  },
});

export const {
  setOrders,
  setOrderState,
  setQueueTime,
  setCurrentLocationId,
} = ordersSlice.actions;

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (user, { dispatch }) => {
    // dispatch(setOrders([]));
    console.log('este es el usuario', user);
    const { success, data, extras } = await getOrdersByUser(user.id);
    console.log('estos son los extras', extras, data);
    if (success) {
      dispatch(setOrders(data));
      if (typeof extras?.queueTime === 'number') {
        dispatch(setQueueTime(extras?.queueTime));
      }
    } else {
      dispatch(setOrders([]));
    }
  },
);

export const updateOrderState = createAsyncThunk(
  'orders/updateOrderState',
  async ({ orderId, nextState }, { dispatch }) => {
    dispatch(setLoading(true));
    const res = await setNextOrderState(orderId, nextState);
    if (!res.success) {
      notify({
        text: 'Ha habido un error',
        type: 'error',
      });
    }
    dispatch(setLoading(false));
  },
);

export const createOrder = createAsyncThunk(
  'orders/saveOrder',
  async (form, { dispatch }) => {
    dispatch(setLoading(true));
    const { success, message, data } = await saveOrder(form);
    console.log('se ha guardado la orden', success, message, data, !!data.queueTime);
    notify({
      type: success ? 'success' : 'error',
      text: message,
    });
    if (success) {
      if (data.queueTime) {
        console.log('el queueTime del bakend', data.queueTime);
        dispatch(setQueueTime(data.queueTime));
      }
      dispatch(resetCart());
    }
    dispatch(setLoading(false));
    return { success };
  },
);

export const selectOrders = (state) => state.orders.collection;
export const selectQueueTime = (state) => {
  console.log('queueTime', state.orders.queueTime);
  return state.orders.queueTime;
};
export const selectCurrentSelectedLocationId = (state) => state.orders.currentSelectedLocationId;
export default ordersSlice.reducer;
