import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setUser,
  selectUser,
  resetApp,
  // setToken,
} from '../../features/login/user';
import GlobalSpinner from '../components/Spinners/GlobalSpinner/GlobalSpinner';
import { fetchUserByAuth0 } from '../../api/user';
import { api } from '../../api/config/axios';
// import { setLoading } from '../components/Spinners/GlobalSpinner/globalSpinnerSlice';
// import { currentToken } from './auth2';

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

export function AuthProvider({ children }) {
  const { isLoading } = useAuth0();

  useEffect(() => {
    // console.log('carga el auth provider');
  });

  if (isLoading) {
    return (
      <GlobalSpinner isLoading>
        <span>Validando credenciales</span>
      </GlobalSpinner>
    );
  }
  return (
    <React.StrictMode>
      {children}
    </React.StrictMode>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useProvideAuth = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const signin = (cb) => fakeAuth.signin(() => {
    dispatch(setUser({ ...user, isValid: true }));
    cb();
  });
  const signout = (cb) => fakeAuth.signin(() => {
    // dispatch(setUser({ username: '', password: '' }));
    dispatch(resetApp());
    cb();
  });

  return {
    user,
    signin,
    signout,
  };
};

export function PrivateRoute({ children }) {
  // const auth = useProvideAuth();
  const dispatch = useDispatch();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    async function fetchToken() {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_AUDIENCE,
        },
      });
      console.log('este es el token', token);
      if (token) {
        // setToken(token);
        // currentToken.value = token;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        if (Object.keys(user).length > 0) {
          const authId = user.sub.split('|')[1];
          const userData = {
            name: user.name,
            email: user.email,
          };
          const { success, data } = await fetchUserByAuth0(userData, authId);
          if (success) {
            dispatch(setUser(data));
          }
        }
      }
    }
    console.log('private route use effect');
    /** dispatch(setLoading(true));
    fetchToken()
      .catch((error) => { console.log('error al obtener token', error); })
      .finally(() => { dispatch(setLoading(false)); });* */
  }, []);

  console.log('este es el usuario de auth0', user);
  // eslint-disable-next-line no-unused-vars
  console.log('private route');
  if (!isAuthenticated) {
    return (<Navigate to={{ pathname: '/login' }} />);
  }

  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
