import { ValidationMould } from '../../../../common/utils/utils';

const dishData = {
  title: '',
  desc: '',
  locationOnRestaurantId: undefined,
  categoryId: undefined,
  price: 0,
  cost: 0,
  img: [],
  imgObject: null,
  prices: [],
  /** ingredients: [
    {
      id: 2,
      name: 'Masa',
      quantity: 1,
      price: 1.5,
      isOptional: true,
      measureUnitId: 1,
      measureUnitName: 'Kilos',
    },
  ],* */
  isAvailable: true,
};

const dishDataValidations = {
  name: new ValidationMould({
    isRequired: true,
    errorMessage: 'Es obligatorio especificar un nombre',
  }),
  price: new ValidationMould({
    isRequired: true,
    errorMessage: 'Es obligatorio especificar una precio',
  }, (val) => val > -1),
  cost: new ValidationMould({
    isRequired: true,
    errorMessage: 'Es obligatorio especificar una precio',
  }, (val) => val > -1),
};

// eslint-disable-next-line import/prefer-default-export
export { dishData, dishDataValidations };
