/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faAdd,
  faSave,
  faSearch,
  // faUpload,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CodeSandboxOutlined,
  DeleteOutlined,
  DropboxOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SafetyCertificateOutlined,
  SaveOutlined,
  StarOutlined,
} from '@ant-design/icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Upload,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import CustomInput from '../../../../common/components/Extras/CustomInput/CustomInput';
import useForm from '../../../../common/hooks/useForm';
import { notify } from '../../../../common/utils/notifications';
import { getFileSize, getUrlByFileRoot, ValidationMould } from '../../../../common/utils/utils';
import { selectAdmDishById } from '../admDishesSlice';
import { dishData, dishData as dishInitialData, dishDataValidations as dishInitialDataValidations } from './model';
import './dish-form.css';
import CustomHeaderRow from '../../../../common/components/Extras/CustomHeaderRow/CustomHeaderRow';
import { selectUser } from '../../../login/user';
import { fetchRestaurantsLocations, selectRestaurantsLocations } from '../../../restaurantLocation/restaurantLocationSlice';
import { fetchDataByRestaurant, selectDishesCollection } from '../../../dish/dishesSlice';
import { addDish, editDish, getOne } from '../../../../api/dish';
import { getAll as getAllCategories } from '../../../../api/category';
import { getAllByLocation as getAllIngredientsByLocation } from '../../../../api/ingredient';
import { getAllByLocation as getAllByAddOnsByLocation } from '../../../../api/addOn';
import { AddOnsModal, IngredientsModal } from './SuppliesModals';
import { channels } from '../../../../common/utils/constants';

const { Option } = Select;
const { Column } = Table;

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

function DishForm() {
  const { id } = useParams();
  // const currentDish = useSelector(selectAdmDishById(id));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dish, setDish] = useState(dishInitialData);
  const currentUser = useSelector(selectUser);
  const [isCombo, setIsCombo] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const restaurantsLocations = useSelector(selectRestaurantsLocations);
  const [form] = Form.useForm();

  const dishForm = useForm(dish, dishInitialDataValidations);
  const [edit, setEdit] = useState(false);
  const inputFileRef = useRef(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const noDataAvailableText = 'Sin data disponible';

  /** * PARA LA IMAGEN */
  const [currentImageUrl, setCurrentImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const uploadImgProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    onPreview: handlePreview,
    fileList,
  };
  /** ********************** */

  /** * DISHES COLLECTION PARA PLATOS */
  const [comboDetailsForm] = Form.useForm();
  const dishesCollection = useSelector(selectDishesCollection);
  const [comboTableIsLoading, setComboTableLoading] = useState(false);
  const comboTableColumns = [
    {
      title: 'Imagen',
      dataIndex: 'img',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
    },
  ];
  const [selectedDishes, setSelectedDishes] = useState([]);
  const onSelectedDish = (selectedDish) => {
    setSelectedDishes(selectedDish);
  };
  const rowComboTableSelection = {
    selectedRowKeys: selectedDishes,
    onChange: onSelectedDish,
  };
  const [comboDetailsCollection, setComboDetailsCollection] = useState([]);

  const handleComboItemSubmit = (values) => {
    comboDetailsForm.validateFields().then((vals) => {
      console.log(values, selectedDishes, vals);
      if (!(selectedDishes.length > 0)) {
        notify({ text: 'Debe seleccionar un plato como mínimo', type: 'error' });
        return;
      }
      const comboDetailsCopy = [...comboDetailsCollection];
      comboDetailsCopy.push({
        key: comboDetailsCollection.length + 1,
        title: vals.comboDetailTitle,
        dishes: dishesCollection
          .filter((d) => selectedDishes.indexOf(d.id) > -1),
      });
      console.log(comboDetailsCopy);
      setComboDetailsCollection(comboDetailsCopy);
      setSelectedDishes([]);
      comboDetailsForm.setFieldValue('comboDetailTitle', '');
    });
  };

  const removeDishFromComboDetail = (dishKey) => {
    console.log('este es el dishId', dishKey);
    setComboDetailsCollection(comboDetailsCollection.filter((c) => c.key !== dishKey));
  };
  /** ***************************************** */
  /** ********* INGREDIENTS ******************* */
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedIngredientsIdOnModal, setSelectedIngredientsIdOnModal] = useState([]);
  const [ingredientsDataSet, setIngredientsDataSet] = useState([]);
  const [ingredientsModalIsOpen, setIngredientsModalState] = useState(false);
  const handleIngredientsSelection = (selectedData) => {
    setSelectedIngredients(selectedData);
    console.log('esta es la selected data', selectedData);
    setIngredientsModalState(false);
  };
  /** ******** ADDONS ********************** */
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedAddOnsIdOnModal, setSelectedAddOnsIdOnModal] = useState([]);
  const [addOnsDataSet, setAddOnsDataSet] = useState([]);
  const [addOnsModalIsOpen, setAddOnsModalState] = useState(false);
  const handleAddOnsSelection = (selectionIds) => {
    setSelectedAddOns(addOnsDataSet.filter(
      (a) => selectionIds.indexOf(a.addOnOnLocationId) > -1,
    ));
    setAddOnsModalState(false);
  };
  /** ************************************ */
  /** ********* PRICES ******** */
  const channelOptions = channels.map((c) => ({ label: c.text, value: c.name }));
  /** *********************** */

  console.log('estos son los platos', dishesCollection);

  const fetchCurrentDish = async () => {
    let currentDish = null;
    console.log('en el metodo fetch Curren dish', edit, id);
    if (!!id && id !== 0) {
      console.log('ha entrado al fi');
      const dishResult = await getOne(id);
      currentDish = dishResult.success && dishResult.data;
    }

    return currentDish;
  };

  useEffect(() => {
    /** * VERIFICAMOS SI EDITAMOS O AGREGAMOS UN NUEVO PLATO */
    setEdit(!!id);
    if (id) {
      // console.log('este es el id seleccionado', currentDish, id);
      // setDish(currentDish);
    }
    /** * CONSULTAMOS LAS LOCACIONES DISPONIBLES */
    setLoadingState(true);

    Promise.all([
      dispatch(fetchRestaurantsLocations()),
      getAllCategories(),
      fetchCurrentDish(),
    ])
      .then(([_, { data: { categories }, success }, currentDish]) => {
        console.log('la promesa de categorias', categories);
        if (success) {
          const categoriesDataset = categories.map((c) => ({ value: c.id, label: c.name }));
          setCategoryOptions(categoriesDataset);
        }

        /** * AQUI SETEAMOS EL PLATO ACTUAL */
        if (currentDish) {
          setDish(currentDish);
          console.log(currentDish, 'plato actual');
          const currentDishFields = Object.keys(dish);
          const currentFormFields = Object.keys(form.getFieldsValue());
          const fieldsToSetObj = {};
          currentFormFields.forEach((f) => {
            console.log('en el bucle', f, currentDish[f]);
            if (currentDishFields.indexOf(f) > -1) {
              if (typeof currentDish[f] !== 'object'
                && f !== 'img') {
                fieldsToSetObj[f] = currentDish[f];
              }
              if (f === 'prices') {
                fieldsToSetObj[f] = currentDish[f];
              }
              if (f === 'img' && currentDish[f] !== '') {
                setCurrentImage(getUrlByFileRoot(currentDish[f]));
              }
            }
          });
          if (currentDish.ingredients.length > 0) {
            setSelectedIngredients(currentDish.ingredients);
            setSelectedIngredientsIdOnModal(currentDish.ingredients
              .map((i) => i.ingredientOnLocationId));
            console.log('estoy redererizando esto', currentDish.ingredients, currentDish.ingredients.map((i) => i.ingredientOnLocationId));
          }
          if (currentDish.addons.length > 0) {
            setSelectedAddOns(currentDish.addons);
            setSelectedAddOnsIdOnModal(currentDish.addons.map((a) => a.addOnOnLocationId));
          }
          if (currentDish.comboDetail.length > 0) {
            setComboDetailsCollection(currentDish.comboDetail);
            setIsCombo(true);
          }
          if (Object.keys(fieldsToSetObj).length > 0) {
            form.setFieldsValue(fieldsToSetObj);
          }

          console.log('seteamos el plato', dish, currentDish, currentFormFields, fieldsToSetObj);
        }
      })
      .finally(() => { setLoadingState(false); });
  }, []);

  useEffect(() => {
    setRestaurantOptions(restaurantsLocations.map((r) => ({
      key: r.id,
      value: r.name,
    })));
  }, [restaurantsLocations]);

  const ingredientsColumns = [
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'quantity',
      label: 'Cantidad',
    },
    {
      name: 'unitMeasure',
      label: 'Unidad',
    },
    {
      name: 'price',
      label: 'Precio',
    },
    {
      name: 'Actions',
      label: '',
    },
  ];

  const watchingLocationOnRestaurantId = Form.useWatch('locationOnRestaurantId', form);
  const watchingImg = Form.useWatch('img', form);

  useEffect(() => {
    if (watchingLocationOnRestaurantId) {
      const restaurantLocationObj = restaurantsLocations.find(
        (r) => r.id === watchingLocationOnRestaurantId,
      );
      setComboTableLoading(true);
      setLoadingState(true);
      Promise.all([
        dispatch(fetchDataByRestaurant(watchingLocationOnRestaurantId)),
        getAllIngredientsByLocation(restaurantLocationObj?.locationId),
        getAllByAddOnsByLocation(restaurantLocationObj?.locationId),
      ])
        .then(([_,
          { data: ingredients, success: ingSuccess },
          { data: addOns, success: addOnSuccess },
        ]) => {
          console.log('request', ingredients, addOns, ingSuccess, addOnSuccess);
          if (ingSuccess) {
            setIngredientsDataSet(ingredients);
          }
          if (addOnSuccess) {
            setAddOnsDataSet(addOns);
          }
        })
        .finally(() => { setComboTableLoading(false); setLoadingState(false); });
    }
  }, [watchingLocationOnRestaurantId]);

  const handleFormChange = (allFormValues) => {
    const filteredValues = allFormValues.map((valObj) => {
      if (['locationOnRestaurantId', 'categoryId'].indexOf(valObj.name[0]) > -1
        && valObj.value
      ) {
        return {
          ...valObj,
          value: parseInt(valObj.value, 10),
        };
      } if (['price', 'cost'].indexOf(valObj.name[0]) > -1
        && valObj.value
      ) {
        return {
          ...valObj,
          value: parseFloat(valObj.value),
        };
      }

      return valObj;
    });
    form.setFieldValue(filteredValues);
    console.log('al form values', allFormValues);
  };

  const handleFormSubmit = () => {
    form.validateFields()
      .then((vals) => {
        const formData = new FormData();
        const jsonData = { ...vals };
        console.log('estos son los valores', vals);
        /** Object.keys(vals).forEach((k) => {
          dataForm.append(k, vals[k]);
        });* */
        if (isCombo && !(comboDetailsCollection.length > 0)) {
          notify({ text: 'Debe especificar un ítem del combo como mínimo', type: 'error' });
          return;
        }
        jsonData.isCombo = isCombo;
        jsonData.comboDetailsCollection = comboDetailsCollection;
        jsonData.ingredients = selectedIngredients;
        jsonData.addOns = selectedAddOns;
        // dataForm.append('isCombo', isCombo);
        // dataForm.append('comboDetailCollection', JSON.stringify(comboDetailsCollection));
        console.log('esta es la imagen', vals.img.file, vals, jsonData);
        formData.append('data', JSON.stringify(jsonData));
        formData.append('img', vals.img.file);
        const apiToUse = edit ? editDish : addDish;
        setLoadingState(true);
        apiToUse(formData, id)
          .then((data) => {
            console.log('esta es al data', data);
            notify({
              text: data.message,
              type: data.success ? 'success' : 'error',
            });
            if (data.success) {
              navigate('/app/admin/dishes');
            }
          })
          .catch((error) => { console.log('Ha habido un error', error); })
          .finally(() => { setLoadingState(false); });
      });
  };

  const beforeUpload = () => {

  };

  /** const handleFileInputChange = () => {
    inputFileRef.current.click();
  };* */

  return (
    <Spin spinning={isLoading}>
      <div className="is-size-4 has-text-weight-bold has-text-centered mb-5">
        {edit ? `Editar  ${dish?.title}` : 'Nuevo Plato'}
      </div>

      <div className="form-container has-yscroll has-hidden-scroll">
        <Form
          form={form}
          initialValues={dishData}
          layout="vertical"
          onFieldsChange={(_, allFields) => { handleFormChange(allFields); }}
          onFinish={handleFormSubmit}
        >
          <Row gutter={[16, 2]}>
            <Col span={24}>
              <Space align="center">
                <Form.Item>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                  >
                    Guardar
                  </Button>
                </Form.Item>
                <Switch checked={isCombo} onChange={(value) => { setIsCombo(value); }} checkedChildren="Es combo" unCheckedChildren="No es combo" />
              </Space>
            </Col>
            <Col span={8}>
              <Form.Item
                name="title"
                label="Nombre"
                rules={
                  [
                    {
                      required: true,
                      message: 'Se debe especificar un nombre',
                    },
                  ]
                }
              >
                <Input
                  placeholder="Nombre del plato"
                  prefix={<CodeSandboxOutlined />}
                />
              </Form.Item>

            </Col>
            <Col span={4}>
              <Form.Item
                name="price"
                label="Precio público"
              >
                <InputNumber
                  defaultValue={0}
                  type="number"
                  style={{ width: '100%' }}
                  prefix={<SafetyCertificateOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="cost"
                label="Costo"
              >
                <InputNumber
                  defaultValue={0}
                  type="number"
                  style={{ width: '100%' }}
                  prefix={<StarOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="locationOnRestaurantId"
                label="Restaurante - Cocina"
                rules={[
                  {
                    required: true,
                    message: 'Es obligatorio',
                  },
                ]}
              >
                <Select>
                  {
                    restaurantOptions.map((r) => (
                      <Option key={r.key} value={r.key}>
                        {r.value}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item
                name="img"
                label="Foto"
              >
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  <img
                    src={`${form.getFieldValue('img') || './pictures/hamburguer.jpg'}`}
                    style={{ width: '100%' }}
                    alt="dish-img"
                  />
                </Upload>
              </Form.Item>
            </Col> * */}
            <Col span={8}>
              <Form.Item
                name="desc"
                label="Descripcion"
                rules={[
                  {
                    required: true,
                    message: 'Se debe especificar una descripción',
                  },
                ]}
              >
                <TextArea
                  maxLength={255}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="categoryId"
                label="Categoría"
                rules={[
                  {
                    required: true,
                    message: 'Es obligatorio',
                  },
                ]}
              >
                <Select
                  options={categoryOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.List
                label="Precios"
                name="prices"
              >
                {(fields, { add, remove }) => (
                  <Space direction="vertical">
                    {
                      fields.map((field) => (
                        <Row key={field.key} gutter={[12, 32]}>
                          <Col span={8}>
                            <Form.Item
                              name={[field.name, 'price']}
                            >
                              <Input type="number" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name={[field.name, 'channel']}
                            >
                              <Select options={channelOptions} />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Button
                              icon={<MinusCircleOutlined />}
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      ))
                    }
                    <Button
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      + Agregar precio
                    </Button>
                  </Space>
                )}
              </Form.List>
            </Col>
            <Col span={4}>
              <Form.Item
                name="img"
              >
                <Upload
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...uploadImgProps}
                  listType="picture-card"
                >
                  {(form.getFieldValue('img')?.fileList?.length >= 1) ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                  alt="example"
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              </Modal>
            </Col>
            {
                currentImageUrl
                && (
                  <Col span={4}>
                    <Image
                      width={100}
                      style={{ borderRadius: '10px' }}
                      src={currentImageUrl}
                    />
                  </Col>

                )
              }
          </Row>
        </Form>
      </div>

      {
        isCombo
        && (

          <Row gutter={12} className="mb-1" align="top">
            <Col span={24}>
              <Form
                form={comboDetailsForm}
                name="comboDetailForm"
                layout="vertical"
                onFinish={handleComboItemSubmit}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item
                      name="comboDetailTitle"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Debe introducir un título para el ítem de su combo',
                        },
                      ]}
                    >
                      <Input prefix={<DropboxOutlined />} placeholder="Nombre del Item del combo" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button prefix={<SaveOutlined />} type="primary" htmlType="submit">
                        Agregar
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={12}>
              <Table
                pagination={{ hideOnSinglePage: true }}
                rowSelection={rowComboTableSelection}
                loading={comboTableIsLoading}
                columns={comboTableColumns}
                dataSource={dishesCollection.filter(
                  (d) => !(d.comboDetail.length > 0),
                ).map((d) => ({ ...d, key: d.id }))}
                locale={{ emptyText: noDataAvailableText }}
              />
            </Col>
            <Col span={12}>
              <Table
                pagination={{ hideOnSinglePage: true }}
                dataSource={comboDetailsCollection}
                locale={{ emptyText: noDataAvailableText }}
              >
                <Column
                  title="Título"
                  dataIndex="title"
                  key="title"
                />
                <Column
                  title="Platos Disponibles"
                  dataIndex="dishes"
                  key="dishes"
                  render={(dishes) => (
                    <>
                      {
                        dishes.map((d) => (
                          <Tag color="blue" key={d.id}>
                            {d.name}
                          </Tag>
                        ))
                      }
                    </>
                  )}
                />
                <Column
                  title="Acciones"
                  align="center"
                  dataIndex=""
                  key="actions"
                  render={(_, record) => (
                    <Button
                      danger
                      onClick={() => { removeDishFromComboDetail(record.key); }}
                      icon={<DeleteOutlined />}
                    />
                  )}
                />
              </Table>
            </Col>
          </Row>
        )
      }
      {
        !isCombo
        && (
          <Row gutter={[12, 18]}>
            <Col span={12}>
              <IngredientsModal
                isOpen={ingredientsModalIsOpen}
                ingredientsDataSet={ingredientsDataSet}
                onModalStateChange={setIngredientsModalState}
                onSelectionSubmit={handleIngredientsSelection}
                newSelectedIndexArray={selectedIngredientsIdOnModal}
              />
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  onClick={() => { setIngredientsModalState(true); }}
                >
                  Seleccionar Ingredientes
                </Button>
                <Table
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={selectedIngredients}
                  locale={{ emptyText: noDataAvailableText }}
                >
                  <Column
                    title="ID"
                    dataIndex="ingredientOnLocationId"
                    key="ingredientOnLocationId"
                  />
                  <Column
                    title="Nombre"
                    dataIndex="title"
                    key="title"
                  />
                  <Column
                    title="Precio"
                    dataIndex="price"
                    key="price"
                  />
                  <Column
                    title="Cantidad"
                    dataIndex="dishQuantity"
                    key="dishQuantity"
                  />
                  <Column
                    title="Unidad"
                    dataIndex="unit"
                    key="unit"
                  />
                  <Column
                    title="Es Opcional"
                    dataIndex="isOptional"
                    key="isOptional"
                    render={(isOptional) => (<span>{`${isOptional ? 'Si' : 'No'}`}</span>)}
                  />
                </Table>
              </Space>
            </Col>
            <Col span={12}>
              <AddOnsModal
                isOpen={addOnsModalIsOpen}
                addOnsDataSet={addOnsDataSet}
                onModalStateChange={setAddOnsModalState}
                onSelectionSubmit={handleAddOnsSelection}
                newSelectedIndexArray={selectedAddOnsIdOnModal}
              />
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Button
                  type="primary"
                  onClick={() => { setAddOnsModalState(true); }}
                >
                  Seleccionar Extras
                </Button>
                <Table
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={selectedAddOns}
                  locale={{ emptyText: noDataAvailableText }}
                >
                  <Column
                    title="ID"
                    dataIndex="addOnOnLocationId"
                    key="addOnOnLocationId"
                  />
                  <Column
                    title="Nombre"
                    dataIndex="title"
                    key="title"
                  />
                  <Column
                    title="Precio"
                    dataIndex="price"
                    key="price"
                  />
                  <Column
                    title="Unidad"
                    dataIndex="unit"
                    key="unit"
                  />
                </Table>
              </Space>
            </Col>
          </Row>
        )
      }

      { /** <div>
        <div className="is-flex is-justify-content-space-between mb-4">
          <div className="ingredients-title-container">
            <div className="has-text-centered is-size-4 has-text-weight-bold">
              Insumos
            </div>
            <button type="button" className="button has-50p-border">
              <FontAwesomeIcon icon={faAdd} />
            </button>
          </div>
          <div>
            <CustomInput
              inputId="searchIngredients"
              inputName="searchIngredients"
              placeholder="Buscar"
              leftIcon={faSearch}
            />
          </div>
        </div>
        <table className="table anki-table">
          <thead>
            <CustomHeaderRow
              columns={ingredientsColumns}
            />
          </thead>
          <tbody>
            {
              dishForm.data.ingredients.map((ingredient) => (
                <tr>
                  <td>{ingredient.name}</td>
                  <td>{ingredient.quantity}</td>
                  <td>{ingredient.measureUnitName}</td>
                  <td>{ingredient.price}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>* */ }
    </Spin>
  );
}

export default DishForm;
