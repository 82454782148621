import { ValidationMould } from '../../../../common/utils/utils';

export const orderData = {
  id: 0,
  startDateTime: '2022/11/20 6:00',
  channel: '',
  paymentMethod: 1,
  customerName: '',
  customerDocumentNumber: '',
  customerPhone: '',
  customerAddress: '',
  customerEmail: '',
  subtotal: 0,
  total: 0,
  delivery: 0,
};

export const orderValidations = {
  customerName: new ValidationMould({ isRequired: true }),
};
