import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  DatePicker,
  Space,
} from 'antd';
import { FileExcelOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
// import ReactDatePicker from 'react-datepicker';
// import CustomInput from '../../../../common/components/Extras/CustomInput/CustomInput';
// import useSearch from '../../../../common/hooks/useSearch';
import { fetchAdmOrders, selectAdmOrders } from '../admOrdersSlice';
import AdmOrdersList from './AdmOrdersList/AdmOrdersList';
import { getReportByUser } from '../../../../api/orders';
import { selectUser } from '../../../login/user';
import GlobalSpinner from '../../../../common/components/Spinners/GlobalSpinner/GlobalSpinner';
import { notify } from '../../../../common/utils/notifications';
import { M_PER_WEEK } from '../../../../common/utils/constants';
import { getDateAlteredByMin } from '../../../../common/utils/utils';
import Paginator from '../../../../common/components/Extras/Paginator/Paginator';
import { setCurrentPageTitle } from '../../../../app/Layouts/appLayoutSlice';
// import { Button, DatePicker } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
// const { Title } = Typography;

export default function AdmOrdersPage() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    dispatch(setCurrentPageTitle('Pedidos'));
  }, []);
  // const search = useSearch();
  const orders = useSelector(selectAdmOrders);
  const [startDate, setStartDate] = useState(dayjs(getDateAlteredByMin(new Date(), M_PER_WEEK)));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [isLoading, setLoading] = useState(false);
  const [page, setCurrentPage] = useState(1);
  // const [rowAmount, setRowAmount] = useState(0);
  const [paginationProps, setPaginatioProps] = useState({
    rowCount: 0,
    rowsPerPage: 10,
  });

  //  console.log('actual dates', startDate.format('YYYY-MM-DDTHH:mm:ss').split('T')[0], endDate);
  // OPTIMIZAR PAGINACION
  const executeSearch = (optObj = {}) => {
    dispatch(fetchAdmOrders({
      startDate: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss').split('T')[0],
      endDate: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss').split('T')[0],
      ...optObj,
    }))
      .then(({ payload }) => {
        console.log(payload, 'esto es payload', payload);
        if (payload) {
          const { rowCount, currentPage } = payload;
          setCurrentPage(currentPage);
          setPaginatioProps({
            ...paginationProps,
            rowCount: rowCount || 0,
          });
        }
      });
  };

  useEffect(() => {
    console.log('Use effect de adm');
    executeSearch({ page });
  }, []);

  const getReport = async () => {
    setLoading(true);
    const fechaInicio = startDate.toISOString().split('T')[0];
    const fechaFin = endDate.toISOString().split('T')[0];
    const { success, data, message } = await getReportByUser(currentUser.id, {
      startDate: fechaInicio,
      endDate: fechaFin,
    })
      .finally(() => { setLoading(false); });

    if (success) {
      const link = document.createElement('a');
      link.download = `pedidos-${fechaInicio.replace(/-/g, '')}-${fechaFin.replace(/-/g, '')}.xlsx`;
      link.href = URL.createObjectURL(new Blob([data]));
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      notify({
        text: message,
        type: 'error',
      });
    }
    // console.log('este es el reporte', data);
  };

  return (
    <>
      <GlobalSpinner isLoading={isLoading}>
        <span>Descargando Documento</span>
      </GlobalSpinner>
      <Space className="mb-1" style={{ width: '100%', justifyContent: 'end' }}>
        <Button type="primary" ghost icon={<FileExcelOutlined />} shape="circle" onClick={getReport} />
        <Link to="add">
          <PlusOutlined />
        </Link>
      </Space>
      {/** <CustomInput
          value={search.value}
          style={{ minWidth: '25rem' }}
          inputName="search"
          inputId="search"
          placeholder="Buscar Pedidos"
          onInputChange={search.handleSearchInputChange}
          leftIcon={faSearch}
          className="column my-auto"
          size="small"
  /> */}
      <Space className="mb-1" direction="horizontal" style={{ width: '100%', justifyContent: 'end' }}>
        <DatePicker defaultValue={startDate} onChange={(date) => { setStartDate(date); }} />
        <DatePicker defaultValue={endDate} onChange={(date) => { setEndDate(date); }} />

        <Button icon={<SearchOutlined />} onClick={executeSearch} type="primary">
          Buscar
        </Button>
      </Space>
      <div>
        <AdmOrdersList
          orders={orders}
          executeDataFetch={executeSearch}
          paginationProps={paginationProps.current}
        />
        <Paginator
          // rowCount={paginationProps.current.rowCount}
          rowCount={paginationProps.rowCount}
          rowsPerPage={paginationProps.rowsPerPage}
          currentPage={page}
          onPageChange={(newPage) => {
            executeSearch({ page: newPage });
          }}
        />
      </div>
    </>
  );
}
